import FoldersRepository from "SP/folders/folders.repository";
import { IFolder, IFolderDTO } from "SP/folders/folders.types";

export default class FoldersService {
  private repository = new FoldersRepository();

  private mapFolder = (folder: IFolderDTO): IFolder => ({
    title: folder.Name,
    key: folder.UniqueId,
  });

  public async getRootFolders(): Promise<IFolder[]> {
    const rootFolder = await this.repository.getRootFolder();
    const mapperRootFolder = this.mapFolder(rootFolder);
    const folders = [mapperRootFolder];
    folders[0].children = await this.getSubFolders(mapperRootFolder.key);
    return folders;
  }

  public async getSubFolders(parentFolderId: string): Promise<IFolder[]> {
    const folders = await this.repository.getSubFolders(parentFolderId);
    return folders.map((folder) => this.mapFolder(folder));
  }
}
