import { useEffect } from "react";

import handleError from "Helpers/errorHandler";
import store from "Store/store";

const NotFoundPage = () => {
  useEffect(() => {
    handleError({ status: 404 }, store);
  }, []);

  return null;
};

export default NotFoundPage;
