import CamlBuilder, { ViewScope } from "camljs";

import { ItemAddResult, ItemUpdateResult, List, sp } from "@pnp/sp";
import { ICamlQuery } from "@pnp/sp-commonjs";

import { hash } from "Helpers/utils";
import cache, { localStorageGetAllPrefix } from "SP/cache";

import { IGetAllArgs, INewsDTO } from "./news.types";

export default class NewsRepository {
  private dl: List = sp.web.lists.getByTitle("News");

  generateListItemCollectionPosition = (lastNewsInfo: Partial<INewsDTO>) => {
    const lastId = lastNewsInfo.Id;
    const CreatedDate = lastNewsInfo.Created;

    const info = {
      PagingInfo: `Paged=TRUE&p_ID=${lastId}&p_Created=${CreatedDate}`,
    };

    return info;
  };

  async getAll(params: IGetAllArgs): Promise<INewsDTO[]> {
    const { limit, lastNewsInfo, sortOrder } = params;

    const baseQuery = new CamlBuilder().View().Scope(ViewScope.Recursive).RowLimit(limit).Query();
    const sortedQuery = sortOrder === "ASC" ? baseQuery.OrderBy("Created") : baseQuery.OrderByDesc("Created");

    const caml: ICamlQuery = {
      ViewXml: sortedQuery.ToString(),
    };

    if (lastNewsInfo) {
      caml.ListItemCollectionPosition = this.generateListItemCollectionPosition(lastNewsInfo);
    }

    return cache.staleWhileRevalidate({
      type: "news",
      key: `${localStorageGetAllPrefix}:${hash(JSON.stringify(params))}`,
      promise: this.dl.getItemsByCAMLQuery.bind(this.dl),
      promiseParams: caml,
    });
  }

  async getById(id: number): Promise<INewsDTO> {
    return await this.dl.items.getById(id).select().usingCaching().get();
  }

  async add(payload: Partial<INewsDTO>): Promise<ItemAddResult> {
    cache.clear("news");
    return await this.dl.items.add(payload);
  }

  async edit(id: number, payload: Partial<INewsDTO>): Promise<ItemUpdateResult> {
    cache.clear("news");
    return await this.dl.items.getById(id).update(payload);
  }

  async deleteById(id: number): Promise<string> {
    cache.clear("news");
    return await this.dl.items.getById(id).recycle();
  }
}
