import { useDispatch, useSelector } from "react-redux";

import type { ISelectOption } from "Components/select/select.types";
import { mapUserForSelectOption } from "Helpers/userHandler";
import { checkEmailsRequest, searchUsersRequest } from "Store/actions/users.actions";
import type { IRootReducerState } from "Store/reducers";

export const useOwnerSelect = () => {
  const dispatch = useDispatch();

  const searchLoading = useSelector<IRootReducerState, boolean>((state) => state.users.loading);
  const userOptions = useSelector<IRootReducerState, ISelectOption[]>((state) =>
    state.users.allUsers.map(mapUserForSelectOption),
  );

  const onSearchOptions = (request = "") => {
    dispatch(searchUsersRequest(request, 8));
  };

  const onCheckEmails = (emails: string[], callback: (userIds: number[]) => void) => {
    dispatch(checkEmailsRequest(emails, callback));
  };

  return { userOptions, searchLoading, onSearchOptions, onCheckEmails };
};
