import React from "react";

import { ReactComponent as InfoIcon } from "@epam/assets/icons/common/table-info-outline-18.svg";
import { IconContainer, Tooltip } from "@epam/loveship";

import { useMobileData } from "Hooks/useMobile";

export const CustomTooltip = ({ content, cx }: { content: string; cx?: string }): JSX.Element => {
  const { isMobile } = useMobileData();

  return (
    <Tooltip placement="top" content={content} trigger={isMobile ? "click" : "hover"}>
      <IconContainer icon={InfoIcon} color="night500" cx={`tooltip-icon ${cx}`} />
    </Tooltip>
  );
};

export const renderLabel = (label: string, info?: string, required = true): JSX.Element => (
  <>
    {label}
    {required && <span style={{ color: "red" }}>*</span>}
    {info && <CustomTooltip content={info} />}
  </>
);
