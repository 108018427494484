import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as filterIcon } from "@epam/assets/icons/common/content-filter_list-18.svg";
import { Button, Panel } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { useMobileData } from "Hooks/useMobile";
import useOutsideClick from "Hooks/useOutsideClick";
import { useSynonymWords } from "Hooks/useSynonymWords";

import { IReportViewMode } from "../reports-browser.actions";
import ReportsBrowserFilterSections from "./report-browsers-filter-sections";

import "./style.scss";

interface IReportsBrowserFilterProps {
  onToggle: (state: boolean) => void;
  viewMode: IReportViewMode;
}

const ReportsBrowserFilter: React.FC<IReportsBrowserFilterProps> = ({ onToggle, viewMode }) => {
  const cn = withNaming("reports-browser-filter");
  const [filtersToggle, setFiltersToggle] = useState(false);
  const filtersRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useMobileData();
  const { isSynonymsWordsShow } = useSynonymWords();

  useEffect(() => {
    if (isMobile) return;

    const isOpenOnNewViewMode = viewMode === "cards";
    handleFiltersToggle(isOpenOnNewViewMode);
  }, [viewMode]);

  // Cover cases when filter panel is open and mobile version is toggled
  useEffect(() => {
    if (isMobile && filtersToggle) {
      setFiltersToggle(false);
      onToggle(false);
    } else if (!isMobile && filtersToggle) {
      onToggle(true);
    }
  }, [isMobile]);

  const isOutsideClickEnable = (viewMode === "table" || isMobile) && filtersToggle;
  useOutsideClick(filtersRef, handleFiltersToggle, isOutsideClickEnable);

  function handleFiltersToggle(newState?: boolean | MouseEvent): void {
    const nextState = typeof newState === "boolean" ? newState : !filtersToggle;

    if (!isMobile) {
      onToggle(nextState);
    }

    setFiltersToggle(nextState);
  }

  return (
    <div className={cn()}>
      <div className={cn("wrapper", ["self-start"])} ref={filtersRef}>
        <Button
          cx={cn("toggle-button", { opened: filtersToggle })}
          icon={filterIcon}
          caption="Filters"
          fill={filtersToggle ? "solid" : "none"}
          onClick={handleFiltersToggle}
        />
        <div
          role="section"
          className={cn("modal", {
            show: filtersToggle,
            synonymWords: isSynonymsWordsShow,
            [viewMode]: true,
          })}
        >
          <Panel cx="modal-panel" background="white">
            <ReportsBrowserFilterSections onClose={handleFiltersToggle} />
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default ReportsBrowserFilter;
