import { shallowEqual, useSelector } from "react-redux";

import { IRootReducerState } from "Store/reducers";

type IGenerateSelectorObject = (state: IRootReducerState) => { [key: string]: any };
type IuseMapStateToPropsReturn = { [key: string]: any };

const useMapStateToProps = (generateSelectorObject: IGenerateSelectorObject): IuseMapStateToPropsReturn => {
  return useSelector(generateSelectorObject, shallowEqual);
};

export default useMapStateToProps;
