import React, { FC } from "react";

import Routes from "@App/routes";
import { ReactComponent as eyeIcon } from "@epam/assets/icons/common/action-eye-18.svg";
import { LinkButton, Tooltip } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { getEncodedReportName } from "Helpers/reportsHelper";

import "./view-report-button.scss";

interface IViewReportButtonProps {
  reportName: string;
  className?: string;
}

const cn = withNaming("view-report-button");

export const ViewReportButton: FC<IViewReportButtonProps> = ({ reportName, className }) => {
  return (
    <Tooltip placement="bottom" content="View report details">
      <LinkButton
        cx={cn("", [className])}
        link={{ pathname: `${Routes.REPORTS_BROWSER.path}/${getEncodedReportName(reportName)}` }}
        icon={eyeIcon}
      />
    </Tooltip>
  );
};
