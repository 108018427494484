import type { IFolder } from "SP/folders/folders.types";

export const createTreePath = (tree, key, path = ""): string => {
  if (tree.key === key) {
    return `${path}/${tree.title}`;
  }

  if (Array.isArray(tree.children)) {
    let result = "";

    for (const child of tree.children) {
      result = createTreePath(child, key, `${path}/${tree.title}`);
      if (result) break;
    }

    return result;
  }

  return "";
};

export const searchTree = (tree: IFolder, key: string): IFolder => {
  if (tree.key == key) {
    return tree;
  }

  if (Array.isArray(tree.children)) {
    let result = null;

    for (const child of tree.children) {
      result = searchTree(child, key);
      if (result) break;
    }

    return result;
  }
  return null;
};
