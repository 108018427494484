import { useField } from "formik";
import _debounce from "lodash/debounce";
import _noop from "lodash/noop";
import moment from "moment";
import React, { useEffect, useRef } from "react";

import { DatePicker, FlexRow, LinkButton, Text } from "@epam/loveship";

import { IFieldCommonProps } from "./field.interface";

import "./datepicker-field.scss";

interface IDatePickerFieldProps extends IFieldCommonProps {
  filterCondition?: (day: moment.Moment) => boolean;
  isDisableInputChange?: boolean;
}

export const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";
const today = moment();

const DatePickerField: React.FC<IDatePickerFieldProps> = ({ name, isDisableInputChange, filterCondition }) => {
  const [field, meta, helpers] = useField(name);
  const datePickerRef = useRef<DatePicker>(null);
  const error = meta.touched ? meta.error : "";

  const setTouched = _debounce(() => {
    if (!meta.touched) {
      helpers.setTouched(true);
    }
  }, 500);

  const handleChange = (value: string) => {
    setTouched();
    helpers.setValue(value);
  };

  const handleFilter = (day: moment.Moment) => filterCondition(day);

  useEffect(() => {
    if (isDisableInputChange) {
      datePickerRef.current.handleInputChange = _noop;
    }
  }, []);

  return (
    <>
      <DatePicker
        ref={datePickerRef}
        format={DEFAULT_DATE_FORMAT}
        isInvalid={!!error}
        value={field.value}
        onValueChange={handleChange}
        renderFooter={() => (
          <FlexRow size="36" cx="calendar-footer">
            <LinkButton
              size="36"
              caption="Today"
              onClick={() => datePickerRef.current.setDisplayedDateAndView(today, "DAY_SELECTION")}
            />
          </FlexRow>
        )}
        {...(filterCondition ? { filter: handleFilter } : {})}
      />
      {!!error && (
        <Text color="fire" size="24">
          {error}
        </Text>
      )}
    </>
  );
};

export default DatePickerField;
