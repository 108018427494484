import React, { forwardRef, ReactElement, useCallback, useImperativeHandle, useState } from "react";

import Routes from "@App/routes";
import { ReactComponent as chevronDown } from "@epam/assets/icons/common/navigation-chevron-down-24.svg";
import { ReactComponent as chevronUp } from "@epam/assets/icons/common/navigation-chevron-up-24.svg";
import { IconButton, LinkButton } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";

import "./accordion.scss";

interface IAccordionProps {
  title: string;
  children: ReactElement;
  badgeCount?: number;
  defaultExpanded?: boolean;
  isDisabled?: boolean;
  renderTitle?: (title: string, isDisabled: boolean) => React.ReactElement;
  allReportsFilter?: string;
}

export interface IAccordionHandle {
  handleSetIsExpanded: (t: boolean) => void;
}

export const Accordion = forwardRef<IAccordionHandle, IAccordionProps>(
  ({ defaultExpanded, title, children, isDisabled, badgeCount, renderTitle, allReportsFilter }, ref) => {
    const cn = withNaming("accordion");
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    useImperativeHandle(ref, () => ({
      handleSetIsExpanded(toggle: boolean) {
        setIsExpanded(toggle);
      },
    }));

    const handleToggle = useCallback((e) => {
      setIsExpanded((v) => !v);
    }, []);

    return (
      <div className={cn("", { isDisabled }, ["flex flex-col"])}>
        <span className="flex justify-between items-center">
          <div className={cn("toggler", ["flex items-center"])}>
            <IconButton
              cx={cn("toggler-chevron-icon")}
              icon={isExpanded ? chevronUp : chevronDown}
              onClick={handleToggle}
            />
            <div className={cn("toggler-title")} data-testid="accordion-title">
              {!!renderTitle ? renderTitle(title, isDisabled) : title}
            </div>
            {!allReportsFilter && badgeCount !== undefined && (
              <div data-testid="accordion-badge-count" className={cn("toggler-badge")}>
                {badgeCount}
              </div>
            )}
          </div>
          {allReportsFilter && (
            <LinkButton
              size="30"
              cx={cn("toggler-all-reports-link", { isDisabled })}
              caption={`See all(${badgeCount || 0})`}
              link={{
                pathname: Routes.REPORTS_BROWSER.path,
                search: allReportsFilter,
              }}
            />
          )}
        </span>

        {isExpanded ? <div className={cn("body", ["flex flex-col"])}>{children}</div> : null}
      </div>
    );
  },
);
