import React, { FC } from "react";

import { Text } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { htmlDecode } from "Helpers/utils";

import ReportDetailsRow from "./report-details-row";

interface IReportDetailsTextProps {
  label: string;
  text: string;
  className?: string;
  info?: string;
}

const ReportDetailsText: FC<IReportDetailsTextProps> = ({ label, info, text, className }) => {
  const cn = withNaming("report-text");

  return (
    <ReportDetailsRow label={label} info={info}>
      <Text cx={cn({ [className]: !!className })} fontSize="14" lineHeight="24">
        {htmlDecode(text)}
      </Text>
    </ReportDetailsRow>
  );
};

export default ReportDetailsText;
