import type { ISelectOption } from "Components/select/select.types";

const SEPARATOR = ";";

export function getGetValidOptions(allOptions: ISelectOption[]) {
  return (search: string): string[] => {
    if (!search) return [];

    const allOptionsMap = new Map(allOptions.map((option) => [option.label, option]));
    const searchOptions = search.split(SEPARATOR);
    const validOptions = [];

    for (const option of searchOptions) {
      const trimmedOption = option.trim();

      if (allOptionsMap.has(trimmedOption)) {
        validOptions.push(trimmedOption);
      }
    }

    return validOptions;
  };
}

export function generateCopyOptionsString<T>(options: ISelectOption[], selectedOptionIds: T[]) {
  let copyOptionsString = "";
  const optionsMap = new Map(options.map((option) => [option.id, option]));

  selectedOptionIds.forEach((id) => {
    const option = optionsMap.get(id as unknown as number);

    if (option) {
      copyOptionsString += `${copyOptionsString ? `${SEPARATOR} ` : ""}${option.label}`;
    }
  });

  return copyOptionsString;
}

export function getCheckedOptions(allOptions: ISelectOption[], copiedOptionLabels: string[]) {
  const checkedOptions = [];
  const lowerCaseOptions = copiedOptionLabels.map((option) => option.toLowerCase());

  allOptions.forEach((option) => {
    const label = option.label.toLowerCase();
    if (lowerCaseOptions.includes(label)) {
      checkedOptions.push(option.id);
    }
  });

  return checkedOptions;
}
