import type { CSSProperties } from "react";

import { defaultDropAnimationSideEffects, DropAnimation } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { CSS } from "@dnd-kit/utilities";

export interface IDndItemProps {
  style?: CSSProperties;
  dragOverlay?: boolean;
  active?: boolean;
  attributes?: {
    role: string;
    tabIndex: number;
    "aria-pressed": boolean | undefined;
    "aria-roledescription": string;
    "aria-describedby": string;
  };
  listeners?: SyntheticListenerMap;
  handleRef?: (element: HTMLElement | null) => void;
}

export const defaultDropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export const touchSensorOptions = {
  activationConstraint: {
    delay: 250,
    tolerance: 5,
  },
};

export function getStyles(transform, transition): CSSProperties {
  return {
    transform: CSS.Transform.toString(transform),
    transition,
  };
}
