import React from "react";

import type { IReportViewMode } from "../reports-browser.actions";
import ReportCards from "./report-cards/report-cards";
import ReportsTable from "./report-table/reports-table";

export interface IReportViewProps {
  viewMode: IReportViewMode;
}

const ReportView: React.FC<IReportViewProps> = ({ viewMode }) => {
  return viewMode === "table" ? <ReportsTable /> : <ReportCards />;
};

export default ReportView;
