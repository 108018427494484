import { fetchClientFactory } from "./msal";

type IHeader = { key: string; value: string };
interface IGraphGetParams {
  url: string;
  headers?: IHeader[];
  readAs?: keyof Omit<Body, "body" | "bodyUsed">;
}

export const graphConfig = {
  graphMePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photos/48x48/$value",
  graphMemberOfEndpoint: "https://graph.microsoft.com/v1.0/me/memberOf?$select=displayName,id",
  nextLinkField: "@odata.nextLink",
};

const ERROR_STATUSES = [401, 403, 404, 423];

export async function graphGet<T>({ url, headers = [], readAs = "json" }: IGraphGetParams): Promise<T> {
  try {
    const accessToken = await fetchClientFactory.getToken();
    if (!accessToken) {
      throw new Error("Access token is missing!");
    }

    const headersCollector = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headersCollector.append("Authorization", bearer);
    headers.forEach(({ key, value }) => {
      headersCollector.append(key, value);
    });

    const options = {
      method: "GET",
      headers: headersCollector,
    };

    const response = await fetch(url, options);
    if (ERROR_STATUSES.includes(response.status)) {
      throw new Error(`${response.status} - ${response.statusText}: ${response.url}`);
    }

    return response[readAs]();
  } catch (error) {
    console.info(error);
    return null;
  }
}
