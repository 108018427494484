import { call, fork, ForkEffect, put, select, takeEvery } from "redux-saga/effects";

import ReportsService from "SP/reports";
import {
  getPermissionsOnReportFailure,
  getPermissionsOnReportSuccess,
  IGetPermissionsOnReportRequestAction,
  PermissionsActionTypes,
} from "Store/actions/permissions.actions";
import { IRootReducerState } from "Store/reducers";

const reportsService = new ReportsService();

function* retrievePermissions(action: IGetPermissionsOnReportRequestAction) {
  try {
    let permissions = yield select((state: IRootReducerState) => state.permissions.permissions[action.reportId]);
    if (!permissions) {
      permissions = yield call([reportsService, "getPermissionsOnItem"], action.reportId);
    }
    yield put(getPermissionsOnReportSuccess(action.reportId, permissions));
  } catch (error) {
    yield put(getPermissionsOnReportFailure(action.reportId, error));
    throw error;
  }
}

function* watchRetrievePermissions() {
  yield takeEvery(PermissionsActionTypes.GET_PERMISSION_ON_REPORT_REQUEST, retrievePermissions);
}

export default function* permissionsSagas(): Iterator<ForkEffect> {
  yield fork(watchRetrievePermissions);
}
