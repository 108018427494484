import _cloneDeep from "lodash/cloneDeep";
import React, { FC, useMemo, useState } from "react";

import { DataTable, DataTableRow } from "@epam/loveship";
import { DataRowProps, DataSourceState, useArrayDataSource } from "@epam/uui";

import type { IReportVersion } from "SP/reports/reports.types";

import { cn, getVersionColumns, renderDiff } from "./version-history.helpers";

import "./version-history.scss";

interface IVersionHistoryProps {
  isMobile: boolean;
  versions: IReportVersion[];
}

export const VersionHistory: FC<IVersionHistoryProps> = ({ isMobile, versions }) => {
  const [value, onValueChange] = useState<DataSourceState<any, string>>({
    sorting: [{ field: "versionLabel", direction: "desc" }],
  });
  const isSortASC = value.sorting[0].direction === "asc";
  const sortedVersions = useMemo(() => (isSortASC ? _cloneDeep(versions).reverse() : versions), [isSortASC, versions]);

  const dataSource = useArrayDataSource<IReportVersion, string, unknown>({
    items: versions,
    getId: (v) => v.versionLabel,
  });

  const view = dataSource.useView(value, onValueChange, {
    sortBy: (item, sorting) => {
      if (sorting.field === "versionLabel") {
        return item.versionLabel;
      }
    },
  });

  const renderRow = (props: DataRowProps<IReportVersion, string>) => {
    const compareIndex = isSortASC ? props.index - 1 : props.index + 1;
    const isWithoutDiffRow = isSortASC ? props.index === 0 : versions.length === props.index + 1;

    if (isWithoutDiffRow) {
      return <DataTableRow key={props.rowKey} size="none" borderBottom="none" {...props} />;
    }

    return (
      <div data-testid="version-history-table-row" className={cn("table-row")} key={props.rowKey}>
        <DataTableRow size="none" borderBottom="none" {...props} />
        {renderDiff(props.value, sortedVersions[compareIndex])}
      </div>
    );
  };

  return (
    <DataTable
      renderRow={renderRow}
      getRows={view.getVisibleRows}
      value={value}
      onValueChange={onValueChange}
      columns={getVersionColumns(isMobile)}
      headerTextCase="upper"
      border="none"
      {...view.getListProps()}
    />
  );
};
