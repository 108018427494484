import React from "react";

import { Button, Tooltip } from "@epam/loveship";

import FavoriteButton from "Components/favorite-button";
import { TimingSpan } from "Components/timing-elements";
import withNaming from "Helpers/bemClassname";

import { IButton, ISubheaderProps } from "./subheader.types";

import "./style.scss";

const cn = withNaming("subheader");

const SubheaderButton: React.FC<IButton> = (props) => (
  <Button key={props.caption} cx={cn("button", ["btn", `btn--${props.color || "ghost"}`])} {...props} />
);

const Subheader: React.FC<ISubheaderProps> = ({ buttons, title, isView, reportId }) => {
  const isForm = !isView;

  return (
    <div className={cn()}>
      <div className={cn("container", ["flex items-center justify-between"])}>
        <div className={cn("title", { "view-mode": isView }, ["flex items-center"])}>
          <TimingSpan elementtiming="subheader-title">{title}</TimingSpan>
          {isForm && !!reportId && (
            <FavoriteButton isTransparentIcon className={cn("favorite-icon")} reportId={reportId} />
          )}
        </div>
        <div className={cn("buttons", ["flex"])}>
          {buttons
            .filter((button) => button.visible)
            .map((button) =>
              button.tooltipText ? (
                <Tooltip placement="bottom" key={button.caption} content={button.tooltipText}>
                  <SubheaderButton {...button} />
                </Tooltip>
              ) : (
                <SubheaderButton key={button.caption} {...button} />
              ),
            )}
        </div>
      </div>
    </div>
  );
};

export default Subheader;
