import React, { ReactElement, useCallback, useRef } from "react";

import { Tooltip as TooltipType } from "@epam/uui-components";

import { generateCopyOptionsString, getCheckedOptions, getGetValidOptions } from "Helpers/optionsCopy";
import { useCopyText } from "Hooks/useCopyText";

import { Select } from "./select";
import { CopyIcon } from "./select-copy-icon";
import { NotFoundBlock } from "./select-not-found";
import type { ISelectProps } from "./select.types";

export function SelectWithCopy<IType>({
  error,
  value,
  options,
  placeholder,
  loading,
  onValueChange,
  onSearchChange,
  renderRow,
  renderTogglerItem,
  addOnCopy,
  strictOnSearch,
}: ISelectProps<IType>): ReactElement {
  const tooltipRef = useRef<TooltipType>(null);
  const { isCopied, handleCopyUrl } = useCopyText(tooltipRef);

  const getValidOptions = useCallback(getGetValidOptions(options), [options]);

  const handleSearchChange = useCallback(
    (searchValue: string) => {
      if (getValidOptions(searchValue).length > 1 && !strictOnSearch) return;
      onSearchChange?.(searchValue);
    },
    [getValidOptions, onSearchChange, strictOnSearch],
  );

  const handleCheckOptions = useCallback(
    (copiedOptionLabels: string[], onClose) => {
      const checkedOptions = getCheckedOptions(options, copiedOptionLabels);
      // Get rid off duplicate values
      const values = new Set<IType>([...value, ...checkedOptions]);
      onValueChange(Array.from(values));
      onClose();
    },
    [getCheckedOptions, options, value, onValueChange],
  );

  const handleCopy = useCallback(
    (e) => {
      let valueToCopy: any = value;
      if (addOnCopy) {
        valueToCopy = [...value, ...addOnCopy()];
      }
      const copyOptionsString = generateCopyOptionsString<IType>(options, valueToCopy);
      handleCopyUrl(e, copyOptionsString);
    },
    [addOnCopy, value, options],
  );

  return (
    <Select<IType>
      strictOnSearch
      value={value}
      error={error}
      loading={loading}
      options={options}
      placeholder={placeholder}
      onValueChange={onValueChange}
      onSearchChange={handleSearchChange}
      renderRow={renderRow}
      renderTogglerItem={renderTogglerItem}
      renderCopyIcon={({ cx }) => (
        <CopyIcon
          cx={cx}
          tooltipRef={tooltipRef}
          tooltipContent={isCopied ? "Options are copied to your clipboard" : "Copy selected options"}
          onCopy={handleCopy}
        />
      )}
      renderNotFound={(props) => (
        <NotFoundBlock autoCheck getData={getValidOptions} onCheck={handleCheckOptions} {...props} />
      )}
    />
  );
}
