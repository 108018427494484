import React from "react";
import { useSelector } from "react-redux";

import mainLogo from "@App/assets/reports-logo.svg";
import {
  BurgerButton,
  Button,
  FlexSpacer,
  GlobalMenu,
  MainMenu,
  MainMenuAvatar,
  MainMenuButton,
  MainMenuLogo,
} from "@epam/loveship";

import { getRedirectProp, headerButtons, onBurgerButtonClick } from "Components/header/header.helpers";
import withNames from "Helpers/bemClassname";
import { SHARE_POINT_LINK } from "Helpers/constants";
import { UserPhoto } from "Helpers/storage";
import { useMobileData } from "Hooks/useMobile";
import { useUsersData } from "Hooks/useUsers";
import { IRootReducerState } from "Store/reducers";

import Routes from "../../routes";

import "./header.scss";

const Header: React.FC = () => {
  const cn = withNames("header");
  const { isMobile } = useMobileData();
  const { currentUser } = useUsersData();
  const isGlobalErrorThrow = useSelector<IRootReducerState, boolean>((state) => state.global.isGlobalErrorThrow);

  const menuLogo = <MainMenuLogo link={{ pathname: Routes.HOME.path }} logoUrl={mainLogo} />;

  const renderBurger = ({ onClose }) => (
    <>
      {headerButtons.map((headerButton) => (
        <BurgerButton
          key={headerButton.caption}
          {...headerButton}
          onClick={onBurgerButtonClick(onClose, isGlobalErrorThrow)}
        />
      ))}
      {currentUser?.permissions.canAddReport && (
        <BurgerButton
          link={{ pathname: Routes.ADD_REPORT.path }}
          onClick={onBurgerButtonClick(onClose, isGlobalErrorThrow)}
          caption="Add Report"
        />
      )}
    </>
  );

  return (
    <MainMenu alwaysShowBurger={isMobile} cx={cn("wrapper")} renderBurger={renderBurger}>
      <div className={cn("container")}>
        {isGlobalErrorThrow ? <div {...getRedirectProp(true)}>{menuLogo}</div> : menuLogo}
        {!isMobile && (
          <>
            {headerButtons.map((headerButton) => (
              <MainMenuButton
                key={headerButton.caption}
                cx={cn("menu-button", [headerButton.className])}
                {...headerButton}
                {...getRedirectProp(isGlobalErrorThrow)}
              />
            ))}
          </>
        )}
        <FlexSpacer priority={100500} />
        {!isMobile && !!currentUser?.permissions && (
          <Button cx={cn("sp-button")} caption="SharePoint" target="_blank" href={SHARE_POINT_LINK} />
        )}
        {!isMobile && currentUser?.permissions.canAddReport && (
          <Button
            cx={cn("add-button")}
            link={{ pathname: Routes.ADD_REPORT.path }}
            {...getRedirectProp(isGlobalErrorThrow)}
            caption="Add Report"
            color="grass"
          />
        )}
        <MainMenuAvatar isDropdown={false} avatarUrl={currentUser?.photo || UserPhoto.get()} />
        <GlobalMenu estimatedWidth={60} priority={100500} />
      </div>
    </MainMenu>
  );
};

export default Header;
