import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import usePermissions from "Hooks/usePermissions";
import { useSingleReportMethods } from "Hooks/useSingleReport";
import type { IFile } from "SP/reports/reports.types";
import type { IRootReducerState } from "Store/reducers";

const isNeedToGetReportInfo = (link: string, file: IFile) => !link && !file;

const isShowLoading = (link: string, file: IFile, loading: boolean) => {
  return isNeedToGetReportInfo(link, file) || loading;
};

export const useFile = (reportId: number, reportLink: string) => {
  const file = useSelector<IRootReducerState, IFile>((state) => state.reports.reportsFiles[reportId]);
  const { permissions, permissionsLoading, getReportPermissions } = usePermissions(reportId);
  const { getReportFileUrl } = useSingleReportMethods();

  const wrapperClassName = useMemo(() => {
    const classNames = ["overflow-hidden unselect"];

    if (isShowLoading(reportLink, file, permissionsLoading)) {
      classNames.push("cursor-wait");
    } else if (file && !permissions?.canDownload) {
      classNames.push("cursor-not-allowed");
    }

    return classNames.join(" ");
  }, [reportLink, file, permissions, permissionsLoading]);

  const getReportFileAndPermissions = useCallback(() => {
    if (isNeedToGetReportInfo(reportLink, file)) {
      getReportPermissions();
      getReportFileUrl(reportId);
    }
  }, [reportId, reportLink, file]);

  return {
    file,
    canDownload: permissions?.canDownload,
    wrapperClassName,
    getReportFileAndPermissions,
  };
};
