import produce from "immer";
import { Reducer } from "redux";

import { IReportPermissions } from "SP/reports/reports.types";
import { IPermissionsActions, PermissionsActionTypes } from "Store/actions/permissions.actions";

export interface IPermissionsState {
  permissions: {
    [reportId: number]: IReportPermissions;
  };
  permissionsLoading: {
    [reportId: number]: boolean;
  };
  error: string;
}

const initialState: IPermissionsState = {
  permissions: {},
  permissionsLoading: {},
  error: null,
};

const permissionsReducer: Reducer<IPermissionsState, IPermissionsActions> = produce((draft, action) => {
  switch (action.type) {
    case PermissionsActionTypes.GET_PERMISSION_ON_REPORT_REQUEST:
      draft.permissionsLoading[action.reportId] = true;
      break;
    case PermissionsActionTypes.GET_PERMISSION_ON_REPORT_SUCCESS:
      draft.permissionsLoading[action.reportId] = false;
      draft.permissions[action.reportId] = action.permissions;
      break;
    case PermissionsActionTypes.GET_PERMISSION_ON_REPORT_FAILURE:
      draft.error = action.error;
      draft.permissionsLoading[action.reportId] = false;
      break;
  }
}, initialState);

export default permissionsReducer;
