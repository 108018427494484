import type { IDeleteReportSuccessAction } from "Pages/report/report.actions";
import type { IFavorite } from "SP/favorites/favorites.types";

export enum FavoritesActionTypes {
  GET_CURRENT_USER_FAVORITES_REQUEST = "[FAVORITES] GET CURRENT USER FAVORITES REQUEST",
  GET_CURRENT_USER_FAVORITES_SUCCESS = "[FAVORITES] GET CURRENT USER FAVORITES SUCCESS",
  GET_CURRENT_USER_FAVORITES_FAILURE = "[FAVORITES] GET CURRENT USER FAVORITES FAILURE",

  FAVORITE_REPORT_REQUEST = "[FAVORITES] FAVORITE REPORT REQUEST",
  FAVORITE_REPORT_SUCCESS = "[FAVORITES] FAVORITE REPORT SUCCESS",
  FAVORITE_REPORT_FAILURE = "[FAVORITES] FAVORITE REPORT FAILURE",

  UNFAVORITE_REPORT_REQUEST = "[FAVORITES] UNFAVORITE REPORT REQUEST",
  UNFAVORITE_REPORT_SUCCESS = "[FAVORITES] UNFAVORITE REPORT SUCCESS",
  UNFAVORITE_REPORT_FAILURE = "[FAVORITES] UNFAVORITE REPORT FAILURE",
}

// GET CURRENT USER FAVORITES
export interface IGetCurrentUserFavoritesRequestAction {
  type: FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_REQUEST;
  userId: number;
}

export function getCurrentUserFavorites(userId: number): IGetCurrentUserFavoritesRequestAction {
  return {
    type: FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_REQUEST,
    userId,
  };
}

export interface IGetCurrentUserFavoritesSuccessAction {
  type: FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_SUCCESS;
  payload: IFavorite[];
}

export function getCurrentUserFavoritesSuccess(favorites: IFavorite[]): IGetCurrentUserFavoritesSuccessAction {
  return {
    type: FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_SUCCESS,
    payload: favorites,
  };
}

export interface IGetCurrentUserFavoritesFailureAction {
  type: FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_FAILURE;
  error: string;
}

export function getCurrentUserFavoritesFailure(error: Error): IGetCurrentUserFavoritesFailureAction {
  return {
    type: FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_FAILURE,
    error: error.message,
  };
}

// FAVORITE REPORT
export interface IFavoriteReportRequestAction {
  type: FavoritesActionTypes.FAVORITE_REPORT_REQUEST;
  reportId: number;
}

export function favoriteReport(reportId: number): IFavoriteReportRequestAction {
  return {
    type: FavoritesActionTypes.FAVORITE_REPORT_REQUEST,
    reportId,
  };
}

export interface IFavoriteReportSuccessAction {
  type: FavoritesActionTypes.FAVORITE_REPORT_SUCCESS;
  favoriteItem: IFavorite;
  reportId: number;
}

export function favoriteReportSuccess(favoriteItem: IFavorite, reportId: number): IFavoriteReportSuccessAction {
  return {
    type: FavoritesActionTypes.FAVORITE_REPORT_SUCCESS,
    favoriteItem,
    reportId,
  };
}

export interface IFavoriteReportFailureAction {
  type: FavoritesActionTypes.FAVORITE_REPORT_FAILURE;
  error: string;
  reportId: number;
}

export function favoriteReportFailure(error: Error, reportId: number): IFavoriteReportFailureAction {
  return {
    type: FavoritesActionTypes.FAVORITE_REPORT_FAILURE,
    error: error.message,
    reportId,
  };
}

// UNFAVORITE REPORT
export interface IUnfavoriteReportRequestAction {
  type: FavoritesActionTypes.UNFAVORITE_REPORT_REQUEST;
  reportId: number;
  favoriteId: number;
}

export function unfavoriteReport(reportId: number, favoriteId: number): IUnfavoriteReportRequestAction {
  return {
    type: FavoritesActionTypes.UNFAVORITE_REPORT_REQUEST,
    reportId,
    favoriteId,
  };
}

export interface IUnfavoriteReportSuccessAction {
  type: FavoritesActionTypes.UNFAVORITE_REPORT_SUCCESS;
  reportId: number;
}

export function unfavoriteReportSuccess(reportId: number): IUnfavoriteReportSuccessAction {
  return {
    type: FavoritesActionTypes.UNFAVORITE_REPORT_SUCCESS,
    reportId,
  };
}

export interface IUnfavoriteReportFailureAction {
  type: FavoritesActionTypes.UNFAVORITE_REPORT_FAILURE;
  error: string;
  reportId: number;
}

export function unfavoriteReportFailure(error: Error, reportId: number): IUnfavoriteReportFailureAction {
  return {
    type: FavoritesActionTypes.UNFAVORITE_REPORT_FAILURE,
    error: error.message,
    reportId,
  };
}

export type IFavoritesActionTypes =
  | IGetCurrentUserFavoritesRequestAction
  | IGetCurrentUserFavoritesSuccessAction
  | IGetCurrentUserFavoritesFailureAction
  | IFavoriteReportRequestAction
  | IFavoriteReportSuccessAction
  | IFavoriteReportFailureAction
  | IUnfavoriteReportRequestAction
  | IUnfavoriteReportSuccessAction
  | IUnfavoriteReportFailureAction
  | IDeleteReportSuccessAction;
