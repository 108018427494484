import React from "react";

import { ReactComponent as searchIcon } from "@epam/assets/icons/common/action-search-24.svg";
import { IconContainer } from "@epam/loveship";

import { TimingDiv } from "Components/timing-elements";
import { VirtualList } from "Components/virtual-list";
import withNaming from "Helpers/bemClassname";
import { useReportsData, useReportsSort } from "Hooks/index";
import { IReportFields, ISort } from "SP/reports/reports.types";

import ColumnName from "./column-name";
import TableRow from "./table-row";

import "./reports-table.scss";

const columnNames = [
  { label: "", flexBasis: "3.6%", sortFieldName: IReportFields.Certified },
  { label: "NAME", flexBasis: "16.8%", sortFieldName: IReportFields.ReportName },
  { label: "DESCRIPTION", flexBasis: "26%" },
  { label: "OWNER", flexBasis: "7.8%" },
  { label: "ROLE", flexBasis: "9.6%" },
  { label: "BUSINESS DOMAIN", flexBasis: "9.6%" },
  { label: "APP", flexBasis: "8.6%" },
  { label: "METRIC", flexBasis: "9.6%" },
  {
    label: "MOD.DATE",
    flexBasis: "8%",
    sortFieldName: IReportFields.UserDefinedModificationDate,
    tooltip: "Report structure Modification date",
  },
];

const addASCProp = (sort: ISort, sortFieldName: IReportFields) => {
  return sort && sort.field === sortFieldName && { sortASC: sort.asc };
};

const ReportsTable = () => {
  const cn = withNaming("report-table");
  const { isFilteredReportsLoaded, filteredReports } = useReportsData();
  const { sort, sortedReports, setSort } = useReportsSort(filteredReports, true);
  const isReportsPresent = sortedReports.length > 0;

  return (
    <div className={cn()}>
      <TimingDiv className={cn("header")} elementtiming="table-header">
        All reports
      </TimingDiv>
      <div className={cn("content")}>
        <div className={cn("columns-headers")}>
          {columnNames.map(({ label, flexBasis, sortFieldName, tooltip }) => (
            <ColumnName
              key={label}
              label={label}
              flexBasis={flexBasis}
              sortFieldName={sortFieldName}
              onSort={setSort}
              tooltip={tooltip}
              {...addASCProp(sort, sortFieldName)}
            />
          ))}
        </div>
        {isReportsPresent && <VirtualList type="table" items={sortedReports} estimateSize={74} ListItem={TableRow} />}
        {isFilteredReportsLoaded && !isReportsPresent && (
          <div className={cn("not-found-content", ["flex flex-col items-center"])}>
            <IconContainer cx={cn("not-found-content-loop")} icon={searchIcon} />
            <span>No Reports Found</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportsTable;
