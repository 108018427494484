import { ISetAllReportsOwnersAction } from "Pages/reports-browser/reports-browser.actions";
import { IUser, IUserExtended } from "SP/users/users.types";

export enum UsersActionsTypes {
  SEARCH_USERS_REQUEST = "[USERS] SEARCH USERS REQUEST",
  SEARCH_USERS_SUCCESS = "[USERS] SEARCH USERS SUCCESS",
  SEARCH_USERS_FAILURE = "[USERS] SEARCH USERS FAILURE",

  GET_USERS_BY_IDS_REQUEST = "[USERS] GET USERS BY IDS REQUEST",
  GET_USERS_BY_IDS_SUCCESS = "[USERS] GET USERS BY IDS SUCCESS",
  GET_USERS_BY_IDS_FAILURE = "[USERS] GET USERS BY IDS FAILURE",

  GET_USERS_BY_NAMES_REQUEST = "[USERS] GET USERS BY NAMES REQUEST",
  GET_USERS_BY_NAMES_SUCCESS = "[USERS] GET USERS BY NAMES SUCCESS",
  GET_USERS_BY_NAMES_FAILURE = "[USERS] GET USERS BY NAMES FAILURE",

  GET_CURRENT_USER_REQUEST = "[USERS] GET CURRENT USER REQUEST",
  GET_CURRENT_USER_SUCCESS = "[USERS] GET CURRENT USER SUCCESS",
  GET_CURRENT_USER_FAILURE = "[USERS] GET CURRENT USER FAILURE",

  CHECK_EMAILS_REQUEST = "[USERS] CHECK EMAILS REQUEST",
  CHECK_EMAILS_SUCCESS = "[USERS] CHECK EMAILS SUCCESS",
  CHECK_EMAILS_FAILURE = "[USERS] CHECK EMAILS FAILURE",

  UPDATE_CURRENT_USER = "[USERS] UPDATE CURRENT USER",
}

export interface ISearchUsersRequestAction {
  type: UsersActionsTypes.SEARCH_USERS_REQUEST;
  search: string;
  limit: number;
}

export function searchUsersRequest(search: string, limit: number): ISearchUsersRequestAction {
  return {
    type: UsersActionsTypes.SEARCH_USERS_REQUEST,
    search,
    limit,
  };
}

export interface ISearchUsersSuccessAction {
  type: UsersActionsTypes.SEARCH_USERS_SUCCESS;
  payload: IUser[];
}

export function searchUsersSuccess(users: IUser[]): ISearchUsersSuccessAction {
  return {
    type: UsersActionsTypes.SEARCH_USERS_SUCCESS,
    payload: users,
  };
}

export interface ISearchUsersFailureAction {
  type: UsersActionsTypes.SEARCH_USERS_FAILURE;
  error: string;
}

export function searchUsersFailure(error: Error): ISearchUsersFailureAction {
  return {
    type: UsersActionsTypes.SEARCH_USERS_FAILURE,
    error: error.message,
  };
}

export interface IGetUsersByIdsRequestAction {
  type: UsersActionsTypes.GET_USERS_BY_IDS_REQUEST;
  ids: number[];
}

export function getUsersByIdsRequest(ids: number[]): IGetUsersByIdsRequestAction {
  return {
    type: UsersActionsTypes.GET_USERS_BY_IDS_REQUEST,
    ids,
  };
}

export interface IGetUsersByIdsSuccessAction {
  type: UsersActionsTypes.GET_USERS_BY_IDS_SUCCESS;
  payload: IUser[];
}

export function getUsersByIdsSuccess(users: IUser[]): IGetUsersByIdsSuccessAction {
  return {
    type: UsersActionsTypes.GET_USERS_BY_IDS_SUCCESS,
    payload: users,
  };
}

export interface IGetUsersByIdsFailureAction {
  type: UsersActionsTypes.GET_USERS_BY_IDS_FAILURE;
  payload: string;
}

export function getUsersByIdsFailure(error: Error): IGetUsersByIdsFailureAction {
  return {
    type: UsersActionsTypes.GET_USERS_BY_IDS_FAILURE,
    payload: error.message,
  };
}

// GET USERS BY NAMES
export interface IGetUsersByNamesRequestAction {
  type: UsersActionsTypes.GET_USERS_BY_NAMES_REQUEST;
  names: string[];
}

export function getUsersByNamesRequest(names: string[]): IGetUsersByNamesRequestAction {
  return {
    type: UsersActionsTypes.GET_USERS_BY_NAMES_REQUEST,
    names,
  };
}

export interface IGetUsersByNamesSuccessAction {
  type: UsersActionsTypes.GET_USERS_BY_NAMES_SUCCESS;
  payload: IUser[];
}

export function getUsersByNamesSuccess(users: IUser[]): IGetUsersByNamesSuccessAction {
  return {
    type: UsersActionsTypes.GET_USERS_BY_NAMES_SUCCESS,
    payload: users,
  };
}

export interface IGetUsersByNamesFailureAction {
  type: UsersActionsTypes.GET_USERS_BY_NAMES_FAILURE;
  payload: string;
}

export function getUsersByNamesFailure(error: Error): IGetUsersByNamesFailureAction {
  return {
    type: UsersActionsTypes.GET_USERS_BY_NAMES_FAILURE,
    payload: error.message,
  };
}

// GET CURRENT USER
export interface IGetCurrentUserRequestAction {
  type: UsersActionsTypes.GET_CURRENT_USER_REQUEST;
}

export function getCurrentUser(): IGetCurrentUserRequestAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_REQUEST,
  };
}

export interface IGetCurrentUserSuccessAction {
  type: UsersActionsTypes.GET_CURRENT_USER_SUCCESS;
  payload: IUserExtended;
}

export function getCurrentUserSuccess(user: IUserExtended): IGetCurrentUserSuccessAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_SUCCESS,
    payload: user,
  };
}

export interface IGetCurrentUserFailureAction {
  type: UsersActionsTypes.GET_CURRENT_USER_FAILURE;
  payload: string;
}

export function getCurrentUserFailure(error: Error): IGetCurrentUserFailureAction {
  return {
    type: UsersActionsTypes.GET_CURRENT_USER_FAILURE,
    payload: error.message,
  };
}

export interface ICheckEmailsRequestAction {
  type: UsersActionsTypes.CHECK_EMAILS_REQUEST;
  emails: string[];
  callback: (userIds: number[]) => void;
}

export function checkEmailsRequest(emails: string[], callback: (userIds: number[]) => void): ICheckEmailsRequestAction {
  return {
    type: UsersActionsTypes.CHECK_EMAILS_REQUEST,
    emails,
    callback,
  };
}

export interface ICheckEmailsSuccessAction {
  type: UsersActionsTypes.CHECK_EMAILS_SUCCESS;
  payload: IUser[];
}

export function checkEmailsSuccess(users: IUser[]): ICheckEmailsSuccessAction {
  return {
    type: UsersActionsTypes.CHECK_EMAILS_SUCCESS,
    payload: users,
  };
}

export interface ICheckEmailsFailureAction {
  type: UsersActionsTypes.CHECK_EMAILS_FAILURE;
  error: string;
}

export function checkEmailsFailure(error: Error): ICheckEmailsFailureAction {
  return {
    type: UsersActionsTypes.CHECK_EMAILS_FAILURE,
    error: error.message,
  };
}

export interface IUpdateCurrentUser {
  type: UsersActionsTypes.UPDATE_CURRENT_USER;
  currentUserUpdate: Partial<IUserExtended>;
}

export function updateCurrentUser(currentUserUpdate: Partial<IUserExtended>): IUpdateCurrentUser {
  return {
    type: UsersActionsTypes.UPDATE_CURRENT_USER,
    currentUserUpdate,
  };
}

export type IUsersActionsTypes =
  | ISearchUsersRequestAction
  | ISearchUsersSuccessAction
  | ISearchUsersFailureAction
  | IGetUsersByIdsRequestAction
  | IGetUsersByIdsSuccessAction
  | IGetUsersByIdsFailureAction
  | IGetUsersByNamesRequestAction
  | IGetUsersByNamesSuccessAction
  | IGetUsersByNamesFailureAction
  | IGetCurrentUserRequestAction
  | IGetCurrentUserSuccessAction
  | IGetCurrentUserFailureAction
  | ISetAllReportsOwnersAction
  | ICheckEmailsRequestAction
  | ICheckEmailsSuccessAction
  | ICheckEmailsFailureAction
  | IUpdateCurrentUser;
