import { PnPFetchClient } from "@App/libs/pnp";
import {
  Configuration,
  EventMessage,
  EventMessageUtils,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";

export const msalInstance = (function () {
  const msalConfig: Configuration = {
    auth: {
      clientId: process.env.REACT_APP_GRAPH_CLIENT_ID,
      authority: process.env.REACT_APP_GRAPH_AUTHORITY,
      navigateToLoginRequestUrl: false,
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      windowHashTimeout: 60000,
      iframeHashTimeout: 6000,
      loadFrameTimeout: 0,
    },
  };

  return new PublicClientApplication(msalConfig);
})();

export const interactions = (function () {
  enum MsalProviderActionType {
    UNBLOCK_INPROGRESS = "UNBLOCK_INPROGRESS",
    EVENT = "EVENT",
  }

  const state = {
    inProgress: InteractionStatus.Startup,
  };

  function getInteractionStatus() {
    return state.inProgress;
  }

  function updateState(message: EventMessage, type: MsalProviderActionType) {
    let newInProgress = state.inProgress;

    switch (type) {
      case MsalProviderActionType.UNBLOCK_INPROGRESS:
        if (state.inProgress === InteractionStatus.Startup) {
          newInProgress = InteractionStatus.None;
        }
        break;
      case MsalProviderActionType.EVENT:
        const status = EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress);
        if (status) {
          newInProgress = status;
        }
        break;
      default:
        throw new Error(`Unknown action type: ${type}`);
    }

    if (newInProgress !== state.inProgress) {
      state.inProgress = newInProgress;
    }
  }

  return {
    updateState,
    getInteractionStatus,
    MsalProviderActionType,
  };
})();

export const fetchClientFactory = new PnPFetchClient(msalInstance);
