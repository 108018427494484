import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import Routes from "@App/routes";
import { Panel } from "@epam/loveship";

import { Layout } from "Components/layout";
import InlineLoader from "Components/loader";
import Subheader, { IButton } from "Components/subheader";
import { getEncodedReportName } from "Helpers/reportsHelper";
import { handleHistoryBack } from "Helpers/utils";
import { useNotifications, useSingleReportData, useSingleReportMethods } from "Hooks/index";
import { notificationTypes } from "Store/actions/notifications.actions";

import { panelStyles } from "../add-report/add-report";
import ReportDetails from "./report-details";

const Report: React.FC<RouteComponentProps> = ({ history, match }) => {
  const { showNotification } = useNotifications();
  const reportIdentifier = match.params["reportName"]; // Could be also old url with id
  const { report, loading, error, permissions } = useSingleReportData();
  const { deleteReport, resetReport, getReport } = useSingleReportMethods();

  const subHeaderButtons: IButton[] = [
    {
      caption: "Delete",
      fill: "white",
      color: "night500",
      onClick: handleDelete,
      visible: permissions?.canDelete,
    },
    {
      caption: "Edit",
      fill: "white",
      color: "night500",
      onClick: () => history.push(`${Routes.REPORTS_BROWSER.path}/${getEncodedReportName(report.reportName)}/edit`),
      visible: permissions?.canEdit,
    },
    {
      caption: "Back",
      fill: "white",
      color: "night500",
      onClick: () => handleHistoryBack(history),
      visible: true,
    },
  ];

  function handleDelete() {
    showNotification({
      type: notificationTypes.warning,
      text: "Are you sure you want to delete this report?",
      actions: (props) => [
        {
          name: "Delete",
          action: () => {
            deleteReport(report.id, () => {
              props.onClose();
              history.push("/reports");
            });
          },
        },
      ],
      duration: "forever",
      size: "large",
    });
  }

  useEffect(() => {
    getReport(reportIdentifier);

    return () => {
      resetReport();
    };
  }, []);

  if (error) {
    return <>Ooops, something went wrong.</>;
  }

  return (
    <Layout>
      {loading && <InlineLoader />}
      {report && (
        <>
          <Subheader isView reportId={report.id} title={report.reportName} buttons={subHeaderButtons} />
          <Panel background="white" style={panelStyles}>
            <ReportDetails {...report} />
          </Panel>
        </>
      )}
    </Layout>
  );
};

export default Report;
