import React, { ReactElement, useLayoutEffect, useRef } from "react";

import { useWindowVirtualizer } from "@tanstack/react-virtual";

import withNaming from "Helpers/bemClassname";

import type { IVirtualListProps } from "./virtual-list.types";

import "./virtual-list.scss";

export function VirtualList<IType extends { id: string | number }>({
  items,
  ListItem,
  estimateSize,
  type,
}: IVirtualListProps<IType>): ReactElement {
  const cn = withNaming("virtual-list");

  const parentRef = useRef<HTMLDivElement>(null);
  const parentOffsetRef = useRef(0);

  useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  const virtualizer = useWindowVirtualizer({
    count: items.length,
    estimateSize: () => estimateSize,
    scrollMargin: parentOffsetRef.current,
    overscan: 5,
  });
  const virtualItems = virtualizer.getVirtualItems();

  return (
    <div ref={parentRef} className={cn("", { [type]: !!type })}>
      <div
        className={cn("wrapper")}
        style={{
          height: virtualizer.getTotalSize(),
          width: "100%",
          position: "relative",
        }}
      >
        <div
          className={cn("items")}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0].start - virtualizer.options.scrollMargin}px)`,
          }}
        >
          {virtualItems.map(({ index, key }) => (
            <ListItem key={key} measureElement={virtualizer.measureElement} {...items[index]} />
          ))}
        </div>
      </div>
    </div>
  );
}
