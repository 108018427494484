import { PnPClientStorageWrapper } from "@pnp/common";

import { hash, parseJson } from "Helpers/utils";

const cacheExpirationIntervalInMinutes = 30;
export const localStorageGetAllPrefix = hash("getAll").toString();

export default (function () {
  const storage = new PnPClientStorageWrapper(localStorage, cacheExpirationIntervalInMinutes);

  interface ITypes {
    news: Map<string, any>;
    reports: Map<string, any>;
    global: Map<string, any>;
    search: Map<string, any>;
  }

  interface ICacheParams<T, P> {
    type: keyof ITypes;
    key: string;
    promise: (p: P) => Promise<T>;
    promiseParams: P;
    isUseLocalStorage?: boolean;
  }

  enum Keys {
    "siteInfo" = "siteInfo",
  }

  const types: ITypes = {
    news: new Map<string, any>(),
    reports: new Map<string, any>(),
    global: new Map<string, any>(),
    search: new Map<string, any>(),
  };

  function add<T>(key: Keys, v: T) {
    types.global.set(key, v);
  }

  function get<T>(key: Keys): T {
    return types.global.get(key) as T;
  }

  function clear(type: keyof ITypes): void {
    const cache = types[type];
    clearIfHasExpirationDate();
    cache.clear();
  }

  function clearIfHasExpirationDate(): void {
    const keys: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
      const currentKey = localStorage.key(i);

      if (currentKey.startsWith(localStorageGetAllPrefix)) {
        keys.push(currentKey);
      }
    }

    keys.forEach((key) => storage.delete(key));
  }

  (function clearExpiredInLocalStorage(): void {
    const keys: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
      const currentKey = localStorage.key(i);
      const currentValue = parseJson(localStorage.getItem(currentKey));

      if (!!currentValue && !!currentValue["expiration"] && new Date(currentValue["expiration"]) < new Date()) {
        keys.push(currentKey);
      }
    }

    keys.forEach((key) => localStorage.removeItem(key));
  })();

  async function staleWhileRevalidate<T, P>(params: ICacheParams<T, P>): Promise<T> {
    const { type, key, promise, promiseParams, isUseLocalStorage } = params;

    try {
      let result: T = undefined;

      if (isUseLocalStorage) {
        result = storage.get(key);
      }

      if (!result) {
        const cache = types[type];

        if (cache.has(key)) {
          promise(promiseParams).then((u) => {
            cache.set(key, u);
          });
          result = cache.get(key);
        } else {
          result = await promise(promiseParams);
          cache.set(key, result);
        }

        if (isUseLocalStorage) {
          storage.put(key, result);
        }
      }

      return result;
    } catch {
      clearIfHasExpirationDate();
      return await promise(promiseParams);
    }
  }

  async function cacheFirstFallToNetwork<T, P>(params: ICacheParams<T, P>): Promise<T> {
    const { type, key, promise, promiseParams } = params;
    try {
      const cache = types[type];

      if (cache.has(key)) {
        return cache.get(key);
      }

      const result = await promise(promiseParams);
      cache.set(key, result);

      return result;
    } catch (error) {
      throw new Error(`Error while using cacheFirstThenNetwork: ${type}, ${error}`);
    }
  }

  return {
    Keys,
    clear,
    add,
    get,
    staleWhileRevalidate,
    cacheFirstFallToNetwork,
  };
})();
