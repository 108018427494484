import { combineReducers } from "redux";

import dashboardReducer, { IDashboardState } from "Pages/dashboard/dashboard.reducer";
import reportReducer, { IReportState } from "Pages/report/report.reducer";
import reportsReducer, { IReportsState } from "Pages/reports-browser/reports-browser.reducer";
import reportsFilterReducer, { IReportsFilterState } from "Pages/reports-browser/reports-filters.reducer";
import foldersReducer, { IFoldersState } from "Store/reducers/folders.reducer";
import permissionsReducer, { IPermissionsState } from "Store/reducers/permissions.reducer";
import tagsReducer, { ITagsState } from "Store/reducers/tags.reducer";

import favoritesReducer, { IFavoriteState } from "./favorites.reducer";
import globalReducer, { IGlobalState } from "./global.reducer";
import newsReducer, { INewsState } from "./news.reducer";
import notificationsReducer, { INotificationsState } from "./notifications.reducer";
import usersReducer, { IUsersState } from "./users.reducer";

export interface IRootReducerState {
  global: IGlobalState;
  reports: IReportsState;
  folders: IFoldersState;
  filters: IReportsFilterState;
  users: IUsersState;
  notifications: INotificationsState;
  report: IReportState;
  tags: ITagsState;
  news: INewsState;
  favorites: IFavoriteState;
  dashboard: IDashboardState;
  permissions: IPermissionsState;
}

export const rootReducer = combineReducers<IRootReducerState>({
  global: globalReducer,
  reports: reportsReducer,
  folders: foldersReducer,
  filters: reportsFilterReducer,
  users: usersReducer,
  notifications: notificationsReducer,
  report: reportReducer,
  news: newsReducer,
  tags: tagsReducer,
  favorites: favoritesReducer,
  dashboard: dashboardReducer,
  permissions: permissionsReducer,
});
