import React, { FC } from "react";

import { ReactComponent as copyIcon } from "@epam/assets/icons/common/action-copy_content-18.svg";
import { IconButton, Tooltip } from "@epam/loveship";

import type { ICopyIcon } from "./select.types";

export const CopyIcon: FC<ICopyIcon> = ({ cx, tooltipContent, tooltipRef, onCopy }) => (
  <Tooltip ref={tooltipRef} placement="bottom" content={tooltipContent}>
    <IconButton cx={cx} icon={copyIcon} onClick={onCopy} />
  </Tooltip>
);
