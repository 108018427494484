import React from "react";

import withNaming from "Helpers/bemClassname";

import "./badge.scss";

export enum BadgeColors {
  "white" = "white",
  "amber" = "amber",
  "grass" = "grass",
  "blue" = "blue",
}

interface IBadgeProps {
  color?: BadgeColors;
  type?: string;
  className?: string;
  icon?: any;
  isGroupName?: boolean;
  onIconClick?: () => void;
}

export const badgeCN = withNaming("badge");

const Badge: React.FC<IBadgeProps> = ({ color, type, children, icon, className = "", isGroupName, onIconClick }) => {
  const Icon = icon;

  return (
    <div className={badgeCN({ [color]: !!color, [type]: !!type, isGroupName }, [className])}>
      {children}
      {Icon && (
        <button className={badgeCN("button")} onClick={onIconClick}>
          <Icon />
        </button>
      )}
    </div>
  );
};

export default Badge;
