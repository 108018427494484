import { sp } from "@pnp/sp";

import { IFolderDTO } from "SP/folders/folders.types";

export default class FoldersRepository {
  async getRootFolder(): Promise<IFolderDTO> {
    return await sp.web.getFolderByServerRelativeUrl("Documents").select("Name", "UniqueId").get();
  }

  async getSubFolders(parentFolderId: string): Promise<IFolderDTO[]> {
    const folders = await sp.web
      .getFolderById(parentFolderId)
      .select("Folders/Name", "Folders/UniqueId")
      .expand("Folders")
      .get();
    return folders.Folders;
  }
}
