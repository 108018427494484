import { ForkEffect, spawn } from "redux-saga/effects";

import dashboardSagas from "Pages/dashboard/dashboard.sagas";
import singleReportSagas from "Pages/report/report.sagas";
import reportsSagas from "Pages/reports-browser/reports-browser.sagas";
import reportsFiltersSagas from "Pages/reports-browser/reports-filters.sagas";

import favoritesSagas from "./favorites.sagas";
import foldersSagas from "./folders.sagas";
import newsSagas from "./news.sagas";
import permissionsSagas from "./permissions.sagas";
import tagsSagas from "./tags.sagas";
import usersSagas from "./users.sagas";

const rootSaga = function* (): Iterator<ForkEffect> {
  yield spawn(reportsSagas);
  yield spawn(singleReportSagas);
  yield spawn(tagsSagas);
  yield spawn(newsSagas);
  yield spawn(usersSagas);
  yield spawn(foldersSagas);
  yield spawn(reportsFiltersSagas);
  yield spawn(favoritesSagas);
  yield spawn(dashboardSagas);
  yield spawn(permissionsSagas);
};

export default rootSaga;
