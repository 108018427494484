import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { INews, INewsSavePayload } from "SP/news/news.types";
import { addNews, deleteNews, editNews, getNewsList } from "Store/actions/news.actions";
import { IRootReducerState } from "Store/reducers";

export const useNewsData = () => {
  const newsList = useSelector<IRootReducerState, INews[]>((state) => state.news.newsList);
  const loading = useSelector<IRootReducerState, boolean>((state) => state.news.loading);
  const singleNewsLoading = useSelector<IRootReducerState, boolean>((state) => state.news.singleNewsLoading);
  const dispatch = useDispatch();

  const handleGetNewsList = useCallback((limit) => {
    dispatch(getNewsList({ limit }));
  }, []);

  const handleAddNews = useCallback((payload: INewsSavePayload, callback?: () => void) => {
    dispatch(addNews(payload, callback));
  }, []);

  const handleEditNews = useCallback((id: number, payload: INewsSavePayload, callback?: () => void) => {
    dispatch(editNews(id, payload, callback));
  }, []);

  const handleDeleteNews = useCallback((id: number, callback?: () => void) => {
    dispatch(deleteNews(id, callback));
  }, []);

  return {
    loading,
    singleNewsLoading,
    newsList,
    deleteNews: handleDeleteNews,
    addNews: handleAddNews,
    editNews: handleEditNews,
    getNewsList: handleGetNewsList,
  };
};

export const useNewsPagination = (limit = 5) => {
  const currentPage = useSelector<IRootReducerState, number>((state) => state.news.pagination.currentPage);
  const isLastPage = useSelector<IRootReducerState, boolean>((state) => state.news.pagination.isLastPage);

  const dispatch = useDispatch();
  const loadPrevPage = useCallback(() => {
    dispatch(getNewsList({ limit, page: currentPage - 1 }));
  }, [currentPage]);
  const loadNextPage = useCallback(() => {
    dispatch(getNewsList({ limit, page: currentPage + 1 }));
  }, [currentPage]);

  return { isFirstPage: currentPage === 1, isLastPage, loadPrevPage, loadNextPage };
};
