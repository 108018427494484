import { useDispatch, useSelector } from "react-redux";

import { getReportVideoGuide } from "Pages/report/report.actions";
import type { IReportVideoGuide } from "SP/reports/reports.types";
import type { IRootReducerState } from "Store/reducers";

export function useReportVideoGuide() {
  const dispatch = useDispatch();

  const loading = useSelector<IRootReducerState, boolean>((state) => state.report.reportVideoGuideLoading);
  const reportVideoGuide = useSelector<IRootReducerState, IReportVideoGuide>((state) => state.report.reportVideoGuide);

  const handleGetReportVideoGuide = (id) => dispatch(getReportVideoGuide(id));

  return {
    loading,
    reportVideoGuide,
    getReportVideoGuide: handleGetReportVideoGuide,
  };
}
