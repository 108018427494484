import React from "react";

import { LabeledInput, Text } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";

const cn = withNaming("report-card");

interface ILabeledFieldProps {
  label: string;
  isBadges?: boolean;
  children?: React.ReactNode;
  cx?: string;
}

const LabeledField: React.FC<ILabeledFieldProps> = ({ label, isBadges, children, cx }) => (
  <LabeledInput label={`${label}: `} cx={cn("labeled-field", [cx])} labelPosition="left">
    {isBadges ? children : <Text cx={cn("labeled-field-text")}>{children}</Text>}
  </LabeledInput>
);

export default LabeledField;
