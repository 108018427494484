import React, { useEffect } from "react";

import { svc } from "@App/services";
import { ReactComponent as addIcon } from "@epam/assets/icons/common/action-add-24.svg";
import { FlexCell, FlexRow, IconButton, Panel } from "@epam/loveship";

import { Layout } from "Components/layout";
import withNaming from "Helpers/bemClassname";
import { useReportsSort, useTagsData, useUsersData } from "Hooks/index";
import { useAppSelector } from "Hooks/useAppSelector";
import { usePrimaryRoleModal } from "Hooks/usePrimaryRoleModal";
import { INewsSavePayload } from "SP/news/news.types";

import DashboardDomainSearch from "./dashboard-domain-search";
import DashboardFavoritesBlock from "./dashboard-favorites-block";
import DashboardNewsFeed from "./dashboard-news-feed";
import DashboardNewsModal, { ModalModes } from "./dashboard-news-modal";
import { DashboardSection } from "./dashboard-section/dashboard-section";
import { useDashboardReportsData, useDashboardReportsMethods } from "./dashboard.hooks";

import "./dashboard.scss";

const Dashboard: React.FC = () => {
  const cn = withNaming("dashboard");
  const { loading: tagsLoading } = useTagsData();
  const { currentUser } = useUsersData();
  const { roleRelatedReports } = useDashboardReportsData();
  const { sortedReports: sortedRoleRelatedReports } = useReportsSort(roleRelatedReports);
  const { getDashboardReports } = useDashboardReportsMethods();
  const filterRole = useAppSelector((state) => state.dashboard.filterRole);

  useEffect(() => {
    getDashboardReports();
  }, []);

  usePrimaryRoleModal();

  const openNewsModal = ({
    defaultMode,
    newsId,
    details,
  }: {
    defaultMode?: ModalModes;
    newsId?: number;
    details?: INewsSavePayload;
  }) => {
    svc.uuiModals.show((modalProps) => (
      <DashboardNewsModal
        defaultMode={defaultMode}
        newsId={newsId}
        modalProps={modalProps}
        currentUserPermissions={currentUser.permissions}
        details={details}
      />
    ));
  };

  return (
    <Layout withWall>
      <FlexRow cx={cn("search-row")}>
        <FlexCell cx={cn("cell")} grow={1}>
          <DashboardDomainSearch />
        </FlexCell>
      </FlexRow>
      <FlexRow alignItems="top" cx={cn()}>
        <FlexCell cx={cn("cell")} grow={1}>
          <main className={cn("main")}>
            <DashboardFavoritesBlock />
            <DashboardSection
              loading={tagsLoading}
              reports={sortedRoleRelatedReports}
              title={filterRole}
              allReportsFilter={`Role=${filterRole}`}
            />
          </main>
        </FlexCell>
        <FlexCell cx={cn("cell")} width={339}>
          <aside className={cn("sidebar")}>
            <Panel shadow background="white" cx={cn("sidebar-panel")}>
              <div className="flex justify-between items-center">
                <div className={cn("sidebar-title")}>The Latest News</div>
                {currentUser?.permissions.canAddNews && (
                  <IconButton
                    cx={cn("sidebar-add-icon")}
                    color="sky"
                    icon={addIcon}
                    onClick={() => openNewsModal({ defaultMode: ModalModes.FORM_MODE })}
                  />
                )}
              </div>
              <DashboardNewsFeed onOpenNewsModal={openNewsModal} />
            </Panel>
          </aside>
        </FlexCell>
      </FlexRow>
    </Layout>
  );
};

export default Dashboard;
