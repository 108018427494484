import React, { FC } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, useHistory } from "react-router-dom";

import { withAuth } from "@App/hoc/withAuth";
import { svc } from "@App/services";
import { ErrorHandler } from "@epam/loveship";
import { ContextProvider } from "@epam/uui";
import { Modals } from "@epam/uui-components";

import Notifications from "Components/notifications/notifications";
import store from "Store/store";

import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

import "@epam/uui-components/styles.css";
import "@epam/loveship/styles.css";
import "./scss/index.scss";

const UuiEnhancedApp: FC = () => {
  const history = useHistory();

  return (
    <ContextProvider
      apiDefinition={() => null}
      loadAppContext={() => Promise.resolve({})}
      onInitCompleted={(context) => {
        Object.assign(svc, context);
      }}
      history={history}
    >
      <ErrorHandler>
        <App />
        <Notifications />
        <Modals />
      </ErrorHandler>
    </ContextProvider>
  );
};

const RoutedApp = withAuth(() => {
  return (
    <BrowserRouter basename="/">
      <Provider store={store}>
        <UuiEnhancedApp />
      </Provider>
    </BrowserRouter>
  );
});

ReactDOM.render(
  <React.StrictMode>
    <RoutedApp />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
