import React, { FC } from "react";

import { ReactComponent as downloadIcon } from "@App/assets/download.svg";
import { ReactComponent as linkIcon } from "@App/assets/link.svg";
import { IconButton, Tooltip } from "@epam/loveship";
import { cx } from "@epam/uui";
import { DropdownPlacement } from "@epam/uui-components";

import { downloadFile } from "Helpers/utils";
import { useFile } from "Hooks/useFile";

interface IFileButtonProps {
  reportId: number;
  reportLink: string;
  className?: string;
  tooltipPlacement?: DropdownPlacement;
}

export const FileButton: FC<IFileButtonProps> = ({ reportId, reportLink, className, tooltipPlacement = "bottom" }) => {
  const { wrapperClassName, file, canDownload, getReportFileAndPermissions } = useFile(reportId, reportLink);

  const handleDownload = () => {
    downloadFile(file, canDownload);
  };

  return (
    <Tooltip
      placement={tooltipPlacement}
      content={reportLink ? "Open report link" : canDownload ? "Download report" : ""}
    >
      <span
        data-testid={reportLink ? "link-icon" : "download-icon"}
        className={cx(
          className,
          {
            "svg-hover": reportLink || canDownload,
          },
          [wrapperClassName, "shrink-0"],
        )}
        onMouseEnter={getReportFileAndPermissions}
      >
        <IconButton
          cx={wrapperClassName}
          icon={reportLink ? linkIcon : downloadIcon}
          {...(reportLink ? { href: reportLink, target: "_blank" } : { onClick: handleDownload })}
        />
      </span>
    </Tooltip>
  );
};
