import { useDispatch, useSelector } from "react-redux";

import { IReport } from "SP/reports/reports.types";
import { IRootReducerState } from "Store/reducers";

import {
  getDashboardReports,
  getFilterRole,
  reorderFavoriteReports,
  setFilterRole,
  setPrimaryModalValue,
} from "./dashboard.actions";

export const useDashboardFavorites = () => {
  const dispatch = useDispatch();
  const loading = useSelector<IRootReducerState, boolean>((state) => state.dashboard.loading);
  const isLoaded = useSelector<IRootReducerState, boolean>((state) => state.dashboard.isLoaded);
  const favoriteReports = useSelector<IRootReducerState, IReport[]>((state) => state.dashboard.favoriteReports);

  const handleReorderFavoriteReports = (newFavoriteReports: IReport[]) => {
    dispatch(reorderFavoriteReports(newFavoriteReports));
  };

  return {
    loading,
    isLoaded,
    favoriteReports,
    reorderFavoriteReports: handleReorderFavoriteReports,
  };
};

export const useDashboardReportsData = () => {
  const loading = useSelector<IRootReducerState, boolean>((state) => state.dashboard.loading);
  const roleRelatedReports = useSelector<IRootReducerState, IReport[]>((state) => state.dashboard.roleRelatedReports);
  const filterRole = useSelector<IRootReducerState, string>((state) => state.dashboard.filterRole);
  const primaryRoleModalValue = useSelector<IRootReducerState, string>(
    (state) => state.dashboard.primaryRoleModalValue,
  );

  return {
    loading,
    roleRelatedReports,
    filterRole,
    primaryRoleModalValue,
  };
};

export const useDashboardReportsMethods = () => {
  const dispatch = useDispatch();
  const handleGetDashboardReports = () => dispatch(getDashboardReports());
  const handleSetFilterRole = (filterRole: string) => dispatch(setFilterRole(filterRole));
  const handleGetFilterRole = () => dispatch(getFilterRole());
  const handleSetPrimaryModalValue = (value: string) => dispatch(setPrimaryModalValue(value));

  return {
    getDashboardReports: handleGetDashboardReports,
    setFilterRole: handleSetFilterRole,
    getFilterRole: handleGetFilterRole,
    setPrimaryModalValue: handleSetPrimaryModalValue,
  };
};
