import React, { FC } from "react";

import Badge, { badgeCN } from "Components/badge/badge";
import highlight from "Helpers/highlight";

import { BadgesTooltip } from "./badges-tooltip";
import type { IBadgesGroupProps } from "./badges.types";

export const BadgesGroup: FC<IBadgesGroupProps> = ({ badges, badgesCount, groupName, color, isHighlighted }) => {
  const groupRenderInfo = (
    <>
      <span className={badgeCN("label")}>{`${badgesCount} ${groupName}`}</span>
    </>
  );

  return (
    <BadgesTooltip badges={badges}>
      <Badge isGroupName className="cursor-pointer" color={color}>
        {isHighlighted ? highlight.wrap(groupRenderInfo) : groupRenderInfo}
      </Badge>
    </BadgesTooltip>
  );
};
