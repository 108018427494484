import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

import { ReactComponent as loadIcon } from "@epam/assets/icons/loaders/circle_loader_30 .svg";
import { IconContainer, PickerInput, Text } from "@epam/loveship";
import { ArrayDataSource } from "@epam/uui";

import withNaming from "Helpers/bemClassname";
import { isCanSelectPickerInput } from "Helpers/utils";

import type { IPickerInput, ISelectOption, ISelectProps } from "./select.types";

import "./select.scss";

export function Select<IType>({
  loading,
  options,
  placeholder,
  value,
  error,
  inputProps,
  renderCopyIcon,
  onValueChange,
  onSearchChange,
  renderNotFound,
  renderFooter,
  renderRow,
  renderTogglerItem,
  strictOnSearch,
}: ISelectProps<IType>): ReactElement {
  const cn = withNaming("select");
  const pickerInputRef = useRef<IPickerInput<IType>>(null);
  const itemsDataSource = new ArrayDataSource({ items: options });
  const [search, setSearch] = useState("");

  const debouncedOnSearchChange = useDebouncedCallback((event) => {
    const newSearch = event.target.value;
    if (strictOnSearch) {
      onSearchChange?.(newSearch);
    }

    if (search !== newSearch) {
      if (!strictOnSearch) {
        onSearchChange?.(newSearch);
      }
      setSearch(newSearch);
    }
  }, 550);

  const handleGetSearchFields = useCallback((item: ISelectOption) => {
    const fields = [item.label];

    if (item.searchField) {
      return fields.concat(item.searchField);
    }

    return fields;
  }, []);

  const handleValueChange = useCallback(
    (newValue) => {
      if (isCanSelectPickerInput(value, newValue)) {
        onValueChange(newValue || []);
      }
    },
    [value, onValueChange],
  );

  useEffect(() => {
    if (pickerInputRef.current && onSearchChange) {
      const oldHandlePickerInputKeyboard = pickerInputRef.current.handlePickerInputKeyboard;
      const handleSearchChange = (rows, event) => {
        event.persist();
        debouncedOnSearchChange.callback(event);
        oldHandlePickerInputKeyboard(rows, event);
      };

      pickerInputRef.current.handlePickerInputKeyboard = handleSearchChange;
    }
  }, []);

  useEffect(() => {
    if (renderTogglerItem) {
      pickerInputRef.current.togglerRef.current.renderItem = renderTogglerItem;
    }
  }, [renderTogglerItem]);

  return (
    <div data-testid="select" className={cn("wrapper")}>
      {loading && (
        <IconContainer cx={cn("loader", { [`right-${inputProps?.size}`]: !!inputProps?.size })} icon={loadIcon} />
      )}
      {value?.length > 1 && renderCopyIcon?.({ cx: cn("clone-icon") })}
      <PickerInput<ISelectOption, IType>
        ref={pickerInputRef}
        valueType="id"
        selectionMode="multi"
        searchPosition="body"
        placeholder={placeholder || "Please select"}
        dataSource={itemsDataSource}
        value={value}
        onValueChange={handleValueChange}
        getSearchFields={handleGetSearchFields}
        getName={(item) => item.label}
        renderNotFound={renderNotFound}
        renderFooter={renderFooter}
        isInvalid={!!error}
        renderRow={renderRow}
        {...inputProps}
      />
      {!!error && (
        <Text color="fire" size="24">
          {error}
        </Text>
      )}
    </div>
  );
}
