import { fork, ForkEffect, put, takeLatest } from "redux-saga/effects";

import { getFilteredReports } from "Pages/reports-browser/reports-browser.actions";
import { ReportsFiltersActionTypes } from "Pages/reports-browser/reports-filters.actions";

function* applyFilters() {
  yield put(getFilteredReports());
}

function* watchApplyFilters() {
  yield takeLatest(
    [
      ReportsFiltersActionTypes.SET_FILTER_TAGS,
      ReportsFiltersActionTypes.SET_FILTER_OWNERS,
      ReportsFiltersActionTypes.SET_FILTER_BY,
      ReportsFiltersActionTypes.SET_FILTER_TIME_PERIOD,
      ReportsFiltersActionTypes.SET_SEARCH_TEXT,
      ReportsFiltersActionTypes.SET_ALL_FILTERS,
    ],
    applyFilters,
  );
}

export default function* reportsFiltersSagas(): Iterator<ForkEffect> {
  yield fork(watchApplyFilters);
}
