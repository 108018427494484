import { IReportPermissions } from "SP/reports/reports.types";

export enum PermissionsActionTypes {
  GET_PERMISSION_ON_REPORT_REQUEST = "[PERMISSIONS] GET PERMISSIONS ON REPORT REQUEST",
  GET_PERMISSION_ON_REPORT_SUCCESS = "[PERMISSIONS] GET PERMISSIONS ON REPORT SUCCESS",
  GET_PERMISSION_ON_REPORT_FAILURE = "[PERMISSIONS] GET PERMISSIONS ON REPORT FAILURE",
}

export interface IGetPermissionsOnReportRequestAction {
  type: PermissionsActionTypes.GET_PERMISSION_ON_REPORT_REQUEST;
  reportId: number;
}

export function getPermissionsOnReport(reportId: number): IGetPermissionsOnReportRequestAction {
  return {
    type: PermissionsActionTypes.GET_PERMISSION_ON_REPORT_REQUEST,
    reportId,
  };
}

export interface IGetPermissionOnReportSuccessAction {
  type: PermissionsActionTypes.GET_PERMISSION_ON_REPORT_SUCCESS;
  permissions: IReportPermissions;
  reportId: number;
}

export function getPermissionsOnReportSuccess(
  reportId: number,
  permissions: IReportPermissions,
): IGetPermissionOnReportSuccessAction {
  return {
    type: PermissionsActionTypes.GET_PERMISSION_ON_REPORT_SUCCESS,
    permissions,
    reportId,
  };
}

export interface IGetPermissionOnReportFailureAction {
  type: PermissionsActionTypes.GET_PERMISSION_ON_REPORT_FAILURE;
  reportId: number;
  error: string;
}

export function getPermissionsOnReportFailure(reportId: number, error: string): IGetPermissionOnReportFailureAction {
  return {
    type: PermissionsActionTypes.GET_PERMISSION_ON_REPORT_FAILURE,
    reportId,
    error,
  };
}

export type IPermissionsActions =
  | IGetPermissionsOnReportRequestAction
  | IGetPermissionOnReportSuccessAction
  | IGetPermissionOnReportFailureAction;
