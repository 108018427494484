import { IUser, IUserExtended } from "SP/users/users.types";

export const allUsers: IUser[] = [
  {
    id: 550,
    email: "Auto_EPM-AMSP_Test1@epam.com",
    name: "Auto EPM-AMSP Test1",
  },
  {
    id: 572,
    email: "Auto_EPM-AMSP_Test3@epam.com",
    name: "Auto EPM-AMSP Test3",
  },
  {
    id: 79,
    email: "Auto_EPM-RIM_QA2@epam.com",
    name: "Auto EPM-RIM QA2",
  },
  {
    id: 35,
    email: "Auto_EPM-RIM_QA3@epam.com",
    name: "Auto EPM-RIM QA3",
  },
  {
    id: 153,
    email: "Auto_EPM-RIM_QA4@epam.com",
    name: "Auto EPM-RIM QA4",
  },
];

export const currentUser: IUserExtended = {
  id: 550,
  email: "Auto_EPM-AMSP_Test1@epam.com",
  name: "Auto EPM-AMSP Test1",
  photo: "",
  permissions: {
    canAddNews: true,
    canEditNews: true,
    canDeleteNews: true,
    canAddReport: true,
    canCertifyReport: true,
  },
  configs: {
    id: 0,
    favoritesOrder: [],
    filterRole: {},
  },
};
