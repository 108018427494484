import produce from "immer";
import { Reducer } from "redux";

import { searchTree } from "Helpers/foldersHelper";
import { IFolder } from "SP/folders/folders.types";
import { FoldersActionsTypes, IFoldersActionsTypes } from "Store/actions/folders.actions";

export interface IFoldersState {
  rootFolders: IFolder[];
  loading: boolean;
}

const initialState: IFoldersState = {
  rootFolders: [],
  loading: false,
};

const updateRootFolders = (draft: IFoldersState, { parentFolderId, subFolders }) => {
  const folder = searchTree(draft.rootFolders[0], parentFolderId);

  if (folder) {
    folder.children = subFolders;
  }
};

const foldersReducer: Reducer<IFoldersState, IFoldersActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case FoldersActionsTypes.GET_ROOT_FOLDERS_REQUEST:
    case FoldersActionsTypes.GET_SUB_FOLDERS_REQUEST:
      draft.loading = true;
      break;
    case FoldersActionsTypes.GET_ROOT_FOLDERS_SUCCESS:
      draft.rootFolders = action.payload;
      draft.loading = false;
      break;

    case FoldersActionsTypes.GET_SUB_FOLDERS_SUCCESS:
      updateRootFolders(draft, action.payload);
      draft.loading = false;
      break;
    case FoldersActionsTypes.CLEAR_FOLDERS:
      draft.rootFolders = [];
      draft.loading = false;
      break;
  }
}, initialState);

export default foldersReducer;
