interface IBatchPromisesArgs<IItem, IFnResult> {
  items: IItem[];
  fn: (item: IItem, ...fnArgs) => Promise<IFnResult>;
  thenCb?: (item: IItem) => (result: IFnResult) => void;
  retry?: number;
}

const defaultThenCb = (item) => (result) => result;

export function batchPromises<IItem, IFnResult, IEndResult>(
  { items, fn, thenCb = defaultThenCb, retry = 3 }: IBatchPromisesArgs<IItem, IFnResult>,
  ...fnArgs
): Promise<IEndResult[]> {
  function getCurrentItem(item) {
    return fn(item, ...fnArgs)
      .then(thenCb(item))
      .catch((e) => {
        console.info("Error in batchPromises: ", e);

        if (retry > 0) {
          retry--;
          return getCurrentItem(item);
        }
      });
  }

  return Promise.all<IEndResult>(items.map(getCurrentItem));
}
