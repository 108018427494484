import produce from "immer";
import _remove from "lodash/remove";
import { Reducer } from "redux";

import { dashboardDefaultRole } from "Helpers/utils";
import { DashboardActionTypes, IDashboardActionTypes } from "Pages/dashboard/dashboard.actions";
import { ReportActionTypes } from "Pages/report/report.actions";
import type { IReport } from "SP/reports/reports.types";
import { FavoritesActionTypes } from "Store/actions/favorites.actions";

export interface IDashboardState {
  favoriteReports: IReport[];
  roleRelatedReports: IReport[];
  filterRole: string;
  primaryRoleModalValue: string;
  loading: boolean;
  isLoaded: boolean;
}

const initialState: IDashboardState = {
  favoriteReports: [],
  roleRelatedReports: [],
  filterRole: "",
  primaryRoleModalValue: "",
  loading: false,
  isLoaded: false,
};

const handleFavoriteReport = (draft: IDashboardState, reportId: number) => {
  const favoriteReport = draft.roleRelatedReports.find((report) => report.id === reportId);

  if (favoriteReport) {
    draft.favoriteReports.unshift(favoriteReport);
  }
};

const findReportById = (reportId: number) => (report: IReport) => report.id === reportId;

const dashboardReducer: Reducer<IDashboardState, IDashboardActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case DashboardActionTypes.GET_DASHBOARD_REPORTS_REQUEST:
      draft.loading = true;
      draft.isLoaded = false;
      break;
    case DashboardActionTypes.GET_DASHBOARD_REPORTS_SUCCESS:
      draft.loading = false;
      draft.isLoaded = true;
      draft.favoriteReports = action.favoriteReports;
      draft.roleRelatedReports = action.roleRelatedReports;
      break;
    case DashboardActionTypes.GET_DASHBOARD_REPORTS_FAILURE:
      draft.loading = false;
      draft.isLoaded = true;
      break;
    case DashboardActionTypes.SET_FILTER_ROLE:
      draft.filterRole = action.filterRole;
      break;
    case DashboardActionTypes.SET_PRIMARY_MODAL_VALUE:
      draft.primaryRoleModalValue = action.primaryRoleModalValue;
      break;

    case DashboardActionTypes.REORDER_FAVORITE_REPORTS:
      draft.favoriteReports = action.favoriteReports;
      break;

    case FavoritesActionTypes.FAVORITE_REPORT_SUCCESS:
      handleFavoriteReport(draft, action.reportId);
      break;

    case FavoritesActionTypes.UNFAVORITE_REPORT_SUCCESS:
      _remove(draft.favoriteReports, findReportById(action.reportId));
      break;

    case ReportActionTypes.DELETE_REPORT_SUCCESS:
      _remove(draft.favoriteReports, findReportById(action.reportId));
      _remove(draft.roleRelatedReports, findReportById(action.reportId));
      break;
  }
}, initialState);

export default dashboardReducer;
