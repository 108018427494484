import { IGroupedTags, ITagWithGroup } from "SP/tags/tags.types";

export enum TagsActionTypes {
  GET_TAGS_REQUEST = "[TAGS] GET REQUEST",
  GET_TAGS_SUCCESS = "[TAGS] GET SUCCESS",
  GET_TAGS_FAILURE = "[TAGS] GET FAILURE",
}

export interface IGetTagsRequestAction {
  type: TagsActionTypes.GET_TAGS_REQUEST;
}

export function getTags(): IGetTagsRequestAction {
  return {
    type: TagsActionTypes.GET_TAGS_REQUEST,
  };
}

interface IGetTagsSuccessAction {
  type: TagsActionTypes.GET_TAGS_SUCCESS;
  groupedTags: IGroupedTags;
  allTags: ITagWithGroup[];
}

export function getTagsSuccess(groupedTags: IGroupedTags, allTags: ITagWithGroup[]): IGetTagsSuccessAction {
  return {
    type: TagsActionTypes.GET_TAGS_SUCCESS,
    groupedTags,
    allTags,
  };
}

interface IGetTagsFailureAction {
  type: TagsActionTypes.GET_TAGS_FAILURE;
  error: string;
}

export function getTagsFailure(error: string): IGetTagsFailureAction {
  return {
    type: TagsActionTypes.GET_TAGS_FAILURE,
    error,
  };
}

export type ITagsActionTypes = IGetTagsRequestAction | IGetTagsSuccessAction | IGetTagsFailureAction;
