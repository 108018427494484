import { ItemUpdateResult } from "@pnp/sp";

import NewsRepository from "./news.repository";
import { IGetAllArgs, INews, INewsDTO, INewsSavePayload } from "./news.types";

class NewsService {
  repository = new NewsRepository();

  lastNewsId = null;

  private mapNews = (news: INewsDTO): INews => {
    return {
      id: news.Id,
      newsTitle: news.Title,
      description: news.Content,
      reportLink: news.ReportLink,
      date: news.Created,
    };
  };

  private reverseMapNews = (news: Partial<INews>, isEdit?: boolean): Partial<INewsDTO> => {
    const dto = {
      Title: news.newsTitle,
      Content: news.description,
      ReportLink: news.reportLink,
    };

    if (isEdit) {
      Object.assign(dto, { Id: news.id });
    }

    return dto;
  };

  async getAll({ limit, lastNewsInPageId }: IGetAllArgs): Promise<{ news: INews[]; isLastPage: boolean }> {
    let lastNews;
    if (lastNewsInPageId) {
      lastNews = await this.repository.getById(lastNewsInPageId);
    }

    const allNews = await this.repository.getAll({ limit, lastNewsInfo: lastNews });
    const isLastPage = await this.getIfLastPage(limit, allNews);

    return { news: allNews.map((news) => this.mapNews(news)), isLastPage };
  }

  async getIfLastPage(limit: number, news: INewsDTO[]): Promise<boolean> {
    if (!news.length || news.length < limit) {
      return true;
    }

    if (!this.lastNewsId) {
      const reversedNews = await this.repository.getAll({ limit: 1, sortOrder: "ASC" });
      this.lastNewsId = reversedNews[0].Id;
    }

    if (news[news.length - 1].Id === this.lastNewsId) {
      return true;
    }

    return false;
  }

  async getById(id: number): Promise<INews> {
    const news = await this.repository.getById(id);
    return this.mapNews(news);
  }

  async add(payload: INewsSavePayload): Promise<INews> {
    const payloadData = this.reverseMapNews(payload);
    const { data } = await this.repository.add(payloadData);
    return this.mapNews(data);
  }

  async edit(id: number, payload: INewsSavePayload): Promise<ItemUpdateResult> {
    const newsData = this.reverseMapNews({ id, ...payload }, true);
    return await this.repository.edit(id, newsData);
  }

  async deleteById(id: number): Promise<string> {
    return this.repository.deleteById(id);
  }
}

export default NewsService;
