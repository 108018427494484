import React from "react";
import { useSelector } from "react-redux";

import withNaming from "Helpers/bemClassname";
import type { IRootReducerState } from "Store/reducers";

import "./owners.scss";

interface IOwnersProps {
  ownersNames: string[];
  reportName: string;
  spaceSeparated?: boolean;
}

const cn = withNaming("owners");

export const Owners = ({ ownersNames, reportName, spaceSeparated }: IOwnersProps): JSX.Element => {
  const allUserEmails = useSelector(
    (state: IRootReducerState) => new Map<string, string>(state.users.allUsers.map((user) => [user.name, user.email])),
  );

  return (
    <span className={cn("container")}>
      {ownersNames?.map((ownerName) => {
        const className = cn("link", [spaceSeparated ? "space-separated" : "comma-separated"]);

        return (
          <a
            className={className}
            href={`mailto:${allUserEmails.get(ownerName) || ownerName}?subject=${encodeURIComponent(reportName)}`}
            key={ownerName}
          >
            {ownerName}
          </a>
        );
      })}
    </span>
  );
};
