import type { Item } from "@pnp/sp";

import { ISelectedPath } from "Components/path-select-modal/path-select-modal";
import type { IUser } from "SP/users/users.types";

export interface IFile {
  name: string;
  url: string;
  uniqueId: string;
}

export interface IReport {
  id: number;
  owner: number[];
  readOnlyUsers: number[];
  viewOnlyUsers: number[];
  modifierUsers: number[];
  contributorUsers: number[];
  tags: string[];
  orderNumber: number;
  folderPath: ISelectedPath | null;
  reportName: string;
  modificationDate: string;
  kbLink: string;
  videoGuideLink: string;
  reportLink: string;
  reportUploadFile: File | null;
  description: string;
  powerBI_ID: string;
  file?: IFile;
  certified: boolean;
  primaryRoles: string[];
}

export interface IReportVersion {
  description: string;
  userDefinedModificationDate: string;
  reportName: string;
  orderNumber: number;
  owner: string[];
  viewOnlyUsers: string[];
  readOnlyUsers: string[];
  modifierUsers: string[];
  contributorUsers: string[];
  id: number;
  kbLink: string;
  videoGuideLink: string;
  reportLink: string;
  tags: string[];
  powerBI_ID: string;
  fileSize: string;
  editor: string;
  modified: Date;
  versionLabel: string;
  certified: boolean;
  primaryRoles: string[];
}

export interface IStreamReport extends IReport {
  ownerUsers: IUser[];
  ownersNames: string[];
}

export interface IReportExtended extends IReport {
  ownersNames?: string[];
  searchRank?: number;
}

export interface IReportVideoGuide {
  id: number;
  reportName: string;
  videoGuideLink: string;
}

export interface IReportEditSavePayload extends Omit<IReportExtended, "id" | "tags"> {
  applications: string[];
  domains: string[];
  metrics: string[];
  roles: string[];
}

export type IUsersWithResult = { results: number[] };
export type IUsersSet = number[] | IUsersWithResult;
export type IRoleAssignmentUsers = { [key in IReportPermissionGroups]: IUsersSet };

interface IReportCommonDTO {
  DescriptionBI: string;
  UserDefinedModificationDate: string;
  Title: string;
  ReportName: string;
  KBLink: string;
  ReportLink: string;
  File?: IFile;
  PowerBI_ID: string;
  ReportVideoGuide: string;
}

export interface IReportDTO extends IReportCommonDTO {
  Id: number;
  OwnerId: IUsersSet;
  ContributorsId: IUsersSet;
  ViewOnlyUsersId: IUsersSet;
  ModifiersId: IUsersSet;
  ReadOnlyUsersId: IUsersSet;
  TagsBI: ITagInReportDTO[] | string[];
  OrderNo: number;
  Certified: boolean;
  PrimaryRoles: string[];
}

export type IStreamUser = {
  id: string;
  title: string;
  email: string;
};

export type IStreamTagsBI = {
  Label: string;
  TermID: string;
};

export interface IStreamReportDTO extends IReportCommonDTO {
  ID: string;
  Owner: IStreamUser[];
  Contributors: IStreamUser[];
  ViewOnlyUsers: IStreamUser[];
  Modifiers: IStreamUser[];
  ReadOnlyUsers: IStreamUser[];
  TagsBI: IStreamTagsBI[];
  OrderNo: string;
  FileRef: string;
  FileLeafRef: string;
  UniqueId: string;
  Certified: "Yes" | "No";
  PrimaryRoles: IStreamTagsBI[];
}

export type IStreamReportsDTO = { Row: IStreamReportDTO[] };

export interface IUserLookupField {
  LookupId: number;
  LookupValue: string;
  Email: string;
}

export interface IReportFileVersionDTO {
  Length: string;
  VersionLabel: string;
}

export interface IReportVersionDTO {
  DescriptionBI: string;
  UserDefinedModificationDate: string;
  ReportName: string;
  OrderNo: number;
  Owner: IUserLookupField[];
  ViewOnlyUsers: IUserLookupField[];
  ReadOnlyUsers: IUserLookupField[];
  Modifiers: IUserLookupField[];
  Contributors: IUserLookupField[];
  ID: number;
  Certified: boolean;
  KBLink: string;
  ReportVideoGuide: string;
  ReportLink: string;
  TagsBI: ITagInReportDTO[];
  PowerBI_x005f_ID: string;
  Editor: IUserLookupField;
  Modified: string;
  VersionLabel: string;
  PrimaryRoles: ITagInReportDTO[];
}

export interface ITagInReportDTO {
  Label: string;
  WssId: number;
  TermGuid: string;
}

export interface IReportPermissions {
  canDownload: boolean;
  canEdit: boolean;
  canDelete: boolean;
}

export enum IReportPermissionGroups {
  viewOnlyUsers = "ViewOnlyUsersId",
  readOnlyUsers = "ReadOnlyUsersId",
  modifierUsers = "ModifiersId",
  contributorUsers = "ContributorsId",
}

export enum IReportFields {
  "ReportName" = "ReportName",
  "DescriptionBI" = "DescriptionBI",
  "ID" = "ID",
  "TagsBI" = "TagsBI",
  "Owner" = "Owner",
  "UserDefinedModificationDate" = "UserDefinedModificationDate",
  "OrderNo" = "OrderNo",
  "IsTechnical" = "IsTechnical",
  "ReportVideoGuide" = "ReportVideoGuide",
  "OwnerId" = "OwnerId",
  "Certified" = "Certified",
}

export type IReportIdentifierType = "name" | "id";

export type IDateFieldCheckExpressions = "LessThanOrEqualTo" | "GreaterThanOrEqualTo";

export interface ISort {
  field: IReportFields;
  asc: boolean;
}

export interface IDestinationUrls {
  originalUrl: string;
  newUrl: string;
}

export interface IEditReportParams {
  id: number;
  file: File;
  payload: Partial<IReportDTO>;
  oldPayload: Partial<IReportDTO>;
  destinationUrls: IDestinationUrls;
  canEditPermissions: boolean;
}

export interface IEditReportPermissionsParams {
  report: Item;
  payload: Partial<IReportDTO>;
  oldPayload: Partial<IReportDTO>;
}

export interface IRoleAssignmentUsersWithDefId {
  users: IUsersSet;
  defId: number;
}

export interface IGetRoleAssignmentUsersParams {
  payload: Partial<IReportDTO>;
  oldPayload: Partial<IReportDTO>;
}
