import { INews, INewsSavePayload } from "SP/news/news.types";

export enum NewsActionTypes {
  GET_NEWS_LIST_REQUEST = "[NEWS] GET NEWS LIST REQUEST",
  GET_NEWS_LIST_SUCCESS = "[NEWS] GET NEWS LIST SUCCESS",
  GET_NEWS_LIST_FAILURE = "[NEWS] GET NEWS LIST FAILURE",

  ADD_NEWS_REQUEST = "[NEWS] ADD NEWS REQUEST",
  ADD_NEWS_SUCCESS = "[NEWS] ADD NEWS SUCCESS",
  ADD_NEWS_FAILURE = "[NEWS] ADD NEWS FAILURE",

  EDIT_NEWS_REQUEST = "[NEWS] EDIT NEWS REQUEST",
  EDIT_NEWS_SUCCESS = "[NEWS] EDIT NEWS SUCCESS",
  EDIT_NEWS_FAILURE = "[NEWS] EDIT NEWS FAILURE",

  DELETE_NEWS_REQUEST = "[NEWS] DELETE NEWS REQUEST",
  DELETE_NEWS_SUCCESS = "[NEWS] DELETE NEWS SUCCESS",
  DELETE_NEWS_FAILURE = "[NEWS] DELETE NEWS FAILURE",
}

// GET ALL NEWS ACTIONS
export interface IGetNewsListRequestAction {
  type: NewsActionTypes.GET_NEWS_LIST_REQUEST;
  limit: number;
  page: number;
}

export function getNewsList({ limit, page }: { limit?: number; page?: number }): IGetNewsListRequestAction {
  return {
    type: NewsActionTypes.GET_NEWS_LIST_REQUEST,
    limit: limit ?? 5,
    page: page ?? 1,
  };
}

export interface IGetNewsListSuccessAction {
  type: NewsActionTypes.GET_NEWS_LIST_SUCCESS;
  news: INews[];
  page: number;
  isLastPage: boolean;
}

export function getNewsListSuccess(news: INews[], page: number, isLastPage: boolean): IGetNewsListSuccessAction {
  return {
    type: NewsActionTypes.GET_NEWS_LIST_SUCCESS,
    news,
    page,
    isLastPage,
  };
}

export interface IGetNewsListFailureAction {
  type: NewsActionTypes.GET_NEWS_LIST_FAILURE;
  error: string;
}

export function getNewsListFailure(error: Error): IGetNewsListFailureAction {
  return {
    type: NewsActionTypes.GET_NEWS_LIST_FAILURE,
    error: error.message,
  };
}

// ADD NEWS ACTIONS
export interface IAddNewsRequestAction {
  type: NewsActionTypes.ADD_NEWS_REQUEST;
  payload: INewsSavePayload;
  callback?: () => void;
}

export function addNews(payload: INewsSavePayload, callback?: () => void): IAddNewsRequestAction {
  return {
    type: NewsActionTypes.ADD_NEWS_REQUEST,
    payload,
    callback,
  };
}

export interface IAddNewsSuccessAction {
  type: NewsActionTypes.ADD_NEWS_SUCCESS;
}

export function addNewsSuccess(): IAddNewsSuccessAction {
  return {
    type: NewsActionTypes.ADD_NEWS_SUCCESS,
  };
}

export interface IAddNewsFailureAction {
  type: NewsActionTypes.ADD_NEWS_FAILURE;
  error: string;
}

export function addNewsFailure(error: Error): IAddNewsFailureAction {
  return {
    type: NewsActionTypes.ADD_NEWS_FAILURE,
    error: error.message,
  };
}

// EDIT NEWS ACTIONS
export interface IEditNewsRequestAction {
  type: NewsActionTypes.EDIT_NEWS_REQUEST;
  id: number;
  payload: INewsSavePayload;
  callback?: () => void;
}

export function editNews(id: number, payload: INewsSavePayload, callback?: () => void): IEditNewsRequestAction {
  return {
    type: NewsActionTypes.EDIT_NEWS_REQUEST,
    id,
    payload,
    callback,
  };
}

export interface IEditNewsSuccessAction {
  type: NewsActionTypes.EDIT_NEWS_SUCCESS;
}

export function editNewsSuccess(): IEditNewsSuccessAction {
  return {
    type: NewsActionTypes.EDIT_NEWS_SUCCESS,
  };
}

export interface IEditNewsFailureAction {
  type: NewsActionTypes.EDIT_NEWS_FAILURE;
  error: string;
}

export function editNewsFailure(error: Error): IEditNewsFailureAction {
  return {
    type: NewsActionTypes.EDIT_NEWS_FAILURE,
    error: error.message,
  };
}

// DELETE NEWS ACTIONS
export interface IDeleteNewsRequestAction {
  type: NewsActionTypes.DELETE_NEWS_REQUEST;
  id: number;
  callback?: () => void;
}

export function deleteNews(id: number, callback?: () => void): IDeleteNewsRequestAction {
  return {
    type: NewsActionTypes.DELETE_NEWS_REQUEST,
    id,
    callback,
  };
}

export interface IDeleteNewsSuccessAction {
  type: NewsActionTypes.DELETE_NEWS_SUCCESS;
}

export function deleteNewsSuccess(): IDeleteNewsSuccessAction {
  return {
    type: NewsActionTypes.DELETE_NEWS_SUCCESS,
  };
}

export interface IDeleteNewsFailureAction {
  type: NewsActionTypes.DELETE_NEWS_FAILURE;
  error: string;
}

export function deleteNewsFailure(error: Error): IDeleteNewsFailureAction {
  return {
    type: NewsActionTypes.DELETE_NEWS_FAILURE,
    error: error.message,
  };
}

export type INewsActionTypes =
  | IGetNewsListRequestAction
  | IGetNewsListSuccessAction
  | IGetNewsListFailureAction
  | IAddNewsRequestAction
  | IAddNewsSuccessAction
  | IAddNewsFailureAction
  | IEditNewsRequestAction
  | IEditNewsSuccessAction
  | IEditNewsFailureAction
  | IDeleteNewsRequestAction
  | IDeleteNewsSuccessAction
  | IDeleteNewsFailureAction;
