import produce from "immer";
import { Reducer } from "redux";

import { IReportExtended, IReportPermissions, IReportVersion, IReportVideoGuide } from "SP/reports/reports.types";

import { IReportActionTypes, ReportActionTypes } from "./report.actions";

export interface IReportState {
  savedReport: IReportExtended;
  reportVideoGuide: IReportVideoGuide;
  loading: boolean;
  reportVideoGuideLoading: boolean;
  error: string;
  permissions: IReportPermissions;
  versions: IReportVersion[];
  versionsLoading: boolean;
}

const initialState: IReportState = {
  savedReport: null,
  reportVideoGuide: null,
  loading: false,
  reportVideoGuideLoading: false,
  error: null,
  permissions: null,
  versions: null,
  versionsLoading: false,
};

const reportReducer: Reducer<IReportState, IReportActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case ReportActionTypes.GET_REPORT_REQUEST:
      draft.loading = true;
      break;
    case ReportActionTypes.GET_REPORT_SUCCESS:
      draft.loading = false;
      draft.savedReport = action.payload;
      draft.permissions = action.permissions;
      break;
    case ReportActionTypes.GET_REPORT_FAILURE:
      draft.loading = false;
      draft.error = action.payload;
      break;
    case ReportActionTypes.EDIT_REPORT_REQUEST:
      draft.loading = true;
      break;
    case ReportActionTypes.EDIT_REPORT_SUCCESS:
      draft.loading = false;
      break;
    case ReportActionTypes.EDIT_REPORT_FAILURE:
      draft.loading = false;
      draft.error = action.payload;
      break;
    case ReportActionTypes.CREATE_REPORT_REQUEST:
      draft.loading = true;
      draft.savedReport = {
        id: null,
        tags: [],
        ...action.payload,
      };
      draft.loading = true;
      break;
    case ReportActionTypes.CREATE_REPORT_SUCCESS:
      draft.loading = false;
      break;
    case ReportActionTypes.CREATE_REPORT_FAILURE:
      draft.loading = false;
      draft.error = action.payload;
      break;

    case ReportActionTypes.GET_REPORT_VIDEO_GUIDE_REQUEST:
      draft.reportVideoGuideLoading = true;
      break;
    case ReportActionTypes.GET_REPORT_VIDEO_GUIDE_SUCCESS:
      draft.reportVideoGuideLoading = false;
      draft.reportVideoGuide = action.reportVideoGuide;
      break;
    case ReportActionTypes.GET_REPORT_VIDEO_GUIDE_FAILURE:
      draft.reportVideoGuideLoading = false;
      break;

    case ReportActionTypes.GET_REPORT_VERSION_HISTORY_REQUEST:
      draft.versionsLoading = true;
      break;
    case ReportActionTypes.GET_REPORT_VERSION_HISTORY_SUCCESS:
      draft.versionsLoading = false;
      draft.versions = action.versions;
      break;
    case ReportActionTypes.GET_REPORT_VERSION_HISTORY_FAILURE:
      draft.versionsLoading = false;
      break;

    case ReportActionTypes.RESET:
      return initialState;
  }
}, initialState);

export default reportReducer;
