export interface IFilterSectionItem {
  id: string;
  name: string;
  order: number;
}

export interface IReportBrowsersFilterProps {
  sectionItems: IFilterSectionItem[];
  selectedItems: string[];
  onSelectFilters: (v: string[]) => void;
  showToggleAll?: boolean;
}

export enum ALL {
  id = "all",
  label = "All",
}
