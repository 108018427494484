import * as Yup from "yup";

import { INewsSavePayload } from "SP/news/news.types";

const REQUIRED_MESSAGE = "This field is required to fill";
const INCORRECT_URL_MESSAGE = "The link must be correct";

const newsSchema = Yup.object<INewsSavePayload>().shape({
  newsTitle: Yup.string().trim().required(REQUIRED_MESSAGE),
  description: Yup.string().trim().required(REQUIRED_MESSAGE),
  reportLink: Yup.lazy((value) => {
    if (!value) {
      return Yup.mixed().notRequired();
    }
    return Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-?]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        INCORRECT_URL_MESSAGE,
      )
      .required(REQUIRED_MESSAGE);
  }),
});

export default newsSchema;
