import { ITerm, taxonomy } from "@pnp/sp-taxonomy";

import { ITagDTO } from "SP/tags/tags.types";

export default class TagsRepository {
  groupName = "Reporting";
  termSetName = "Navigation";
  termStore = taxonomy.getDefaultSiteCollectionTermStore();

  private async getAllTermsFromStore(): Promise<any> {
    const reportingGroup = await this.termStore.groups.getByName(this.groupName).get();
    const termsSets = await reportingGroup.termSets.getByName(this.termSetName).get();

    if (termsSets?.terms) {
      return await termsSets.terms.select("PathOfTerm", "Name", "Id", "Description").usingCaching().get();
    }

    return [];
  }

  async getAll(): Promise<ITagDTO[]> {
    return await this.getAllTermsFromStore();
  }

  async getById(id: string): Promise<ITerm> {
    return this.termStore.getTermById(id).get();
  }
}
