import React, { FC } from "react";

import Routes from "@App/routes";
import { ReactComponent as eyeIcon } from "@epam/assets/icons/common/action-eye-18.svg";
import { LinkButton } from "@epam/loveship";

import FavoriteButton from "Components/favorite-button";
import { ReportButtons } from "Components/report-buttons/report-buttons";
import withNaming from "Helpers/bemClassname";
import { getEncodedReportName } from "Helpers/reportsHelper";
import type { IReport } from "SP/reports/reports.types";

import "./dashboard-card-footer.scss";

const cn = withNaming("dashboard-card-footer");

export const DashboardCardFooter: FC<Partial<IReport>> = ({ reportName, videoGuideLink, id, kbLink }) => {
  return (
    <div className={cn("", ["flex", "justify-between", "items-center"])}>
      <LinkButton
        link={{ pathname: `${Routes.REPORTS_BROWSER.path}/${getEncodedReportName(reportName)}` }}
        icon={eyeIcon}
        caption="View details"
        cx={cn("view-btn")}
        size="24"
        fontSize="14"
      />
      <span className={cn("actions", ["flex", "justify-end", "items-center"])}>
        <FavoriteButton reportId={id} isTransparentIcon className={cn("favorite-icon")} defaultIconColor="night600" />
        <ReportButtons reportId={id} reportName={reportName} kbLink={kbLink} videoGuideLink={videoGuideLink} hideShow />
      </span>
    </div>
  );
};
