export enum GlobalActionsTypes {
  THROW_GLOBAL_ERROR = "[GLOBAL] THROW GLOBAL ERROR",
  SET_IS_MOBILE = "[GLOBAL] SET IS MOBILE",
  SET_IS_SYNONYM_WORDS_SHOW = "[GLOBAL] SET IS SYNONYM WORDS SHOW",
}

export interface IThrowGlobalError {
  type: GlobalActionsTypes.THROW_GLOBAL_ERROR;
}

export const throwGlobalError = (): IThrowGlobalError => {
  return {
    type: GlobalActionsTypes.THROW_GLOBAL_ERROR,
  };
};

export interface ISetIsMobile {
  type: GlobalActionsTypes.SET_IS_MOBILE;
  toggle: boolean;
}

export const setIsMobile = (toggle: boolean): ISetIsMobile => {
  return {
    type: GlobalActionsTypes.SET_IS_MOBILE,
    toggle,
  };
};

export interface ISetIsSynonymWordsShow {
  type: GlobalActionsTypes.SET_IS_SYNONYM_WORDS_SHOW;
  toggle: boolean;
}

export const setIsSynonymWordsShow = (toggle: boolean): ISetIsSynonymWordsShow => {
  return {
    type: GlobalActionsTypes.SET_IS_SYNONYM_WORDS_SHOW,
    toggle,
  };
};

export type IGlobalActionsTypes = IThrowGlobalError | ISetIsMobile | ISetIsSynonymWordsShow;
