import React, { FC, memo, useMemo } from "react";

import { ReactComponent as certifiedIcon } from "@App/assets/warning_fill.svg";
import { FlexRow, IconContainer, RichTextView, Tooltip } from "@epam/loveship";

import { BadgeColors } from "Components/badge";
import Badges from "Components/badges";
import { BadgesComplexGroup } from "Components/badges/badges-complex-group";
import ReportLink from "Components/report-link";
import { TimingDiv } from "Components/timing-elements";
import withNaming from "Helpers/bemClassname";
import { useTagsData } from "Hooks/useTags";
import { handleRoles } from "Pages/report/report-details/report-details-helpers";
import { CERTIFIED_MARK_TOOLTIP } from "SP/reports/reports.constants";
import type { IReport } from "SP/reports/reports.types";

import { DashboardCardFooter } from "../dashboard-card-footer/dashboard-card-footer";

import "./dashboard-card.scss";

const CONTENT_HEIGHT = 20;

interface ICard extends IReport {
  fullCard?: boolean;
}

export const DashboardCard: FC<ICard> = memo(
  ({ reportName, reportLink, videoGuideLink, id, description, tags, kbLink, certified, fullCard, primaryRoles }) => {
    const cn = withNaming("dashboard-card");

    const { getReportTags } = useTagsData();
    const { metrics, roles, domains } = getReportTags(tags);
    const rolesBadges = useMemo(() => handleRoles(roles, primaryRoles), [roles, primaryRoles]);

    return (
      <TimingDiv className={cn("report", ["flex", "flex-col", fullCard && "full"])} elementtiming="dashboard-card">
        <div className={cn("report-details", ["flex", "flex-col", "justify-center"])}>
          {fullCard && <BadgesComplexGroup badges={rolesBadges} contentHeight={CONTENT_HEIGHT} />}
          <FlexRow size="24">
            <ReportLink
              id={id}
              trimLength={38}
              className={cn("report-details-name", ["unstyled-link"])}
              name={reportName}
              link={reportLink}
            />
            {certified ? (
              <Tooltip placement="bottom" content={CERTIFIED_MARK_TOOLTIP}>
                <IconContainer cx={cn("report-details-certified")} icon={certifiedIcon} color="sky" />
              </Tooltip>
            ) : null}
          </FlexRow>
          {fullCard ? (
            <>
              <FlexRow size="24" cx={cn("description")}>
                <RichTextView cx={cn("description-text")} htmlContent={description} />
              </FlexRow>
              <BadgesComplexGroup badges={domains} contentHeight={CONTENT_HEIGHT} color={BadgeColors.blue} />
              <BadgesComplexGroup badges={metrics} contentHeight={CONTENT_HEIGHT} color={BadgeColors.grass} />
            </>
          ) : (
            <FlexRow size="24">
              <Badges
                groupName="Domains"
                contentHeight={CONTENT_HEIGHT}
                color={BadgeColors.blue}
                data={domains}
                groupRender
              />
              <Badges
                groupName="Metrics"
                contentHeight={CONTENT_HEIGHT}
                color={BadgeColors.grass}
                data={metrics}
                groupRender
              />
              <Badges groupName="Roles" contentHeight={CONTENT_HEIGHT} data={rolesBadges} groupRender />
            </FlexRow>
          )}
        </div>
        <DashboardCardFooter reportName={reportName} videoGuideLink={videoGuideLink} id={id} kbLink={kbLink} />
      </TimingDiv>
    );
  },
);
