import React from "react";

import { ReactComponent as tableSortAscIcon } from "@epam/assets/icons/common/table-sort_asc-18.svg";
import { ReactComponent as tableSortDescIcon } from "@epam/assets/icons/common/table-sort_desc-18.svg";
import { ReactComponent as tableSortIcon } from "@epam/assets/icons/common/table-swap-18.svg";
import { IconButton, Tooltip } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { IReportFields, ISort } from "SP/reports/reports.types";

interface IColumnHeaderProps {
  label: string;
  flexBasis?: string;
  sortFieldName?: IReportFields;
  sortASC?: boolean;
  tooltip?: string;
  onSort: (sort: ISort) => void;
}

interface IColumnLabelProps {
  label: string;
  sortIcon: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >;
    fillColor: "night400" | "night500";
  };
}

const getIcon = (isSortable?: boolean, sortASC?: boolean) => {
  if (!isSortable) return;

  let icon = tableSortIcon;
  let fillColor: "night400" | "night500" = "night400";

  if (sortASC !== undefined) {
    icon = sortASC ? tableSortAscIcon : tableSortDescIcon;
    fillColor = "night500";
  }

  return { icon, fillColor };
};

const getASCNextValue = (sortASC: boolean) => {
  return sortASC === undefined ? false : !sortASC;
};

const cn = withNaming("report-table");

const ColumnLabel: React.FC<IColumnLabelProps> = ({ label, sortIcon }) => (
  <div className={cn("column-header-content")}>
    {!!label && <span>{label}</span>}
    {sortIcon ? (
      <div className="icon-wrapper">
        <IconButton icon={sortIcon.icon} color={sortIcon.fillColor} />
      </div>
    ) : null}
  </div>
);

const ColumnName: React.FC<IColumnHeaderProps> = ({
  label,
  flexBasis = "",
  onSort,
  sortFieldName,
  sortASC,
  tooltip,
}) => {
  const isSortable = !!sortFieldName;
  const sortIcon = getIcon(isSortable, sortASC);

  return (
    <div
      className={cn("column-header", { sortable: isSortable, favorite: label === "" })}
      style={{ flexBasis }}
      {...(isSortable && {
        onClick: () => onSort({ field: sortFieldName, asc: getASCNextValue(sortASC) }),
      })}
    >
      {tooltip ? (
        <Tooltip content={tooltip} placement="bottom">
          <ColumnLabel label={label} sortIcon={sortIcon} />
        </Tooltip>
      ) : (
        <ColumnLabel label={label} sortIcon={sortIcon} />
      )}
    </div>
  );
};

export default ColumnName;
