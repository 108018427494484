import _isArray from "lodash/isArray";
import _isEqual from "lodash/isEqual";
import _map from "lodash/map";
import qs from "qs";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { groupTags, omitEmptyDate } from "Helpers/utils";
import { ITimePeriod } from "Pages/reports-browser/reports-filters.reducer";
import { ITagWithGroup } from "SP/tags/tags.types";
import { IUser } from "SP/users/users.types";
import { IRootReducerState } from "Store/reducers";

type ISetQueryByFiltersParams = {
  selectedTags: ITagWithGroup[];
  selectedOwners: IUser[];
  filterBy: string[];
  timePeriod: ITimePeriod;
  searchText: string;
};

const getOwnerIdsByName = (queryValue: string | string[], ownersMap: Map<string, number>) =>
  _isArray(queryValue) ? queryValue.map((ownerName) => ownersMap.get(ownerName)) : ownersMap.get(queryValue);

export const useQuery = () => {
  const history = useHistory();
  const { search, pathname, hash } = useLocation();
  const hasParams = search.indexOf("=") > -1;

  const getQuery = useCallback(() => {
    return hasParams ? qs.parse(search, { ignoreQueryPrefix: true }) : {};
  }, [hasParams, search]);

  const setQuery = useCallback(
    (oldQuery, newQuery) => {
      if (_isEqual(oldQuery, newQuery)) return;

      const search = qs.stringify(newQuery, { indices: false });
      history.replace(pathname + "?" + search + hash);
    },
    [pathname, hash],
  );

  return {
    hasParams,
    getQuery,
    setQuery,
  };
};

export const useFiltersQuery = () => {
  const { hasParams, getQuery, setQuery } = useQuery();
  const allReportsOwners = useSelector<IRootReducerState, IUser[]>((state) => state.reports.allReportsOwners);
  const ownersMap = useMemo(() => new Map(allReportsOwners.map((i) => [i.name, i.id])), [allReportsOwners]);

  const setQueryByFilters = useCallback(
    ({ selectedTags, selectedOwners, filterBy, timePeriod, searchText }: ISetQueryByFiltersParams) => {
      const oldQuery = getQuery();
      const newQuery = {
        ...(searchText && { search: searchText }),
        ...(filterBy && filterBy.length > 0 && { filterBy }),
        ...(selectedOwners && selectedOwners.length > 0 && { Owner: _map(selectedOwners, "name") }),
        ...groupTags(selectedTags),
        ...(timePeriod && { timePeriod: omitEmptyDate(timePeriod) }),
      };

      setQuery(oldQuery, newQuery);
    },
    [getQuery],
  );

  const getFiltersFromQuery = useCallback(
    (groupedTagOptions) => {
      const selectedTags = [];
      let selectedOwners = [];
      let filterBy = [];
      let timePeriod = null;
      let searchText = null;
      const query = getQuery();

      Object.keys(query).forEach((queryKey) => {
        if (queryKey === "filterBy") {
          filterBy = filterBy.concat(query[queryKey]);
          return;
        } else if (queryKey === "Owner") {
          selectedOwners = selectedOwners.concat(getOwnerIdsByName(query[queryKey], ownersMap));
          return;
        } else if (queryKey === "timePeriod") {
          timePeriod = query[queryKey];
          return;
        } else if (queryKey === "search") {
          searchText = query[queryKey];
          return;
        }

        if (_isArray(groupedTagOptions[queryKey])) {
          groupedTagOptions[queryKey]
            .filter((tag) =>
              _isArray(query[queryKey]) ? query[queryKey].includes(tag.name) : query[queryKey] === tag.name,
            )
            .forEach((tag) => {
              selectedTags.push(tag.id);
            });
        }
      });

      return { selectedTags, selectedOwners, filterBy, timePeriod, searchText };
    },
    [getQuery, ownersMap],
  );

  return {
    hasParams,
    query: getQuery(),
    setQueryByFilters,
    setQuery,
    getFiltersFromQuery,
  };
};
