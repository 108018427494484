import React, { FC, useCallback } from "react";

import { PickerInput } from "@epam/loveship";
import { ArrayDataSource } from "@epam/uui";

import { isCanSelectPickerInput } from "Helpers/utils";

import { IFilterSectionItem, IReportBrowsersFilterProps } from "./reports-browser-filter.types";

const ReportBrowsersFilterMetrics: FC<IReportBrowsersFilterProps> = ({
  sectionItems,
  selectedItems,
  onSelectFilters,
}) => {
  const itemsDataSource = new ArrayDataSource({ items: sectionItems });

  const handlePickerInputChange = useCallback(
    (values: string[]) => {
      if (isCanSelectPickerInput(selectedItems, values)) {
        onSelectFilters(values || []);
      }
    },
    [selectedItems, onSelectFilters],
  );

  return (
    <PickerInput<IFilterSectionItem, string>
      size="30"
      valueType="id"
      searchPosition="body"
      selectionMode="multi"
      dropdownPlacement="bottom-end"
      placeholder="Please select Metrics"
      sorting={{ field: "order", direction: "asc" }}
      onValueChange={handlePickerInputChange}
      dataSource={itemsDataSource}
      getName={(item) => item.name}
      value={selectedItems}
      minBodyWidth={300}
    />
  );
};

export default ReportBrowsersFilterMetrics;
