export interface ITagDTO {
  PathOfTerm: string;
  Name: string;
  Id: string;
  Description: string;
}

export interface ITag {
  name: string;
  id: string;
  description: string;
}

export interface ITagWithGroup extends ITag {
  group: string;
}

export enum TagGroupNames {
  "Role" = "Role",
  "Application" = "Application",
  "Domain" = "Domain",
  "Metric" = "Metric",
}

export enum TagGroupUINames {
  "Domain" = "Business Domain",
}

export type IGroupedTagNames = {
  [key in TagGroupNames]?: string[];
};

export type IGroupedTags = {
  [key in TagGroupNames]?: ITagWithGroup[];
};
