import React, { FC } from "react";

import Routes from "@App/routes";
import { LinkButton } from "@epam/loveship";
import type { ButtonProps } from "@epam/uui-components/src/buttons";

import withNaming from "Helpers/bemClassname";
import type { IHighlightItem } from "Helpers/highlight";
import { downloadFileByUniqID, generateOnPremiseUrl, isShortLink, isSSRSFile } from "Helpers/utils";
import { useFile } from "Hooks/useFile";
import type { IFile } from "SP/reports/reports.types";

interface IReportLinkProps {
  id: number;
  name: string;
  link: string;
  className?: string;
  lineHeight?: "12" | "18" | "24" | "30";
  trimLength?: number;
  caption?: IHighlightItem;
}

interface IReportLinkParams {
  link: string;
  file: IFile;
  id: number;
  canDownload: boolean;
}

const getReportLinkProps = ({ link, file, id, canDownload }: IReportLinkParams): ButtonProps => {
  const fileUrl = file?.url;
  const fileUniqueId = file?.uniqueId;

  if (isShortLink(link)) {
    return {
      link: {
        pathname: `${Routes.REPORTS_BROWSER.path}/${id}`,
      },
    };
  }

  if (link || isSSRSFile(fileUrl)) {
    return { href: generateOnPremiseUrl(fileUrl) || link, target: "_blank" };
  }

  if (fileUniqueId && canDownload) {
    return { onClick: () => downloadFileByUniqID(fileUniqueId) };
  }
};

const ReportLink: FC<IReportLinkProps> = ({ id, className, name, link, lineHeight, trimLength, caption }) => {
  const cn = withNaming("report-link");
  const { wrapperClassName, file, canDownload, getReportFileAndPermissions } = useFile(id, link);

  return (
    <span
      className={cn("", [wrapperClassName])}
      onMouseEnter={getReportFileAndPermissions}
      {...(name.length > trimLength && { title: name })}
    >
      <LinkButton
        cx={className}
        caption={caption || name}
        lineHeight={lineHeight}
        {...getReportLinkProps({ link, file, id, canDownload })}
      />
    </span>
  );
};

export default ReportLink;
