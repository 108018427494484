import { useField } from "formik";
import React, { useCallback, useState } from "react";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

import { Text, TextArea, TextInput } from "@epam/loveship";

import { htmlDecode } from "Helpers/utils";

import { IFieldCommonProps } from "./field.interface";

interface IInputFieldProps extends IFieldCommonProps {
  type?: "number" | "text" | "textarea";
  maxLength?: number;
  rows?: number;
  handler?: (value: string) => string;
}

function getTrimValue(maxLength: number, newValue: string): string {
  if (!!maxLength && newValue.length > maxLength) {
    return newValue.substring(0, maxLength);
  }

  return newValue;
}

const InputField: React.FC<IInputFieldProps> = ({ name, placeholder, type = "text", maxLength, rows, handler }) => {
  const [field, meta, helpers] = useField(name);
  const [inputValue, setInputValue] = useState(field.value);

  const debouncedOnTouch = useDebouncedCallback(() => {
    if (!meta.touched) {
      helpers.setTouched(true);
    }
  }, 500);

  const debouncedOnChange = useDebouncedCallback((value) => {
    helpers.setValue(value);
  }, 500);

  const error = meta.touched && !debouncedOnChange.pending() ? meta.error : "";

  const handleChange = useCallback(
    (newValue) => {
      let trimValue = getTrimValue(maxLength, newValue);

      if (handler) {
        trimValue = handler(trimValue);
      }

      setInputValue(trimValue);
      debouncedOnTouch.callback();
      debouncedOnChange.callback(trimValue);
    },
    [handler, maxLength],
  );

  return (
    <>
      {type === "textarea" ? (
        <TextArea
          placeholder={placeholder || "Please enter the value"}
          value={htmlDecode(inputValue)}
          onValueChange={handleChange}
          isInvalid={!!error}
          maxLength={maxLength}
          rows={rows}
        />
      ) : (
        <TextInput
          placeholder={placeholder || "Please enter the value"}
          type={type}
          value={inputValue}
          onValueChange={handleChange}
          isInvalid={!!error}
        />
      )}
      {!!error && (
        <Text color="fire" size="24">
          {error}
        </Text>
      )}
    </>
  );
};

export default InputField;
