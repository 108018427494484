import { all, call, fork, ForkEffect, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import FavoritesService from "SP/favorites";
import type { IUserExtended } from "SP/users/users.types";
import {
  favoriteReportFailure,
  favoriteReportSuccess,
  FavoritesActionTypes,
  getCurrentUserFavoritesFailure,
  getCurrentUserFavoritesSuccess,
  IFavoriteReportRequestAction,
  IGetCurrentUserFavoritesRequestAction,
  IUnfavoriteReportRequestAction,
  unfavoriteReportFailure,
  unfavoriteReportSuccess,
} from "Store/actions/favorites.actions";
import type { IRootReducerState } from "Store/reducers";

import { getFavoritesOrder, updateCurrentUserFavoritesOrder } from "./helpers/utils.sagas";

const favoriteService = new FavoritesService();

export const favoritesSelectors = {
  currentUser: (state: IRootReducerState) => state.users.currentUser,
  favorites: (state: IRootReducerState) => state.favorites.favorites,
  groupedTags: (state: IRootReducerState) => state.tags.groupedTags,
};

export function* getCurrentUserFavorites(action: IGetCurrentUserFavoritesRequestAction) {
  try {
    const favorites = yield call([favoriteService, "getUserFavorites"], action.userId);
    yield put(getCurrentUserFavoritesSuccess(favorites));
  } catch (error) {
    yield put(getCurrentUserFavoritesFailure(error));
    throw error;
  }
}

export function* watchGetCurrentUserFavorites() {
  yield takeLatest(FavoritesActionTypes.GET_CURRENT_USER_FAVORITES_REQUEST, getCurrentUserFavorites);
}

export function* favoriteReport(action: IFavoriteReportRequestAction) {
  try {
    const favoriteOrders = yield call(getFavoritesOrder);

    const [favoriteItem] = yield all([
      call([favoriteService, "favoriteReport"], action.reportId),
      call(updateCurrentUserFavoritesOrder, [action.reportId, ...favoriteOrders]),
    ]);
    yield put(favoriteReportSuccess(favoriteItem, action.reportId));
  } catch (error) {
    yield put(favoriteReportFailure(error, action.reportId));
    throw error;
  }
}

export function* watchFavoriteReport() {
  yield takeEvery(FavoritesActionTypes.FAVORITE_REPORT_REQUEST, favoriteReport);
}

export function* unfavoriteReport(action: IUnfavoriteReportRequestAction) {
  try {
    const favoriteOrders = yield call(getFavoritesOrder);
    const currentUser: IUserExtended = yield select(favoritesSelectors.currentUser);

    yield all([
      call([favoriteService, "unfavoriteReport"], action.favoriteId, currentUser.id),
      call(
        updateCurrentUserFavoritesOrder,
        favoriteOrders.filter((id) => id !== action.reportId),
      ),
    ]);

    yield put(unfavoriteReportSuccess(action.reportId));
  } catch (error) {
    yield put(unfavoriteReportFailure(error, action.reportId));
    throw error;
  }
}

export function* watchUnfavoriteReport() {
  yield takeEvery(FavoritesActionTypes.UNFAVORITE_REPORT_REQUEST, unfavoriteReport);
}

export default function* singleReportSagas(): Iterator<ForkEffect> {
  yield fork(watchGetCurrentUserFavorites);
  yield fork(watchFavoriteReport);
  yield fork(watchUnfavoriteReport);
}
