import _isEmpty from "lodash/isEmpty";
import React, { ReactElement, useMemo } from "react";

import { ReactComponent as LikeIcon } from "@epam/assets/icons/common/social-thumb-up-18.svg";

import Badge from "Components/badge";
import { BadgeDescriptionTooltip } from "Components/badges/badges-description-tooltip";
import { BadgesGroup } from "Components/badges/badges-group";
import type { IBadgesCommon } from "Components/badges/badges.types";
import highlight from "Helpers/highlight";
import { checkIsSomeTagHighlighted } from "Helpers/reportsHelper";
import { isLastElement } from "Helpers/utils";
import LabeledField from "Pages/reports-browser/report-view/report-cards/labeled-field";

interface IBadgesInline extends IBadgesCommon {
  maxBadges?: number;
}

const BadgesInline: React.FC<IBadgesInline> = ({ color, data, groupName, maxBadges = 5, searchText }) => {
  const newData = _isEmpty(data) ? [] : data;
  const isShowAllBadges = newData.length < maxBadges;
  const isGroupTitleHighlighted = useMemo(
    () => checkIsSomeTagHighlighted(searchText, newData, "label"),
    [searchText, newData.length],
  );

  const renderAllBadges = (startIndex?: number): ReactElement[] => {
    return newData.slice(startIndex).map((tag, i, arr) => (
      <BadgeDescriptionTooltip key={tag.id} description={tag.description}>
        <Badge color={color} key={tag.id} className={isLastElement(i, arr) ? "last-group-element" : ""}>
          {tag.thumbUp && <LikeIcon className="thumb-up-icon" />}
          {highlight.peek(searchText, tag.label)}
        </Badge>
      </BadgeDescriptionTooltip>
    ));
  };

  const renderBadges = () => {
    if (isShowAllBadges) {
      const tag = newData[0];

      return (
        <BadgeDescriptionTooltip key={tag.id} description={tag.description}>
          <Badge color={color}>
            {tag.thumbUp && <LikeIcon className="thumb-up-icon" />}
            {highlight.peek(searchText, tag.label)}
          </Badge>
        </BadgeDescriptionTooltip>
      );
    }

    return (
      <BadgesGroup
        isHighlighted={isGroupTitleHighlighted}
        badges={renderAllBadges()}
        badgesCount={newData.length}
        groupName={groupName}
        color={color}
      />
    );
  };

  return (
    !!newData.length && (
      <>
        <LabeledField isBadges label={groupName} cx={data.length === 1 || !isShowAllBadges ? "last-group-element" : ""}>
          {renderBadges()}
        </LabeledField>
        {isShowAllBadges ? renderAllBadges(1) : null}
      </>
    )
  );
};

export default BadgesInline;
