import moment from "moment";
import React, { FC, useEffect, useMemo, useRef } from "react";

import { ReactComponent as kbIcon } from "@App/assets/kb.svg";
import { ReactComponent as videoGuideIcon } from "@App/assets/video_guide.svg";
import { ReactComponent as certificateIcon } from "@App/assets/warning_fill.svg";
import { svc } from "@App/services";
import { ReactComponent as copyIcon } from "@epam/assets/icons/common/action-copy_content-18.svg";
import { Button, IconButton, IconContainer, RichTextView, SuccessNotification, Text, Tooltip } from "@epam/loveship";
import type { INotification } from "@epam/uui";
import { Tooltip as TooltipType } from "@epam/uui-components";

import { BadgeColors } from "Components/badge";
import FavoriteButton from "Components/favorite-button";
import { DEFAULT_DATE_FORMAT } from "Components/fields/datepicker-field";
import { FileButton } from "Components/file-button";
import { Owners } from "Components/owners/owners";
import { handleOpenVideoGuideModal } from "Components/video-guide-modal";
import withNaming from "Helpers/bemClassname";
import { copyTextToClipboard, downloadFile, htmlDecode, isShortLink } from "Helpers/utils";
import { useCopyText } from "Hooks/useCopyText";
import usePermissions from "Hooks/usePermissions";
import { useTagsData } from "Hooks/useTags";
import { CERTIFIED_MARK_TOOLTIP } from "SP/reports/reports.constants";
import type { IReportExtended } from "SP/reports/reports.types";

import ReportDetailsBadges from "./report-details-badges";
import { handleRoles, REPORT_LABELS } from "./report-details-helpers";
import ReportDetailsRow from "./report-details-row";
import ReportDetailsText from "./report-details-text";

import "./style.scss";

const ReportDetails: FC<IReportExtended> = (props) => {
  const {
    primaryRoles,
    reportName,
    certified,
    ownersNames,
    modificationDate,
    kbLink,
    videoGuideLink,
    reportLink,
    powerBI_ID,
    description,
    tags,
    file,
    id,
  } = props;
  const cn = withNaming("report");
  const tooltipRef = useRef<TooltipType>(null);
  const { isCopied, handleCopyUrl } = useCopyText(tooltipRef);

  const { permissions, getReportPermissions } = usePermissions(id);
  const { getReportTags } = useTagsData();
  const { applications, domains, metrics, roles } = getReportTags(tags);

  const rolesBadges = useMemo(() => handleRoles(roles, primaryRoles), [roles, primaryRoles]);
  const isServerLink = isShortLink(reportLink);

  const handleDownloadFile = () => {
    downloadFile(file, permissions?.canDownload);
  };

  const handleCopy = (e) => {
    handleCopyUrl(e, window.location.href);
  };

  const handleReportLinkCopy = () => {
    copyTextToClipboard(reportLink, () => {
      svc.uuiNotifications.show(
        (props: INotification) => (
          <SuccessNotification {...props}>
            <Text size="36" font="sans" fontSize="14">
              Link is copied to your clipboard
            </Text>
          </SuccessNotification>
        ),
        { position: "bot-left", duration: 2 },
      );
    });
  };

  const renderReportActions = () => (
    <div className={cn("actions-row", ["flex items-center"])}>
      <FavoriteButton
        isTransparentIcon
        tooltipPlacement="top"
        className={cn("favorite-icon", ["svg-24"])}
        reportId={id}
      />
      {certified && (
        <Tooltip placement="top" content={CERTIFIED_MARK_TOOLTIP}>
          <IconContainer cx={cn("certified-icon", ["svg-24"])} icon={certificateIcon} color="sky" />
        </Tooltip>
      )}
      <a
        className={cn("report-name", { disabled: isServerLink })}
        {...(reportLink ? { href: reportLink, target: "_blank" } : { onClick: handleDownloadFile })}
        rel="noopener noreferrer"
        tabIndex={0}
      >
        {htmlDecode(reportName)}
      </a>
      <Tooltip
        ref={tooltipRef}
        placement="top"
        content={isCopied ? "Link is copied to your clipboard" : REPORT_LABELS.copyLink}
      >
        <IconButton cx={cn("copy-icon", ["svg-24 svg-hover"])} icon={copyIcon} onClick={handleCopy} />
      </Tooltip>
      <FileButton
        reportId={id}
        reportLink={reportLink}
        tooltipPlacement="top"
        className={cn("file-icon", ["svg-24"])}
      />
      <Tooltip placement="top" content={REPORT_LABELS.linkToKB}>
        <IconButton cx={cn("kb-icon", ["svg-24 svg-hover"])} target="_blank" icon={kbIcon} href={kbLink} />
      </Tooltip>
      {videoGuideLink && (
        <Tooltip placement="top" content={REPORT_LABELS.viewVideoTutorial}>
          <IconButton
            cx={cn("videoGuide-icon", ["svg-24 svg-hover"])}
            icon={videoGuideIcon}
            onClick={() => handleOpenVideoGuideModal(id)}
          />
        </Tooltip>
      )}
    </div>
  );

  useEffect(() => {
    if (id) {
      getReportPermissions();
    }
  }, [id]);

  return (
    <div className={cn("container")}>
      {renderReportActions()}
      {!!isServerLink && (
        <ReportDetailsRow
          label={REPORT_LABELS.linkToTheReport}
          info="Copy the link and paste it into the browser to open the report"
        >
          <Text cx="report-text report-text--serverPath">{reportLink}</Text>
          <Button
            cx="copy-link"
            color="sky"
            fontSize="12"
            lineHeight="12"
            fill="white"
            onClick={handleReportLinkCopy}
            caption={REPORT_LABELS.copyLink}
          />
        </ReportDetailsRow>
      )}
      <ReportDetailsRow label="Description">
        <RichTextView cx="report-text--description" htmlContent={description} />
      </ReportDetailsRow>
      <ReportDetailsRow label="Owner">
        <Owners ownersNames={ownersNames} reportName={reportName} />
      </ReportDetailsRow>
      <ReportDetailsText
        label="Modification date"
        text={modificationDate && moment.utc(modificationDate).format(DEFAULT_DATE_FORMAT)}
        info="Report structure Modification date"
      />
      {!!powerBI_ID && <ReportDetailsText label="Power BI ID" text={powerBI_ID} />}
      <ReportDetailsBadges label="Roles" badges={rolesBadges} />
      <ReportDetailsBadges label="Business Domains" badges={domains} color={BadgeColors.blue} />
      <ReportDetailsBadges label="Applications" badges={applications} color={BadgeColors.amber} />
      <ReportDetailsBadges label="Metrics" badges={metrics} color={BadgeColors.grass} />
    </div>
  );
};

export default ReportDetails;
