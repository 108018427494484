import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _uniqBy from "lodash/uniqBy";
import qs from "qs";
import { call, fork, ForkEffect, put, select, takeLatest } from "redux-saga/effects";

import { waitForAllReports, waitForFavorites } from "@App/sagas/helpers/utils.sagas";
import { loadUsersByNames } from "@App/sagas/users.sagas";

import highlight from "Helpers/highlight";
import { calculateSearchRank, checkIsSomeTagHighlighted, getReportTags } from "Helpers/reportsHelper";
import { isUsernamesFromQuery } from "Helpers/utils";
import analytics from "Helpers/zarazAnalytics";
import { setFilterOwners } from "Pages/reports-browser/reports-filters.actions";
import type { ITimePeriod } from "Pages/reports-browser/reports-filters.reducer";
import ReportsService from "SP/reports";
import { filterReports } from "SP/reports/reports.filters";
import type { IReport, IReportExtended, IStreamReport } from "SP/reports/reports.types";
import SearchService from "SP/search/search.service";
import type { ITagWithGroup } from "SP/tags/tags.types";
import { getUsersByIdsSuccess, UsersActionsTypes } from "Store/actions/users.actions";
import type { IRootReducerState } from "Store/reducers";

import {
  getFilteredReportsFailure,
  getFilteredReportsSuccess,
  getReportsFailure,
  getReportsSuccess,
  IGetReportsRequestAction,
  ReportsActionTypes,
  setAllReportsOwners,
} from "./reports-browser.actions";

const reportsService = new ReportsService();

const reportsBrowsersSelectors = {
  selectedTags: (state: IRootReducerState): Set<string> => state.filters.selectedTags,
  selectedOwners: (state: IRootReducerState): number[] => state.filters.selectedOwners,
  timePeriod: (state: IRootReducerState): ITimePeriod => state.filters.timePeriod,
  allTags: (state: IRootReducerState): ITagWithGroup[] => state.tags.allTags,
  search: (state: IRootReducerState): string => state.filters.search,
};

function* waitForSelectedOwners() {
  let selectedOwners = yield select(reportsBrowsersSelectors.selectedOwners);

  if (isUsernamesFromQuery(selectedOwners)) {
    const users = yield loadUsersByNames({
      type: UsersActionsTypes.GET_USERS_BY_NAMES_REQUEST,
      names: selectedOwners,
    });
    selectedOwners = _map(users, "id");

    // Replace selected owner names from query with ids
    yield put(setFilterOwners(selectedOwners));
  }

  return selectedOwners;
}

export function* getFilteredReports() {
  try {
    const allReports = yield waitForAllReports();
    const favorites = yield waitForFavorites();
    const selectedOwners = yield waitForSelectedOwners();

    const searchQuery = yield select(reportsBrowsersSelectors.search);
    const selectedTags = yield select(reportsBrowsersSelectors.selectedTags);
    const timePeriod = yield select(reportsBrowsersSelectors.timePeriod);
    const allTags = yield select(reportsBrowsersSelectors.allTags);

    const { filterBy } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const filterByList = [].concat(filterBy || []);

    if (searchQuery && SearchService.isDictionaryNotLoaded()) {
      yield call([SearchService, "getSearchDictionary"]);
    }

    const reports: IReport[] = yield call(filterReports, {
      allReports,
      allTags,
      favoriteIds: _map(favorites, "reportId"),
      search: searchQuery,
      filterBy: filterByList,
      selectedTags,
      selectedOwners,
      timePeriod,
    });

    if (analytics.isProvideSearchData()) {
      analytics.provideSearchData(searchQuery, reports.length);
    }

    const extendedReports: IReportExtended[] = reports.map((report) => {
      const titleHighlighted = highlight.checkForSearchRank(searchQuery, report.reportName, "reportNameDictionary");
      const descriptionHighlighted = highlight.checkForSearchRank(
        searchQuery,
        report.description,
        "descriptionDictionary",
      );
      const reportTags = getReportTags(report.tags, allTags);
      const isTagHighlighted = checkIsSomeTagHighlighted(searchQuery, reportTags, "name");

      return {
        ...report,
        searchRank: calculateSearchRank(titleHighlighted, descriptionHighlighted, isTagHighlighted),
      };
    });

    yield put(getFilteredReportsSuccess(extendedReports));
  } catch (e) {
    yield put(getFilteredReportsFailure(e));
    throw e;
  }
}

export function* loadReports(action: IGetReportsRequestAction) {
  try {
    const reports: IStreamReport[] = yield call([reportsService, "getAll"]);
    const allOwners = _map(reports, "ownerUsers").flat();
    const uniqAllOwners = _uniqBy(allOwners, (user) => user.id);
    const extendedReports: IReportExtended[] = reports.map(({ ownerUsers, ...report }) => report);

    yield put(getUsersByIdsSuccess(uniqAllOwners));
    yield put(setAllReportsOwners(uniqAllOwners));

    const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    // Update 'filteredReports' if no search params are found in url, otherwise 'applyFilters' saga
    // will be trigger that update, after 'SET_ALL_FILTERS' action is dispatched!
    if (_isEmpty(searchParams)) {
      yield put(getFilteredReportsSuccess(extendedReports));
    }

    yield put(getReportsSuccess(extendedReports));
  } catch (e) {
    yield put(getReportsFailure(e));
    throw e;
  }
}

export function* watchFilterReports() {
  yield takeLatest(ReportsActionTypes.GET_FILTERED_REPORTS_REQUEST, getFilteredReports);
}

export function* watchLoadReports() {
  yield takeLatest(ReportsActionTypes.GET_ALL_REPORTS_REQUEST, loadReports);
}

export default function* reportsSagas(): Iterator<ForkEffect> {
  yield fork(watchFilterReports);
  yield fork(watchLoadReports);
}
