import { MutableRefObject, useState } from "react";

import { copyTextToClipboard } from "Helpers/utils";

export const useCopyText = (ref: MutableRefObject<any>) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyTextCb = (e) => () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);

      if (ref.current?.state.isOpen) {
        ref.current.mouseClickHandler(e);
      }
    }, 2000);
  };

  const handleCopyUrl = (e, content: string) => {
    copyTextToClipboard(content, handleCopyTextCb(e));
  };

  return {
    isCopied,
    handleCopyUrl,
  };
};
