import moment from "moment";

import { getSearchSegments, groupTags } from "Helpers/utils";
import { DashboardFilterTabs } from "Pages/dashboard/dashboard-domain-filters/dashboard-domain-filters";
import type { ITimePeriod } from "Pages/reports-browser/reports-filters.reducer";
import type { IReportExtended } from "SP/reports/reports.types";
import SearchService from "SP/search/search.service";
import type { IGroupedTagNames, ITagWithGroup } from "SP/tags/tags.types";

function isFavorite(favoriteIds: number[], reportId: number): boolean {
  return favoriteIds.includes(reportId);
}

function isHasTag(reportTags: string[], selectedTags: string[]): boolean {
  return reportTags.some((tagId) => selectedTags.includes(tagId));
}

function isHasTagFromEveryGroup(groupedTags: IGroupedTagNames, reportTags: string[]) {
  return Object.keys(groupedTags).every((tagName) => isHasTag(reportTags, groupedTags[tagName]));
}

export function filterByFavorites(allReports: IReportExtended[], favoriteIds: number[]) {
  return allReports.filter((report) => isFavorite(favoriteIds, report.id));
}

export function filterByTags(allReports: IReportExtended[], groupedTags: IGroupedTagNames) {
  return allReports.filter((report) => isHasTagFromEveryGroup(groupedTags, report.tags));
}

function checkFilterBy(filterBy: string, report: IReportExtended, favoriteIds: number[]) {
  if (filterBy === DashboardFilterTabs.favorite) {
    return favoriteIds.includes(report.id);
  } else if (filterBy === DashboardFilterTabs.certified) {
    return report.certified;
  }
}

function checkTimePeriod(reportModificationDate: string, timePeriod: ITimePeriod) {
  const { fromDate, toDate } = timePeriod;
  const reportDate = moment.utc(reportModificationDate).toDate();

  if (fromDate && !toDate) {
    return reportDate >= moment.utc(fromDate).toDate();
  } else if (!fromDate && toDate) {
    return reportDate <= moment.utc(toDate).toDate();
  }

  return reportDate >= moment.utc(fromDate).toDate() && reportDate <= moment.utc(toDate).toDate();
}

function checkSearchQuery(query: string, report: IReportExtended, allTagsMap: Map<string, string>) {
  const lQuery = query.toLowerCase();

  const { reportNameDictionary, descriptionDictionary } = SearchService.CachedDictionary;
  const words = getSearchSegments(query);

  const isFoundByWords = words.some((word) => {
    const isWordInReportName = word.length > 2 && report.reportName?.toLocaleLowerCase().includes(word);

    const isDictionaryWordInReportName = reportNameDictionary[word]?.some((dictionaryWord) =>
      report.reportName?.toLocaleLowerCase().includes(dictionaryWord),
    );

    const isDictionaryWordInDescription = descriptionDictionary[word]?.some((dictionaryWord) =>
      report.description?.toLocaleLowerCase().includes(dictionaryWord),
    );

    return isWordInReportName || isDictionaryWordInReportName || isDictionaryWordInDescription;
  });

  const isFoundByDescription = report.description.toLocaleLowerCase().includes(lQuery);
  const isFoundByTags = report.tags.some((tag) => {
    const tagLabel = allTagsMap.get(tag);
    return tagLabel?.toLocaleLowerCase().includes(lQuery);
  });

  return isFoundByWords || isFoundByDescription || isFoundByTags;
}

export interface IFilterReportsArgs {
  allReports: IReportExtended[];
  allTags: ITagWithGroup[];
  favoriteIds: number[];
  selectedTags: Set<string>;
  selectedOwners: number[];
  timePeriod: ITimePeriod;
  filterBy: string[];
  search: string;
}

export function filterReports(params: IFilterReportsArgs) {
  const { allReports, allTags, favoriteIds, search, filterBy, selectedTags, selectedOwners, timePeriod } = params;
  let groupSelectedTags: IGroupedTagNames = null;
  let allTagsMap: Map<string, string> = null;

  if (selectedTags.size > 0) {
    groupSelectedTags = groupTags(
      allTags.filter((tag) => selectedTags.has(tag.id)),
      "id",
    );
  }

  if (search) {
    allTagsMap = new Map(allTags.map((tag) => [tag.id, tag.name]));
  }

  return allReports.filter((report) => {
    let isValid = true;

    if (search) {
      isValid = checkSearchQuery(search, report, allTagsMap) && isValid;
    }

    if (filterBy?.length > 0) {
      filterBy.forEach((filterBy) => {
        isValid = checkFilterBy(filterBy, report, favoriteIds) && isValid;
      });
    }

    if (selectedTags.size > 0) {
      isValid = isHasTagFromEveryGroup(groupSelectedTags, report.tags) && isValid;
    }

    if (timePeriod) {
      isValid = checkTimePeriod(report.modificationDate, timePeriod) && isValid;
    }

    if (selectedOwners.length > 0) {
      isValid = report.owner.some((ownerId) => selectedOwners.includes(ownerId)) && isValid;
    }

    return isValid;
  });
}
