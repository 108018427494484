import { useField } from "formik";
import React, { useCallback, useMemo } from "react";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";

import { DataPickerRowProps } from "@epam/loveship";

import { Select, SelectWithCopy, SelectWithEmailsCopy } from "Components/select";
import type { IOnCheckEmails, ISelectOption, ISelectPickerInputProps } from "Components/select/select.types";

import type { IFieldCommonProps } from "../field.interface";

interface SelectFieldProps extends ISelectPickerInputProps, IFieldCommonProps {
  searchLoading?: boolean;
  withCopy?: boolean;
  withEmailsCopy?: boolean;
  onCheckEmails?: IOnCheckEmails;
  renderRow?: (props: DataPickerRowProps<ISelectOption, any>) => JSX.Element;
  getSortedTogglerValue?: (val: string[]) => string[];
  renderTogglerItem?: (props: any) => JSX.Element;
  onValueChange?: (newValue?: string[]) => void;
  addOnCopy?: () => string[];
  strictOnSearch?: boolean;
}

const SelectField: React.FC<SelectFieldProps> = ({
  loading,
  searchLoading,
  withCopy,
  withEmailsCopy,
  options,
  name,
  placeholder,
  onSearchChange,
  onCheckEmails,
  renderNotFound,
  renderFooter,
  renderRow,
  renderTogglerItem,
  getSortedTogglerValue,
  onValueChange,
  addOnCopy,
  strictOnSearch,
}) => {
  const [field, meta, helpers] = useField(name);
  const error = meta.touched ? meta.error : "";

  const debouncedOnTouch = useDebouncedCallback(() => {
    if (!meta.touched) {
      helpers.setTouched(true);
    }
  }, 500);

  const handleValueChange = useCallback((value) => {
    debouncedOnTouch.callback();
    helpers.setValue(value);
    onValueChange?.(value);
  }, []);

  const value = useMemo(
    () => (getSortedTogglerValue ? getSortedTogglerValue(field.value) : field.value),
    [JSON.stringify(field.value), getSortedTogglerValue],
  );

  const props = {
    loading: loading,
    options: options,
    placeholder: placeholder,
    value,
    error: error,
    onValueChange: handleValueChange,
    onSearchChange: onSearchChange,
    renderRow: renderRow,
    renderTogglerItem: renderTogglerItem,
    addOnCopy: addOnCopy,
    strictOnSearch: strictOnSearch,
  };

  if (withCopy) {
    return <SelectWithCopy<string> {...props} />;
  }

  if (withEmailsCopy) {
    return <SelectWithEmailsCopy<string> {...props} searchLoading={searchLoading} onCheckEmails={onCheckEmails} />;
  }

  return <Select<string> {...props} renderFooter={renderFooter} renderNotFound={renderNotFound} />;
};

export default SelectField;
