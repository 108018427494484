import { useField } from "formik";
import React from "react";

import { Switch } from "@epam/loveship";

import { IFieldCommonProps } from "./field.interface";

export interface ISwitchFieldProps extends IFieldCommonProps {
  label: string;
  isDisabled?: boolean;
}

const SwitchField: React.FC<ISwitchFieldProps> = ({ name, label, isDisabled }) => {
  const [field, , helpers] = useField(name);
  const handleChange = (value: boolean) => helpers.setValue(value);

  return <Switch label={label} onValueChange={handleChange} value={field.value} isDisabled={isDisabled} />;
};

export default SwitchField;
