import type { IDeleteReportSuccessAction } from "Pages/report/report.actions";
import type { IReport } from "SP/reports/reports.types";
import type { IFavoriteReportSuccessAction, IUnfavoriteReportSuccessAction } from "Store/actions/favorites.actions";

export enum DashboardActionTypes {
  GET_DASHBOARD_REPORTS_REQUEST = "[DASHBOARD] GET DASHBOARD REPORTS",
  GET_DASHBOARD_REPORTS_SUCCESS = "[DASHBOARD] GET DASHBOARD REPORTS SUCCESS",
  GET_DASHBOARD_REPORTS_FAILURE = "[DASHBOARD] GET DASHBOARD REPORTS FAILURE",

  SET_FILTER_ROLE = "[DASHBOARD] SET FILTER ROLE",

  GET_FILTER_ROLE = "[DASHBOARD] GET FILTER ROLE",

  SET_PRIMARY_MODAL_VALUE = "[DASHBOARD] SET_PRIMARY_MODAL_VALUE",

  REORDER_FAVORITE_REPORTS = "[DASHBOARD] REORDER FAVORITE REPORTS",
}

// GET DASHBOARD REPORTS
export interface IGetDashboardReportsRequestAction {
  type: DashboardActionTypes.GET_DASHBOARD_REPORTS_REQUEST;
}

export function getDashboardReports(): IGetDashboardReportsRequestAction {
  return {
    type: DashboardActionTypes.GET_DASHBOARD_REPORTS_REQUEST,
  };
}

export interface IGetDashboardReportsSuccessAction {
  type: DashboardActionTypes.GET_DASHBOARD_REPORTS_SUCCESS;
  favoriteReports: IReport[];
  roleRelatedReports: IReport[];
}

export function getDashboardReportsSuccess(
  favoriteReports: IReport[],
  roleRelatedReports: IReport[],
): IGetDashboardReportsSuccessAction {
  return {
    type: DashboardActionTypes.GET_DASHBOARD_REPORTS_SUCCESS,
    favoriteReports,
    roleRelatedReports,
  };
}

export interface IGetDashboardReportsFailureAction {
  type: DashboardActionTypes.GET_DASHBOARD_REPORTS_FAILURE;
  error: string;
}

export function getDashboardReportsFailure(error: Error): IGetDashboardReportsFailureAction {
  return {
    type: DashboardActionTypes.GET_DASHBOARD_REPORTS_FAILURE,
    error: error.message,
  };
}

// GET FILTER ROLE
export interface IGetFilterRoleAction {
  type: DashboardActionTypes.GET_FILTER_ROLE;
}

export function getFilterRole(): IGetFilterRoleAction {
  return {
    type: DashboardActionTypes.GET_FILTER_ROLE,
  };
}

export interface ISetPrimaryModalValueAction {
  type: DashboardActionTypes.SET_PRIMARY_MODAL_VALUE;
  primaryRoleModalValue: string;
}

export function setPrimaryModalValue(primaryRoleModalValue: string): ISetPrimaryModalValueAction {
  return {
    type: DashboardActionTypes.SET_PRIMARY_MODAL_VALUE,
    primaryRoleModalValue,
  };
}

// SET FILTERS
export interface ISetFilterRoleAction {
  type: DashboardActionTypes.SET_FILTER_ROLE;
  filterRole: string;
}

export function setFilterRole(filterRole: string): ISetFilterRoleAction {
  return {
    type: DashboardActionTypes.SET_FILTER_ROLE,
    filterRole,
  };
}

export interface IReorderFavoriteReportsAction {
  type: DashboardActionTypes.REORDER_FAVORITE_REPORTS;
  favoriteReports: IReport[];
}

export function reorderFavoriteReports(favoriteReports: IReport[]): IReorderFavoriteReportsAction {
  return {
    type: DashboardActionTypes.REORDER_FAVORITE_REPORTS,
    favoriteReports,
  };
}

export type IDashboardActionTypes =
  | IGetDashboardReportsRequestAction
  | IGetDashboardReportsSuccessAction
  | IGetDashboardReportsFailureAction
  | ISetFilterRoleAction
  | IReorderFavoriteReportsAction
  | IFavoriteReportSuccessAction
  | IUnfavoriteReportSuccessAction
  | IDeleteReportSuccessAction
  | IGetFilterRoleAction
  | ISetPrimaryModalValueAction;
