import { call, fork, ForkEffect, put, select, takeEvery } from "redux-saga/effects";

import { getExistedUsersByEmails, getSortedUserIdsByEmails } from "Helpers/emailsCopy";
import UsersService from "SP/users";
import type { IUser } from "SP/users/users.types";
import { getCurrentUserFavorites } from "Store/actions/favorites.actions";
import {
  checkEmailsFailure,
  checkEmailsSuccess,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  getUsersByIdsFailure,
  getUsersByIdsSuccess,
  getUsersByNamesFailure,
  getUsersByNamesSuccess,
  ICheckEmailsRequestAction,
  IGetCurrentUserRequestAction,
  IGetUsersByIdsRequestAction,
  IGetUsersByNamesRequestAction,
  ISearchUsersRequestAction,
  searchUsersFailure,
  searchUsersSuccess,
  UsersActionsTypes,
} from "Store/actions/users.actions";
import type { IRootReducerState } from "Store/reducers";

const usersService = new UsersService();

export function* loadSearchUsers(action: ISearchUsersRequestAction) {
  try {
    const users = yield call([usersService, "searchPeople"], action.search, action.limit);
    yield put(searchUsersSuccess(users));
  } catch (e) {
    yield put(searchUsersFailure(e));
    throw e;
  }
}

export function* watchSearchUsers() {
  yield takeEvery(UsersActionsTypes.SEARCH_USERS_REQUEST, loadSearchUsers);
}

export function* loadUsersByIds(action: IGetUsersByIdsRequestAction) {
  try {
    const users = yield call([usersService, "getUsersByIds"], action.ids);
    yield put(getUsersByIdsSuccess(users));

    return users;
  } catch (e) {
    yield put(getUsersByIdsFailure(e));
    throw e;
  }
}

export function* watchLoadUsersByIds() {
  yield takeEvery(UsersActionsTypes.GET_USERS_BY_IDS_REQUEST, loadUsersByIds);
}

export function* loadUsersByNames(action: IGetUsersByNamesRequestAction) {
  try {
    const users = yield call([usersService, "getUsersByNames"], action.names);
    yield put(getUsersByNamesSuccess(users));

    return users;
  } catch (e) {
    yield put(getUsersByNamesFailure(e));
    throw e;
  }
}

export function* watchLoadUsersByNames() {
  yield takeEvery(UsersActionsTypes.GET_USERS_BY_NAMES_REQUEST, loadUsersByNames);
}

export function* getCurrentUser(action: IGetCurrentUserRequestAction) {
  try {
    const currentUser = yield call([usersService, "getCurrentUser"]);
    yield put(getCurrentUserFavorites(currentUser.id));
    yield put(getCurrentUserSuccess(currentUser));
  } catch (e) {
    yield put(getCurrentUserFailure(e));
    throw e;
  }
}

export function* watchGetCurrentUser() {
  yield takeEvery(UsersActionsTypes.GET_CURRENT_USER_REQUEST, getCurrentUser);
}

export function* checkEmails(action: ICheckEmailsRequestAction) {
  try {
    const allUsers: IUser[] = yield select((state: IRootReducerState) => state.users.allUsers);
    const existedUsers = getExistedUsersByEmails(allUsers, action.emails);
    const missedUserEmails = action.emails.filter((email) => !existedUsers.has(email.toLowerCase()));

    let foundUsers: IUser[] = [];

    if (missedUserEmails.length > 0) {
      foundUsers = yield call([usersService, "searchPeopleByEmails"], missedUserEmails);
    }
    yield put(checkEmailsSuccess(foundUsers));

    const sortedUserIds = getSortedUserIdsByEmails({ emails: action.emails, existedUsers, foundUsers });
    action.callback(sortedUserIds);
  } catch (e) {
    action.callback([]);
    yield put(checkEmailsFailure(e));
    throw e;
  }
}

export function* watchCheckEmails() {
  yield takeEvery(UsersActionsTypes.CHECK_EMAILS_REQUEST, checkEmails);
}

export default function* reportsSagas(): Iterator<ForkEffect> {
  yield fork(watchSearchUsers);
  yield fork(watchLoadUsersByIds);
  yield fork(watchLoadUsersByNames);
  yield fork(watchGetCurrentUser);
  yield fork(watchCheckEmails);
}
