import { ItemAddResult, List, sp } from "@pnp/sp";

import { IFavoriteDTO } from "./favorites.types";

export default class FavoritesRepository {
  private dl: List = sp.web.lists.getByTitle("Favorites");

  async getUserFavorites(userId: number): Promise<IFavoriteDTO[]> {
    return await this.dl.items.filter(`AuthorId eq ${userId}`).select("DocIDId", "Id").get();
  }

  async favoriteReport(reportId: number): Promise<ItemAddResult> {
    return await this.dl.items.add({
      DocIDId: reportId,
    });
  }

  async unfavoriteReport(favoriteId: number, userId: number): Promise<string> {
    return await this.dl.items.filter(`AuthorId eq ${userId}`).getById(favoriteId).recycle();
  }
}
