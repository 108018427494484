import React, { FC } from "react";

import { ReactComponent as loaderIcon } from "@epam/assets/icons/loaders/circle_loader_30 .svg";
import { IconContainer } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";

import "./loader.scss";

const Loader: FC = () => {
  const cn = withNaming("loader");
  return (
    <div className={cn()}>
      <IconContainer cx={cn("icon")} icon={loaderIcon} />
    </div>
  );
};

export default Loader;
