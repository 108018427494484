import React, { FC, ReactElement, useEffect, useLayoutEffect, useRef } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import { ApmRoute } from "@elastic/apm-rum-react";

import Header from "Components/header";
import ProtectedRoute from "Components/protected-route";
import { getVideoGuideReportId, handleOpenVideoGuideModal } from "Components/video-guide-modal";
import { RedirectRoute } from "Helpers/storage";
import { useReportsMethods, useTagsMethods, useUsersMethods } from "Hooks/index";
import { useMobileEffect } from "Hooks/useMobile";
import { useDashboardReportsMethods } from "Pages/dashboard/dashboard.hooks";
import NotFoundPage from "Pages/NotFoundPage";

import Routes, { IRoute } from "./routes";

export const App: FC = () => {
  const redirectRoute = useRef(RedirectRoute.get());
  const { getTags } = useTagsMethods();
  const { getReports } = useReportsMethods();
  const { getCurrentUser } = useUsersMethods();
  const { getFilterRole } = useDashboardReportsMethods();
  const history = useHistory();

  useLayoutEffect(() => {
    const reportId = getVideoGuideReportId(history.location.search);

    if (reportId) {
      handleOpenVideoGuideModal(reportId);
    }
  }, []);

  useEffect(() => {
    getTags();
    getFilterRole();
    getReports();
    getCurrentUser();

    if (redirectRoute.current) {
      redirectRoute.current = null;
      RedirectRoute.remove();
    }
  }, []);

  // Set global isMobile value
  useMobileEffect();

  return (
    <>
      <Route component={Header} />
      {redirectRoute.current && <Redirect to={redirectRoute.current} />}
      <Switch>
        {Object.values(Routes).map((route: IRoute): ReactElement | undefined => {
          if (route.protected) {
            return (
              <ProtectedRoute key={route.path} permissionKey={route.permissionKey} path={route.path} {...route.props} />
            );
          }
          return <ApmRoute key={route.path} path={route.path} {...route.props} />;
        })}
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};
