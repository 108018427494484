const StorageObject = {
  name: "",
  storage: localStorage,
  init(name, storage) {
    this.name = name;

    if (storage) {
      this.storage = storage;
    }

    return this;
  },
  parseJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return str;
    }
    return JSON.parse(str);
  },
  get() {
    const storage = this.storage.getItem(this.name);

    if (!storage) {
      return null;
    }

    const storageObj = this.parseJson(storage);

    if (typeof storageObj === "string") {
      return storageObj;
    }

    if (storageObj.expired && Date.now() > storageObj.expired) {
      this.remove();
      return null;
    }

    return storageObj.value;
  },
  set(value, expired) {
    let expiredMilliseconds = null;

    if (expired && typeof expired === "number") {
      expiredMilliseconds = Date.now() + expired * 1000;
    }

    const storageObj = {
      value,
      expired: expiredMilliseconds,
    };

    this.storage.setItem(this.name, JSON.stringify(storageObj));
    return this;
  },
  remove() {
    this.storage.removeItem(this.name);
    return this;
  },
};

const types = {
  REPORTS_VIEW_MODE: "reportsViewMode",
  REDIRECT_ROUTE: "redirectRoute",
  USER_PHOTO: "userPhoto",
};

export const ReportsViewMode = Object.create(StorageObject).init(types.REPORTS_VIEW_MODE);
export const RedirectRoute = Object.create(StorageObject).init(types.REDIRECT_ROUTE, sessionStorage);
export const UserPhoto = Object.create(StorageObject).init(types.USER_PHOTO);
