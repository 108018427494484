export const MAX_FILE_SIZE = 200 * 1024 * 1024;
export const DEFAULT_FILE_CHUNK_SIZE = 10 * 1024 * 1024;
export const ACCEPTABLE_FILE_CHUNK_SIZE = 4 * 1024 * 1024;

export const CERTIFIED_MARK_TOOLTIP = "Certified by BI COE";

export const REPORT_VERSIONS_SELECTOR = [
  "DescriptionBI",
  "UserDefinedModificationDate",
  "ReportName",
  "OrderNo",
  "Owner",
  "ViewOnlyUsers",
  "ReadOnlyUsers",
  "Modifiers",
  "Contributors",
  "ID",
  "Certified",
  "KBLink",
  "ReportVideoGuide",
  "ReportLink",
  "TagsBI",
  "PowerBI_ID",
  "Editor",
  "Modified",
  "VersionLabel",
  "PrimaryRoles",
];

export const ALL_REPORTS_SELECTOR = [
  "ReportName",
  "DescriptionBI",
  "TagsBI",
  "UserDefinedModificationDate",
  "OrderNo",
  "ReportVideoGuide",
  "KBLink",
  "ReportLink",
  "Owner",
  "Contributors",
  "ViewOnlyUsers",
  "Modifiers",
  "ReadOnlyUsers",
  "PowerBI_ID",
  "FileRef",
  "FileLeafRef",
  "UniqueId",
  "Certified",
  "PrimaryRoles",
];
