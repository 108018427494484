import { ITerm } from "@pnp/sp-taxonomy";

import TagsRepository from "SP/tags/tags.repository";
import { ITag, ITagDTO, ITagWithGroup } from "SP/tags/tags.types";

export default class TagsService {
  private repository = new TagsRepository();

  // user role -> role
  // domain -> domain
  // usage frequency -> metric
  // source system -> application
  groups = {
    "User Role": "Role",
    Domain: "Domain",
    "Usage frequency": "Metric",
    "Source System": "Application",
  };

  defineGroup = (tag: ITag, pathOfTerm: string): ITagWithGroup | null => {
    const path = pathOfTerm.split(";");
    const parentTerm = this.groups[path[path.length - 2]];
    if (parentTerm) {
      return {
        ...tag,
        group: parentTerm,
      };
    }
    return null;
  };

  mapTags = (tags: ITagDTO[]): ITagWithGroup[] => {
    const groupedTags = tags.map((tag) => {
      return this.defineGroup(
        {
          name: tag.Name,
          id: tag.Id,
          description: tag.Description,
        },
        tag.PathOfTerm,
      );
    });
    return groupedTags.filter((tag) => !!tag);
  };

  async getTagById(tagId: string): Promise<ITerm> {
    return await this.repository.getById(tagId);
  }

  async getAll(): Promise<ITagWithGroup[]> {
    const tagsFromRepo = await this.repository.getAll();
    return this.mapTags(tagsFromRepo);
  }
}
