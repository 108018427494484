import _omit from "lodash/omit";
import qs from "qs";
import React, { FC, useEffect } from "react";

import { svc } from "@App/services";
import { Button, FlexSpacer, Spinner } from "@epam/loveship";
import type { IModal } from "@epam/uui";

import Modal from "Components/modal";
import withNaming from "Helpers/bemClassname";
import { useMobileData } from "Hooks/useMobile";
import { useQuery } from "Hooks/useQuery";

import { useReportVideoGuide } from "./video-guide-modal.hooks";

import "./video-guide-modal.scss";

interface IVideoGuideModalModalProps {
  reportId: number;
  modalProps: IModal<string>;
}

const videoGuideQueryKey = "videoGuideReportId";

export const getVideoGuideReportId = (search: string): number => {
  const { [videoGuideQueryKey]: reportId } = qs.parse(search, { ignoreQueryPrefix: true });

  return +reportId;
};

export const handleOpenVideoGuideModal = async (reportId: number) => {
  return svc.uuiModals.show((modalProps) => <VideoGuideModal modalProps={modalProps} reportId={reportId} />);
};

export const VideoGuideModal: FC<IVideoGuideModalModalProps> = ({ reportId, modalProps }) => {
  const cn = withNaming("video-guide-modal");
  const { getQuery, setQuery } = useQuery();
  const { isMobile } = useMobileData();
  const { loading, reportVideoGuide, getReportVideoGuide } = useReportVideoGuide();

  const handleClose = () => {
    const newQuery = _omit(getQuery(), videoGuideQueryKey);
    setQuery(null, newQuery);
    modalProps.abort();
  };

  useEffect(() => {
    if (reportId) {
      const oldQuery = getQuery();
      setQuery(null, { ...oldQuery, [videoGuideQueryKey]: reportId });
      getReportVideoGuide(reportId);
    }
  }, [reportId]);

  return (
    <Modal
      titleBorderBottom
      disallowClickOutside
      blockerShadow="dark"
      className={cn()}
      onClose={handleClose}
      title={`Report Video Guide - ${reportVideoGuide?.reportName ? reportVideoGuide?.reportName : ""}`}
      footer={
        <>
          <FlexSpacer />
          <Button cx="btn btn--ghost" color="night500" fill="white" onClick={handleClose} caption="Close" />
        </>
      }
      modalProps={modalProps}
      width={isMobile ? 500 : 1000}
    >
      {loading ? (
        <Spinner />
      ) : (
        <iframe
          src={reportVideoGuide?.videoGuideLink}
          height="500"
          width={isMobile ? "500" : "1000"}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )}
    </Modal>
  );
};
