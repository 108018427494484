import {
  IFile,
  IReportEditSavePayload,
  IReportExtended,
  IReportPermissions,
  IReportVersion,
  IReportVideoGuide,
} from "SP/reports/reports.types";

export enum ReportActionTypes {
  GET_REPORT_REQUEST = "[REPORT] GET REPORT REQUEST",
  GET_REPORT_SUCCESS = "[REPORT] GET REPORT SUCCESS",
  GET_REPORT_FAILURE = "[REPORT] GET REPORT FAILURE",

  GET_REPORT_FILE_URL_REQUEST = "[REPORT] GET REPORT FILE URL REQUEST",
  GET_REPORT_FILE_URL_SUCCESS = "[REPORT] GET REPORT FILE URL SUCCESS",
  GET_REPORT_FILE_URL_FAILURE = "[REPORT] GET REPORT FILE URL FAILURE",

  EDIT_REPORT_REQUEST = "[REPORT] EDIT REPORT REQUEST",
  EDIT_REPORT_SUCCESS = "[REPORT] EDIT REPORT SUCCESS",
  EDIT_REPORT_FAILURE = "[REPORT] EDIT REPORT FAILURE",

  CREATE_REPORT_REQUEST = "[REPORT] CREATE REPORT REQUEST",
  CREATE_REPORT_SUCCESS = "[REPORT] CREATE REPORT SUCCESS",
  CREATE_REPORT_FAILURE = "[REPORT] CREATE REPORT FAILURE",

  DELETE_REPORT_REQUEST = "[REPORT] DELETE REPORT REQUEST",
  DELETE_REPORT_SUCCESS = "[REPORT] DELETE REPORT SUCCESS",
  DELETE_REPORT_FAILURE = "[REPORT] DELETE REPORT FAILURE",

  GET_REPORT_VIDEO_GUIDE_REQUEST = "[REPORT] GET REPORT VIDEO GUIDE REQUEST",
  GET_REPORT_VIDEO_GUIDE_SUCCESS = "[REPORT] GET REPORT VIDEO GUIDE SUCCESS",
  GET_REPORT_VIDEO_GUIDE_FAILURE = "[REPORT] GET REPORT VIDEO GUIDE FAILURE",

  GET_REPORT_VERSION_HISTORY_REQUEST = "[REPORT] GET REPORT VERSION HISTORY REQUEST",
  GET_REPORT_VERSION_HISTORY_SUCCESS = "[REPORT] GET REPORT VERSION HISTORY SUCCESS",
  GET_REPORT_VERSION_HISTORY_FAILURE = "[REPORT] GET REPORT VERSION HISTORY FAILURE",

  RESET = "[REPORT] RESET",
}

export interface IGetReportRequestAction {
  type: ReportActionTypes.GET_REPORT_REQUEST;
  reportIdentifier: string;
}

export function getReportRequest(reportIdentifier: string): IGetReportRequestAction {
  return {
    type: ReportActionTypes.GET_REPORT_REQUEST,
    reportIdentifier,
  };
}

export interface IGetReportSuccessAction {
  type: ReportActionTypes.GET_REPORT_SUCCESS;
  payload: IReportExtended;
  permissions: IReportPermissions;
}

export function getReportSuccess(payload: IReportExtended, permissions: IReportPermissions): IGetReportSuccessAction {
  return {
    type: ReportActionTypes.GET_REPORT_SUCCESS,
    payload,
    permissions,
  };
}

export interface IGetReportFailureAction {
  type: ReportActionTypes.GET_REPORT_FAILURE;
  payload: string;
}

export function getReportFailure(error: string): IGetReportFailureAction {
  return {
    type: ReportActionTypes.GET_REPORT_FAILURE,
    payload: error,
  };
}

export interface IGetReportFileUrlRequestAction {
  type: ReportActionTypes.GET_REPORT_FILE_URL_REQUEST;
  id: number;
}

export function getReportFileUrlRequest(id: number): IGetReportFileUrlRequestAction {
  return {
    type: ReportActionTypes.GET_REPORT_FILE_URL_REQUEST,
    id,
  };
}

export interface IGetReportFileUrlSuccessAction {
  type: ReportActionTypes.GET_REPORT_FILE_URL_SUCCESS;
  payload: {
    file: IFile;
    id: number;
  };
}

export function getReportFileUrlSuccess(file: IFile, id: number): IGetReportFileUrlSuccessAction {
  return {
    type: ReportActionTypes.GET_REPORT_FILE_URL_SUCCESS,
    payload: {
      file,
      id,
    },
  };
}

export interface IGetReportFileUrlFailureAction {
  type: ReportActionTypes.GET_REPORT_FILE_URL_FAILURE;
  payload: string;
}

export function getReportFileUrlFailure(error: string): IGetReportFileUrlFailureAction {
  return {
    type: ReportActionTypes.GET_REPORT_FILE_URL_FAILURE,
    payload: error,
  };
}

export interface ICreateReportRequestAction {
  type: ReportActionTypes.CREATE_REPORT_REQUEST;
  reportMode: number;
  payload: IReportEditSavePayload;
  callback?: (reportName: string) => void;
}

export function handleCreateReport(
  reportMode: number,
  report: IReportEditSavePayload,
  callback?: (reportName: string) => void,
): ICreateReportRequestAction {
  return {
    type: ReportActionTypes.CREATE_REPORT_REQUEST,
    reportMode: reportMode,
    payload: report,
    callback,
  };
}

export interface ICreateReportSuccessAction {
  type: ReportActionTypes.CREATE_REPORT_SUCCESS;
}

export function createReportSuccess(): ICreateReportSuccessAction {
  return {
    type: ReportActionTypes.CREATE_REPORT_SUCCESS,
  };
}

export interface ICreateReportFailureAction {
  type: ReportActionTypes.CREATE_REPORT_FAILURE;
  payload: string;
}

export function createReportFailure(error: string): ICreateReportFailureAction {
  return {
    type: ReportActionTypes.CREATE_REPORT_FAILURE,
    payload: error,
  };
}

export interface IEditReportRequestAction {
  type: ReportActionTypes.EDIT_REPORT_REQUEST;
  id: number;
  payload: IReportEditSavePayload;
  callback?: (reportName: string) => void;
}

export function editReportRequest(
  id: number,
  payload: IReportEditSavePayload,
  callback?: (reportName: string) => void,
): IEditReportRequestAction {
  return {
    type: ReportActionTypes.EDIT_REPORT_REQUEST,
    id,
    payload,
    callback,
  };
}

export interface IEditReportSuccessAction {
  type: ReportActionTypes.EDIT_REPORT_SUCCESS;
}

export function editReportSuccess(): IEditReportSuccessAction {
  return {
    type: ReportActionTypes.EDIT_REPORT_SUCCESS,
  };
}

export interface IEditReportFailureAction {
  type: ReportActionTypes.EDIT_REPORT_FAILURE;
  payload: string;
}

export function editReportFailure(error: string): IEditReportFailureAction {
  return {
    type: ReportActionTypes.EDIT_REPORT_FAILURE,
    payload: error,
  };
}

export interface IDeleteReportRequestAction {
  type: ReportActionTypes.DELETE_REPORT_REQUEST;
  reportId: number;
  callback?: () => void;
}

export function handleDeleteReport(id: number, callback?: () => void): IDeleteReportRequestAction {
  return {
    type: ReportActionTypes.DELETE_REPORT_REQUEST,
    reportId: id,
    callback,
  };
}

export interface IDeleteReportSuccessAction {
  type: ReportActionTypes.DELETE_REPORT_SUCCESS;
  reportId: number;
}

export function deleteReportSuccess(id: number): IDeleteReportSuccessAction {
  return {
    type: ReportActionTypes.DELETE_REPORT_SUCCESS,
    reportId: id,
  };
}

export interface IDeleteReportFailureAction {
  type: ReportActionTypes.DELETE_REPORT_FAILURE;
}

export function deleteReportFailure(): IDeleteReportFailureAction {
  return {
    type: ReportActionTypes.DELETE_REPORT_FAILURE,
  };
}

// GET REPORT VIDEO GUIDE START
export interface IGetReportVideoGuideRequestAction {
  type: ReportActionTypes.GET_REPORT_VIDEO_GUIDE_REQUEST;
  reportId: number;
}

export function getReportVideoGuide(reportId: number): IGetReportVideoGuideRequestAction {
  return {
    type: ReportActionTypes.GET_REPORT_VIDEO_GUIDE_REQUEST,
    reportId,
  };
}

export interface IGetReportVideoGuideSuccessAction {
  type: ReportActionTypes.GET_REPORT_VIDEO_GUIDE_SUCCESS;
  reportVideoGuide: IReportVideoGuide;
}

export function getReportVideoGuideSuccess(reportVideoGuide: IReportVideoGuide): IGetReportVideoGuideSuccessAction {
  return {
    type: ReportActionTypes.GET_REPORT_VIDEO_GUIDE_SUCCESS,
    reportVideoGuide,
  };
}

export interface IGetReportVideoGuideFailureAction {
  type: ReportActionTypes.GET_REPORT_VIDEO_GUIDE_FAILURE;
  payload: string;
}

export function getReportVideoGuideFailure(error: string): IGetReportVideoGuideFailureAction {
  return {
    type: ReportActionTypes.GET_REPORT_VIDEO_GUIDE_FAILURE,
    payload: error,
  };
}
// GET REPORT VIDEO GUIDE END

// GET REPORT VERSION HISTORY START
export interface IGetReportVersionHistoryRequestAction {
  type: ReportActionTypes.GET_REPORT_VERSION_HISTORY_REQUEST;
  reportId: number;
}

export function getReportVersionHistory(reportId: number): IGetReportVersionHistoryRequestAction {
  return {
    type: ReportActionTypes.GET_REPORT_VERSION_HISTORY_REQUEST,
    reportId,
  };
}

export interface IGetReportVersionHistorySuccessAction {
  type: ReportActionTypes.GET_REPORT_VERSION_HISTORY_SUCCESS;
  versions: IReportVersion[];
}

export function getReportVersionHistorySuccess(versions: IReportVersion[]): IGetReportVersionHistorySuccessAction {
  return {
    type: ReportActionTypes.GET_REPORT_VERSION_HISTORY_SUCCESS,
    versions,
  };
}

export interface IGetReportVersionHistoryFailureAction {
  type: ReportActionTypes.GET_REPORT_VERSION_HISTORY_FAILURE;
  payload: string;
}

export function getReportVersionHistoryFailure(error: string): IGetReportVersionHistoryFailureAction {
  return {
    type: ReportActionTypes.GET_REPORT_VERSION_HISTORY_FAILURE,
    payload: error,
  };
}
// GET REPORT VERSION HISTORY END

export interface IResetReportAction {
  type: ReportActionTypes.RESET;
}

export function resetSingleReport(): IResetReportAction {
  return {
    type: ReportActionTypes.RESET,
  };
}

export type IReportActionTypes =
  | ICreateReportRequestAction
  | ICreateReportSuccessAction
  | ICreateReportFailureAction
  | IDeleteReportRequestAction
  | IDeleteReportSuccessAction
  | IDeleteReportFailureAction
  | IGetReportRequestAction
  | IGetReportSuccessAction
  | IGetReportFailureAction
  | IGetReportFileUrlRequestAction
  | IGetReportFileUrlSuccessAction
  | IGetReportFileUrlFailureAction
  | IEditReportRequestAction
  | IEditReportSuccessAction
  | IEditReportFailureAction
  | IGetReportVideoGuideRequestAction
  | IGetReportVideoGuideSuccessAction
  | IGetReportVideoGuideFailureAction
  | IGetReportVersionHistoryRequestAction
  | IGetReportVersionHistorySuccessAction
  | IGetReportVersionHistoryFailureAction
  | IResetReportAction;
