import React, { useEffect, useRef, useState } from "react";

import { Text } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import type { IHighlightItem } from "Helpers/highlight";

import "./style.scss";

interface IExpandableTextProps {
  content: IHighlightItem;
  maxHeight: number | string;
  cx?: string;
}

const ExpandableText: React.FC<IExpandableTextProps> = ({ content, cx, maxHeight }) => {
  const [isOpen, setOpen] = useState(false);
  const [isOverflow, setOverflow] = useState(true);

  const textContainerRef = useRef(null);

  useEffect(() => {
    setOverflow(maxHeight <= textContainerRef.current.firstChild.scrollHeight);
  });

  const cn = withNaming("expanded-text");
  return (
    <div ref={textContainerRef} className={cn("", { isClosed: isOverflow && !isOpen })}>
      <Text cx={cn("content", [cx])}>{content}</Text>
      {isOverflow ? (
        <Text
          cx={cn("action-button", { expand: !isOpen }, [cx])}
          fontSize="14"
          lineHeight="18"
          onClick={() => setOpen((v) => !v)}
        >
          ... {isOpen ? "less" : "more"}
        </Text>
      ) : null}
    </div>
  );
};

export default ExpandableText;
