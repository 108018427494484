import _isEmpty from "lodash/isEmpty";

import {
  ISearchDictionary,
  ISearchDictionaryGroup,
  ISearchDictionaryItemDTO,
  SearchDictionaryGroupNames,
} from "SP/search/search.types";

import SearchRepository from "./search.repository";

export default class SearchService {
  private static repository = new SearchRepository();
  private static mappedDictionaryGroup: ISearchDictionaryGroup = {
    reportNameDictionary: {},
    descriptionDictionary: {},
  };

  private static mapSearchDictionary(items: ISearchDictionaryItemDTO[]): ISearchDictionaryGroup {
    const dictionary: Record<SearchDictionaryGroupNames, ISearchDictionary> = {
      ReportName: {},
      Description: {},
    };

    function addSynonymsToGroup(group: SearchDictionaryGroupNames[], word: string, synonyms: string[]) {
      group.forEach((groupName) => {
        dictionary[groupName][word] = synonyms;
      });
    }

    items.forEach((item) => {
      const word = item.Word.toLowerCase().trim();
      const synonyms = item.Synonyms.toLowerCase()
        .split(",")
        .map((w) => w.trim());

      addSynonymsToGroup(item.Group, word, synonyms);
      synonyms.forEach((synonym) => {
        const newSynonyms = [word, ...synonyms.filter((s) => s !== synonym)];

        addSynonymsToGroup(item.Group, synonym, newSynonyms);
      });
    });

    return {
      reportNameDictionary: dictionary.ReportName,
      descriptionDictionary: dictionary.Description,
    };
  }

  public static get CachedDictionary(): ISearchDictionaryGroup {
    return this.mappedDictionaryGroup;
  }

  public static isDictionaryNotLoaded(): boolean {
    return (
      _isEmpty(this.mappedDictionaryGroup.reportNameDictionary) &&
      _isEmpty(this.mappedDictionaryGroup.descriptionDictionary)
    );
  }

  public static async getSearchDictionary(): Promise<ISearchDictionaryGroup> {
    if (this.isDictionaryNotLoaded()) {
      const dictionaryGroup = await this.repository.loadSearchDictionary();
      this.mappedDictionaryGroup = this.mapSearchDictionary(dictionaryGroup);
    }

    return this.mappedDictionaryGroup;
  }
}
