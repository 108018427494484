import produce from "immer";
import _omit from "lodash/omit";
import _sortBy from "lodash/sortBy";
import _uniqBy from "lodash/uniqBy";
import { Reducer } from "redux";

import { updateObject } from "Helpers/utils";
import { ReportsActionTypes } from "Pages/reports-browser/reports-browser.actions";
import { IUser, IUserExtended } from "SP/users/users.types";

import { IUsersActionsTypes, UsersActionsTypes } from "../actions/users.actions";

export interface IUsersState {
  allUsers: IUser[];
  currentUser: IUserExtended;
  loading: boolean;
}

const initialState: IUsersState = {
  allUsers: [],
  currentUser: null,
  loading: false,
};

function moveUserToTheTop(users: IUser[], userIndex: number) {
  const user = users[userIndex];
  users.splice(userIndex, 1);
  users.unshift(user);
}

function handleUsers(draft: IUsersState, newUsers: IUser[]) {
  const uniqByIdUsers = _uniqBy([...draft.allUsers, ...newUsers], (user) => user.id);
  const sortedUsers = _sortBy(uniqByIdUsers, ["name"]);
  const currentUserIndex = sortedUsers.findIndex((user) => user.id === draft.currentUser?.id);

  // To make sure current user always shows as first item in 'Owner' picker input
  if (currentUserIndex > 0) {
    moveUserToTheTop(sortedUsers, currentUserIndex);
  }

  draft.allUsers = sortedUsers;
}

const usersReducer: Reducer<IUsersState, IUsersActionsTypes> = produce((draft, action) => {
  switch (action.type) {
    case UsersActionsTypes.GET_USERS_BY_IDS_REQUEST:
    case UsersActionsTypes.GET_USERS_BY_NAMES_REQUEST:
    case UsersActionsTypes.SEARCH_USERS_REQUEST:
    case UsersActionsTypes.CHECK_EMAILS_REQUEST:
      draft.loading = true;
      break;
    case UsersActionsTypes.GET_USERS_BY_IDS_SUCCESS:
    case UsersActionsTypes.GET_USERS_BY_NAMES_SUCCESS:
    case UsersActionsTypes.SEARCH_USERS_SUCCESS:
    case UsersActionsTypes.CHECK_EMAILS_SUCCESS:
      handleUsers(draft, action.payload);
      draft.loading = false;
      break;
    case UsersActionsTypes.GET_USERS_BY_IDS_FAILURE:
    case UsersActionsTypes.GET_USERS_BY_NAMES_FAILURE:
    case UsersActionsTypes.SEARCH_USERS_FAILURE:
    case UsersActionsTypes.CHECK_EMAILS_FAILURE:
      draft.loading = false;
      break;

    case UsersActionsTypes.GET_CURRENT_USER_SUCCESS:
      draft.currentUser = action.payload;
      handleUsers(draft, [_omit(action.payload, ["permissions"])]);
      break;

    case UsersActionsTypes.UPDATE_CURRENT_USER:
      draft.currentUser = updateObject(draft.currentUser, action.currentUserUpdate);
      break;

    case ReportsActionTypes.SET_ALL_REPORTS_OWNERS:
      handleUsers(draft, action.payload);
      break;
  }
}, initialState);

export default usersReducer;
