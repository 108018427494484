import React from "react";
import { RouteComponentProps } from "react-router-dom";

import withPageHead from "Components/page-head/with-page-head";
import { RedirectRoute } from "Helpers/storage";

import AddReport from "./pages/add-report";
import { ArtifactVersion } from "./pages/artifact-version";
import Dashboard from "./pages/dashboard";
import Report from "./pages/report";
import ReportsBrowser from "./pages/reports-browser";

export interface IRoute {
  path: string;
  pageName: string;
  protected?: boolean;
  permissionKey?: string;
  props: {
    component: React.FC<RouteComponentProps>;
    exact?: boolean;
    routes?: IRoute[];
  };
}

interface IRoutes {
  ADD_REPORT: IRoute;
  REPORT_EDIT: IRoute;
  REPORT: IRoute;
  REPORTS_BROWSER: IRoute;
  HOME: IRoute;
  ARTIFACT_VERSION: IRoute;
}

const Routes: IRoutes = {
  ADD_REPORT: {
    path: "/add-report",
    pageName: "Add Report",
    protected: true,
    permissionKey: "users.currentUser.permissions.canAddReport",
    props: {
      component: withPageHead(AddReport, "Add Report"),
      exact: true,
    },
  },
  REPORT_EDIT: {
    path: "/reports/:reportName/edit",
    pageName: "Report Edit",
    protected: true,
    permissionKey: "report.permissions.canEdit",
    props: {
      component: withPageHead(AddReport, "Edit Report"),
      exact: true,
    },
  },
  REPORT: {
    path: "/reports/:reportName",
    pageName: "Report",
    props: {
      component: withPageHead(Report, "View Report"),
      exact: true,
    },
  },
  REPORTS_BROWSER: {
    path: "/reports",
    pageName: "Reports Browser",
    props: {
      component: withPageHead(ReportsBrowser, "All reports"),
      exact: true,
    },
  },
  HOME: {
    path: "/",
    pageName: "Dashboard",
    props: {
      component: withPageHead(Dashboard, "Dashboard"),
      exact: true,
    },
  },
  ARTIFACT_VERSION: {
    path: "/sys/version",
    pageName: "Artifact version",
    props: {
      component: ArtifactVersion,
      exact: true,
    },
  },
};

function replaceReportIdentifier(path: string) {
  const baseUrlWords = ["add-report", "reports", "edit"];
  const [identifier] = path.split("/").filter((item) => item && !baseUrlWords.includes(item));

  if (identifier) {
    return path.replace(identifier, ":reportName");
  }

  return path;
}

export function isPathInRoutes(path: string): boolean {
  const newPath = replaceReportIdentifier(path);
  return Object.keys(Routes).some((routeKey) => Routes[routeKey].path === newPath);
}

export function setRedirectRoute(): void {
  if (isPathInRoutes(window.location.pathname)) {
    RedirectRoute.set({ pathname: window.location.pathname, search: window.location.search });
  } else {
    RedirectRoute.set("/");
  }
}

export default Routes;
