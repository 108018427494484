import { useDispatch, useSelector } from "react-redux";

import { setIsSynonymWordsShow } from "Store/actions/global.actions";
import { IRootReducerState } from "Store/reducers";

export const useSynonymWords = () => {
  const dispatch = useDispatch();
  const isSynonymsWordsShow = useSelector<IRootReducerState, boolean>((state) => state.global.isSynonymsWordsShow);

  const handleSetIsSynonymWordsShow = (toggle: boolean) => {
    dispatch(setIsSynonymWordsShow(toggle));
  };

  return { isSynonymsWordsShow, setIsSynonymWordsShow: handleSetIsSynonymWordsShow };
};
