import FavoritesRepository from "./favorites.repository";
import { IFavorite, IFavoriteDTO } from "./favorites.types";

class FavoritesService {
  repository = new FavoritesRepository();

  private mapFavorites = (favorite: IFavoriteDTO): IFavorite => {
    return {
      id: favorite.Id,
      reportId: favorite.DocIDId,
    };
  };

  async getUserFavorites(userId: number): Promise<IFavorite[]> {
    const favorites = await this.repository.getUserFavorites(userId);
    return favorites.map(this.mapFavorites);
  }

  async favoriteReport(reportId: number): Promise<IFavorite> {
    const { data } = await this.repository.favoriteReport(reportId);
    return this.mapFavorites(data);
  }

  async unfavoriteReport(favoriteId: number, userId: number): Promise<string> {
    return await this.repository.unfavoriteReport(favoriteId, userId);
  }
}

export default FavoritesService;
