import Tree, { TreeNodeProps } from "rc-tree";
import { DataNode, Key } from "rc-tree/lib/interface";
import React, { FC, useEffect, useRef, useState } from "react";

import { svc } from "@App/services";
import { ReactComponent as arrowExpanded } from "@epam/assets/icons/common/navigation-chevron-down-24.svg";
import { ReactComponent as arrowClosed } from "@epam/assets/icons/common/navigation-chevron-right-24.svg";
import { ReactComponent as loader } from "@epam/assets/icons/loaders/circle_loader_30 .svg";
import { Button, FlexSpacer, IconContainer, ScrollBars, Spinner } from "@epam/loveship";
import type { IModal } from "@epam/uui";

import Modal from "Components/modal";
import { useFoldersData, useFoldersMethods } from "Components/path-select-modal/path-select-modal.hooks";
import withNaming from "Helpers/bemClassname";
import { createTreePath } from "Helpers/foldersHelper";
import type { IFolder } from "SP/folders/folders.types";

import { ReactComponent as folderIcon } from "../../assets/folder.svg";

import "./path-select-modal.scss";

export interface ISelectedPath {
  key: string;
  destinationUrl: string;
}
interface IUploadFileFieldPathSelectModalProps {
  onSave: (p: ISelectedPath) => void;
  onClose: () => void;
  modalProps: IModal<string>;
}

const PathSelectModal: FC<IUploadFileFieldPathSelectModalProps> = ({ onSave, onClose, modalProps }) => {
  const cn = withNaming("path-select-modal");
  const [selectedPath, setSelectedPath] = useState<ISelectedPath>(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [loadingKey, setLoadingKey] = useState("");
  const preventLoadKey = useRef("");

  const { rootFolders, loading } = useFoldersData();
  const { onGetSubFolders, onGetRootFolders, onClearFolders } = useFoldersMethods();

  const handleClose = () => {
    if (process.env.UNIT_TEST) {
      svc.uuiModals.closeAll();
    } else {
      onClearFolders();
      modalProps.abort();
    }
  };

  const handleSave = () => {
    onSave(selectedPath);
    handleClose();
  };

  const handleCancel = () => {
    onClose();
    handleClose();
  };

  const onSelect = (selectedKeys: Key[], b) => {
    const folderKey = selectedKeys[0];

    setSelectedPath({
      key: folderKey as string,
      destinationUrl: createTreePath(rootFolders[0], folderKey),
    });
  };

  const onLoadData = async (treeNode: DataNode): Promise<void> => {
    const treeNodeKey = treeNode.key as string;

    if (preventLoadKey.current !== treeNodeKey && !treeNode.children) {
      preventLoadKey.current = treeNodeKey;

      setLoadingKey(treeNodeKey);
      onGetSubFolders(treeNode.key.toString(), () => setLoadingKey(""));
    }
  };

  useEffect(() => {
    function getRootFolders(rootFolders: IFolder[]) {
      setExpandedKeys([rootFolders[0].key]);
    }

    onGetRootFolders(getRootFolders);
  }, []);

  const renderIcon = (treeNode: TreeNodeProps) => {
    if (loading && treeNode.eventKey === loadingKey) {
      return <IconContainer cx={cn("loader-icon")} icon={loader} />;
    }
    return <IconContainer cx={cn("folder-icon")} icon={folderIcon} />;
  };

  const renderSwitcherIcon = (treeNode: TreeNodeProps) => {
    if (treeNode.isLeaf) {
      return null;
    }
    if (treeNode.expanded) {
      return <IconContainer cx={cn("arrow-icon")} icon={arrowExpanded} />;
    }
    return <IconContainer cx={cn("arrow-icon")} icon={arrowClosed} />;
  };

  return (
    <Modal
      disallowClickOutside
      blockerShadow="dark"
      className={cn()}
      title="Folder selection"
      onClose={onClose}
      footer={
        <>
          <FlexSpacer />
          <Button cx="btn btn--ghost" color="night500" fill="white" onClick={handleCancel} caption="Cancel" />
          <Button cx="btn btn--grass" isDisabled={!selectedPath} color="grass" caption="Save" onClick={handleSave} />
        </>
      }
      modalProps={modalProps}
      width={480}
    >
      {rootFolders.length === 0 && <Spinner />}
      <ScrollBars>
        <Tree
          treeData={rootFolders}
          loadData={onLoadData}
          expandedKeys={expandedKeys}
          onExpand={setExpandedKeys}
          onSelect={onSelect}
          switcherIcon={renderSwitcherIcon}
          icon={renderIcon}
          disabled={loading}
          motion={{
            motionName: "node-motion",
            motionAppear: false,
            onAppearStart: () => ({ height: 0 }),
            onAppearActive: (node) => ({ height: node.scrollHeight }),
            onLeaveStart: (node) => ({ height: node.offsetHeight }),
            onLeaveActive: () => ({ height: 0 }),
          }}
        />
      </ScrollBars>
    </Modal>
  );
};

export default PathSelectModal;
