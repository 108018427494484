import _sortBy from "lodash/sortBy";

import type { ITagSelectOption } from "Hooks/useTags";

export type IBadge = ITagSelectOption & { thumbUp?: boolean };

const LABEL_FILED = "label";

export const REPORT_LABELS = {
  viewVideoTutorial: "View video tutorial",
  linkToKB: "Link to KB",
  linkToTheReport: "Link to the report",
  copyLink: "Copy link",
};

export const handleRoles = (roles: ITagSelectOption[], primaryRoles: string[]): IBadge[] => [
  ..._sortBy(
    roles.filter((role) => primaryRoles.includes(role.id)).map((role) => ({ ...role, thumbUp: true })),
    [LABEL_FILED],
  ),
  ..._sortBy(
    roles.filter((role) => !primaryRoles.includes(role.id)),
    [LABEL_FILED],
  ),
];
