import React from "react";
import { Helmet } from "react-helmet";

interface IPageHeadProps {
  page: string;
}

const PageHead: React.FC<IPageHeadProps> = ({ page }) => (
  <Helmet>
    <title>EPAM Reports | {page}</title>
  </Helmet>
);

export default PageHead;
