import { ApmBase, init as initApm } from "@elastic/apm-rum";

import { getServerRelativeUrl } from "Helpers/utils";

enum ENVS {
  prod = "production",
  preprod = "preprod",
  qa = "qa",
  dev = "development",
}

const elasticConfig = {
  project: "epm-rprt",
  serviceType: "ui",
  serviceName: "sp-reports-ui",
};

const getEnvironment = (): ENVS => {
  const ServerRelativeUrl = getServerRelativeUrl();

  if (process.env.NODE_ENV === ENVS.dev) {
    return ENVS.dev;
  } else if (ServerRelativeUrl.endsWith(ENVS.qa)) {
    return ENVS.qa;
  } else if (ServerRelativeUrl.endsWith(ENVS.preprod)) {
    return ENVS.preprod;
  } else if (ServerRelativeUrl.endsWith("reports")) {
    return ENVS.prod;
  }
};

export let elasticAPM: ApmBase = null;

export function configureElasticAPM(): void {
  elasticAPM = initApm({
    serverUrl: process.env.REACT_APP_ELASTIC_APM_URL,
    environment: getEnvironment(),
    serviceName: elasticConfig.serviceName,
    serviceVersion: process.env.REACT_APP_VERSION,
    transactionSampleRate: 0.2,
    breakdownMetrics: true,
    customPageLoadDelay: 7000,
  });

  elasticAPM.addLabels({ project: elasticConfig.project, service_type: elasticConfig.serviceType });
  elasticAPM.setInitialPageLoadName("EPAM Reports | Dashboard");
}
