import { svc } from "@App/services";
import { NotificationOperation, NotificationParams } from "@epam/uui";

import { notificationTypes } from "Store/actions/notifications.actions";

interface INotificationParamsExtended extends NotificationParams {
  type: notificationTypes;
}

interface INotificationOperationExtended extends NotificationOperation {
  config: INotificationParamsExtended;
}

export const closeNotificationsByType = (type: notificationTypes): void => {
  const notifications = svc.uuiNotifications.getNotifications() as INotificationOperationExtended[];

  notifications.forEach((notification) => {
    if (notification.config.type === type) {
      notification.props.onClose();
    }
  });
};
