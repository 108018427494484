import React, { FC } from "react";

import { Select } from "Components/select";
import type { ISelectOption } from "Components/select/select.types";
import { EmptyRC } from "Helpers/utils";

interface IReportBrowsersFilterOwnersProps {
  selectedOwners: number[];
  ownerOptions: ISelectOption[];
  onSetSelectedOwners: (values: number[]) => void;
}

export const ReportBrowsersFilterOwners: FC<IReportBrowsersFilterOwnersProps> = ({
  ownerOptions,
  selectedOwners,
  onSetSelectedOwners,
}) => {
  return (
    <Select<number>
      inputProps={{
        size: "30",
        dropdownPlacement: "bottom-end",
        minBodyWidth: 300,
      }}
      value={selectedOwners}
      onValueChange={onSetSelectedOwners}
      options={ownerOptions}
      placeholder="Please select Owners"
      renderFooter={EmptyRC}
    />
  );
};
