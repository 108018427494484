import CamlBuilder, { ViewScope } from "camljs";

import { sp } from "@pnp/sp";

import { getServerRelativeUrl } from "Helpers/utils";
import {
  IDestinationUrls,
  IFile,
  IGetRoleAssignmentUsersParams,
  IReport,
  IReportDTO,
  IReportEditSavePayload,
  IReportFields,
  IReportPermissionGroups,
  IRoleAssignmentUsers,
  IStreamReportDTO,
} from "SP/reports/reports.types";

export const addGuidLabel = (id: string) => `/Guid(${id})/`;

export const removeGuidLabel = (id: string) => id.replace(/^\/Guid\((.+)\)\/$/, "$1");

export const convertTagIdsToTagsInReport = (report: IReportEditSavePayload): Omit<IReport, "id"> => {
  const getTagsByIds = (tagIds: string[]) => {
    return tagIds.map((tagId) => removeGuidLabel(tagId));
  };

  const tags = [
    ...getTagsByIds(report.applications),
    ...getTagsByIds(report.roles),
    ...getTagsByIds(report.metrics),
    ...getTagsByIds(report.domains),
  ];

  delete report.domains;
  delete report.metrics;
  delete report.roles;
  delete report.applications;

  return {
    ...report,
    tags,
  };
};

export const addPrefixIfNeeded = (originalUrl: string): string => {
  const ServerRelativeUrl = getServerRelativeUrl();
  const isAdd = originalUrl.indexOf(ServerRelativeUrl) === 0;
  return isAdd ? ServerRelativeUrl : "";
};

const clearPrefixIfNeeded = (url: string): string => {
  const ServerRelativeUrl = getServerRelativeUrl();
  return url.replace(ServerRelativeUrl, "");
};

export const getFileName = (fileUrl: string): string => {
  return fileUrl.split("/").slice(-1)[0];
};

export const generateFolderPathByFileUrl = (fileUrl: string): string => {
  if (typeof fileUrl !== "string") return "";

  const newUrl = fileUrl.split("/").slice(0, -1).join("/");
  return clearPrefixIfNeeded(newUrl);
};

export const getDestinationUrls = (fileUrl: string, newUrl: string): IDestinationUrls => {
  const oldUrl = generateFolderPathByFileUrl(fileUrl);
  const isPathChanged = oldUrl !== newUrl;
  const fileName = getFileName(fileUrl);
  const newUrlPrefix = addPrefixIfNeeded(fileUrl);

  return isPathChanged ? { originalUrl: fileUrl, newUrl: `${newUrlPrefix}${newUrl}/${fileName}` } : null;
};

export const isReportHasUniquePermissions = (reportPayload: Omit<IReport, "id">): boolean => {
  return Object.keys(IReportPermissionGroups).some((k) => reportPayload[k].length > 0);
};

export const isRoleAssignmentsUsersEmpty = (reportPayload: Partial<IReportDTO>): boolean => {
  return Object.values(IReportPermissionGroups).every(
    (k) => (reportPayload[k] as { results: number[] }).results.length === 0,
  );
};

export const isRoleAssignmentsUsersExists = (reportPayload: Partial<IReportDTO>): boolean => {
  return !isRoleAssignmentsUsersEmpty(reportPayload);
};

export const checkIsReportUniqueByName = async (name: string): Promise<boolean> => {
  const baseQuery = new CamlBuilder().View().Scope(ViewScope.Recursive).RowLimit(1).Query();
  const ViewXml = baseQuery.Where().TextField(IReportFields.ReportName).EqualTo(name).ToString();

  const [report] = await sp.web.defaultDocumentLibrary.getItemsByCAMLQuery({ ViewXml });
  return !report;
};

export const getRoleAssignmentUsers = ({ payload, oldPayload }: IGetRoleAssignmentUsersParams) => {
  const removedUsers = {} as IRoleAssignmentUsers;
  const addedUsers = {} as IRoleAssignmentUsers;

  Object.values(IReportPermissionGroups).forEach((k) => {
    const newValue = payload[k] as { results: number[] };
    const oldValue = oldPayload[k] as { results: number[] };

    const removedItems = oldValue.results.filter((item) => !newValue.results.includes(item));
    const addedItems = newValue.results.filter((item) => !oldValue.results.includes(item));

    removedUsers[k] = { results: removedItems };
    addedUsers[k] = { results: addedItems };
  });

  return { removedUsers, addedUsers };
};

export const mapReportFile = (file): IFile => {
  return {
    name: file.Name,
    url: file.ServerRelativeUrl,
    uniqueId: file.UniqueId,
  };
};

export const mapStreamReportFile = (report: IStreamReportDTO): IFile => {
  return {
    name: report.FileLeafRef,
    url: report.FileRef,
    uniqueId: report.UniqueId,
  };
};
