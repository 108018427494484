import produce from "immer";
import _sortBy from "lodash/sortBy";
import _uniqBy from "lodash/uniqBy";
import { Reducer } from "redux";

import { ReportsViewMode } from "Helpers/storage";
import { ReportActionTypes } from "Pages/report/report.actions";
import type { IFile, IReportExtended } from "SP/reports/reports.types";
import type { IUser } from "SP/users/users.types";

import { IReportsActionTypes, IReportViewMode, ReportsActionTypes } from "./reports-browser.actions";

export interface IReportsFiles {
  [key: string]: IFile;
}

export interface IReportsState {
  allReports: IReportExtended[];
  filteredReports: IReportExtended[];
  isAllReportsLoaded: boolean;
  isFilteredReportsLoaded: boolean;
  loading: boolean;
  error: string | null;
  viewMode: IReportViewMode;
  reportsFiles: IReportsFiles;
  allReportsOwners: IUser[];
}

const initialState: IReportsState = {
  allReports: [],
  filteredReports: [],
  reportsFiles: {},
  isAllReportsLoaded: false,
  isFilteredReportsLoaded: false,
  loading: true,
  error: null,
  viewMode: (ReportsViewMode.get() as IReportViewMode) || "cards",
  allReportsOwners: [],
};

const handleUpdateReports = (reports: IReportExtended[], report: IReportExtended) => {
  const reportIndex = reports.findIndex((r) => r.id === report.id);

  if (reportIndex !== -1) {
    reports.splice(reportIndex, 1, report);
  } else {
    reports.push(report);
  }
};

const handleDeleteReport = (reports: IReportExtended[], reportId: number) => {
  const reportIndex = reports.findIndex((r) => r.id === reportId);

  if (reportIndex !== -1) {
    reports.splice(reportIndex, 1);
  }
};

const reportsReducer: Reducer<IReportsState, IReportsActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case ReportsActionTypes.GET_ALL_REPORTS_REQUEST:
      draft.isAllReportsLoaded = false;
      break;
    case ReportsActionTypes.GET_ALL_REPORTS_SUCCESS:
      draft.isAllReportsLoaded = true;
      draft.allReports = action.reports;
      break;
    case ReportsActionTypes.GET_ALL_REPORTS_FAILURE:
      draft.isAllReportsLoaded = true;
      draft.error = action.error;
      break;

    case ReportsActionTypes.GET_FILTERED_REPORTS_REQUEST:
      draft.loading = true;
      draft.isFilteredReportsLoaded = false;
      break;
    case ReportsActionTypes.GET_FILTERED_REPORTS_SUCCESS:
      draft.loading = false;
      draft.isFilteredReportsLoaded = true;
      draft.filteredReports = action.reports;
      break;
    case ReportsActionTypes.GET_FILTERED_REPORTS_FAILURE:
      draft.loading = false;
      draft.isFilteredReportsLoaded = true;
      draft.error = action.error;
      break;

    case ReportsActionTypes.CHANGE_VIEW_MODE:
      ReportsViewMode.set(action.payload);
      draft.viewMode = action.payload;
      break;

    case ReportActionTypes.GET_REPORT_FILE_URL_SUCCESS:
      draft.reportsFiles[action.payload.id] = action.payload.file;
      break;
    case ReportActionTypes.GET_REPORT_FILE_URL_FAILURE:
      draft.error = action.payload;
      break;

    case ReportActionTypes.GET_REPORT_SUCCESS:
      handleUpdateReports(draft.allReports, action.payload);
      handleUpdateReports(draft.filteredReports, action.payload);
      break;
    case ReportActionTypes.DELETE_REPORT_SUCCESS:
      handleDeleteReport(draft.allReports, action.reportId);
      handleDeleteReport(draft.filteredReports, action.reportId);
      break;

    case ReportsActionTypes.SET_ALL_REPORTS_OWNERS:
      const uniqByIdUsers = _uniqBy([...draft.allReportsOwners, ...action.payload], (user) => user.id);
      draft.allReportsOwners = _sortBy(uniqByIdUsers, ["name"]);
      break;
  }
}, initialState);

export default reportsReducer;
