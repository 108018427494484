import React, { FC } from "react";

import { PickerInput, Spinner } from "@epam/loveship";
import { useArrayDataSource } from "@epam/uui";

import withNaming from "Helpers/bemClassname";
import { dashboardDefaultRole, handleRolesData, IRole } from "Helpers/utils";
import { useAppSelector } from "Hooks/useAppSelector";

import { useDashboardReportsMethods } from "../dashboard.hooks";

import "./dashboard-domain-filters.scss";

export enum DashboardFilterTabs {
  certified = "certified",
  favorite = "favorite",
}

interface IDashboardDomainFiltersProps {
  roles: IRole[];
}

export function isNotDefaultFilterRole(filterRole: string): boolean {
  return !!filterRole && filterRole !== dashboardDefaultRole.id;
}

const DashboardDomainFilters: FC<IDashboardDomainFiltersProps> = ({ roles }) => {
  const filterRole = useAppSelector((state) => state.dashboard.filterRole);
  const loading = useAppSelector((state) => state.dashboard.loading);
  const { setFilterRole } = useDashboardReportsMethods();
  const cn = withNaming("dashboard-domain-filters");

  const dataSource = useArrayDataSource({
    items: handleRolesData(roles),
  });

  return (
    <div className={cn("", ["flex justify-end"])}>
      {loading && <Spinner cx={cn("spinner")} />}
      <PickerInput
        disableClear
        searchPosition="body"
        entityName="Role"
        minBodyWidth={278}
        dropdownPlacement="bottom-start"
        dataSource={dataSource}
        value={!loading && (filterRole || dashboardDefaultRole.id)}
        onValueChange={setFilterRole}
        getName={(item) => item.label}
        selectionMode="single"
        valueType={"id"}
      />
    </div>
  );
};

export default DashboardDomainFilters;
