import _isEmpty from "lodash/isEmpty";
import _omit from "lodash/omit";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { mapUserForSelectOption } from "Helpers/userHandler";
import { dashboardDefaultRole } from "Helpers/utils";
import { useGlobalSearch } from "Hooks/useGlobalSearch";
import { useFiltersQuery } from "Hooks/useQuery";
import { useTagsData } from "Hooks/useTags";
import { useAllUsersData } from "Hooks/useUsers";
import { useDashboardReportsData } from "Pages/dashboard/dashboard.hooks";
import { isAtLeastOneFilterPresent } from "Pages/reports-browser/reports-browser-filter/report-browsers-filter-sections";
import {
  IFilterTypes,
  setAllFilters,
  setFilterBy,
  setFilterOwners,
  setFilterTags,
  setFilterTimePeriod,
  setPreselectedRole,
} from "Pages/reports-browser/reports-filters.actions";
import type { ITimePeriod } from "Pages/reports-browser/reports-filters.reducer";
import type { IReport } from "SP/reports/reports.types";
import { ITagWithGroup, TagGroupUINames } from "SP/tags/tags.types";
import type { IUser } from "SP/users/users.types";
import type { IRootReducerState } from "Store/reducers";

const TAGS_FILTER_SECTION_ORDER = 2;

export const useFilters = () => {
  const { groupedTags, allTags } = useTagsData();
  const { getUsersByIds } = useAllUsersData();
  const { hasParams, query, setQueryByFilters } = useFiltersQuery();
  const handleSetQueryByFilters = (params) => {
    setQueryByFilters(params);
    dispatch(setPreselectedRole(""));
  };

  const { filterRole } = useDashboardReportsData();
  const allReportsOwners = useSelector<IRootReducerState, IUser[]>((state) => state.reports.allReportsOwners);
  const reports = useSelector<IRootReducerState, IReport[]>((state) => state.reports.filteredReports);
  const selectedTags = useSelector<IRootReducerState, Set<string>>((state) => state.filters.selectedTags);
  const selectedOwners = useSelector<IRootReducerState, number[]>((state) => state.filters.selectedOwners);
  const timePeriod = useSelector<IRootReducerState, ITimePeriod>((state) => state.filters.timePeriod);
  const preselectedRole = useSelector<IRootReducerState, string>((state) => state.filters.preselectedRole);
  const { searchText, setGlobalSearchText } = useGlobalSearch();

  const filterBy = useSelector<IRootReducerState, string[]>((state) => state.filters.filterBy);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!preselectedRole) {
      dispatch(setPreselectedRole(filterRole));
    }

    if (filterRole) {
      const prevPrimaryRoleId = groupedTags?.Role?.find(({ name }) => name === preselectedRole)?.id;
      const isOnlyPrevPrimaryRoleSelected = selectedTags.size === 1 && selectedTags.has(prevPrimaryRoleId);

      if (
        !hasParams &&
        !filterBy?.length &&
        !selectedOwners.length &&
        !timePeriod &&
        (!selectedTags.size || isOnlyPrevPrimaryRoleSelected)
      ) {
        if (filterRole === dashboardDefaultRole.id) {
          setSelectedTags([]);
        } else {
          const newPrimaryRoleId = groupedTags?.Role?.find(({ name }) => name === filterRole)?.id;
          newPrimaryRoleId && setSelectedTags([newPrimaryRoleId]);
        }

        dispatch(setPreselectedRole(filterRole));
      }
    }
  }, [filterRole]);

  const setSelectedTags = (values: string[]) => {
    handleSetQueryByFilters({
      selectedTags: getSelectedTagsToRender(values),
      selectedOwners: getUsersByIds(selectedOwners),
      filterBy,
      timePeriod,
      searchText,
    });
    dispatch(setFilterTags(values));
  };

  const setSelectedOwners = (values: number[]) => {
    handleSetQueryByFilters({
      selectedTags: getSelectedTagsToRender(Array.from(selectedTags)),
      selectedOwners: getUsersByIds(values),
      filterBy,
      timePeriod,
      searchText,
    });
    dispatch(setFilterOwners(values));
  };

  const handleSetFilterBy = (values: string[]) => {
    handleSetQueryByFilters({
      selectedTags: getSelectedTagsToRender(Array.from(selectedTags)),
      selectedOwners: getUsersByIds(selectedOwners),
      filterBy: values,
      timePeriod,
      searchText,
    });
    dispatch(setFilterBy(values));
  };

  const setTimePeriod = (values: ITimePeriod) => {
    handleSetQueryByFilters({
      selectedTags: getSelectedTagsToRender(Array.from(selectedTags)),
      selectedOwners: getUsersByIds(selectedOwners),
      filterBy,
      timePeriod: values,
      searchText,
    });
    dispatch(setFilterTimePeriod(values));
  };

  const setSearchText = (value: string) => {
    handleSetQueryByFilters({
      selectedTags: getSelectedTagsToRender(Array.from(selectedTags)),
      selectedOwners: getUsersByIds(selectedOwners),
      filterBy,
      timePeriod,
      searchText: value,
    });
    setGlobalSearchText(value);
  };

  const handleSetAllFilters = (filters: IFilterTypes, isUpdateQuery = true) => {
    if (isUpdateQuery) {
      handleSetQueryByFilters({
        selectedTags: getSelectedTagsToRender(filters.selectedTags),
        selectedOwners: getUsersByIds(filters.selectedOwners),
        filterBy: filters.filterBy,
        timePeriod: filters.timePeriod,
        searchText: filters.searchText,
      });
    }
    dispatch(setAllFilters(filters));
  };

  const removeSelectedFilter = (filterId: string) => () => {
    const newSelectedTags = new Set(selectedTags);
    newSelectedTags.delete(filterId);
    setSelectedTags(Array.from(newSelectedTags));
  };

  const removeSelectedOwner = (ownerId: number) => () => {
    const newSelectedOwners = selectedOwners.filter((oId) => oId !== ownerId);
    setSelectedOwners(newSelectedOwners);
  };

  const removeFilterByItem = (filterByItem: string) => () => {
    const newFilterBy = filterBy.filter((f) => f !== filterByItem);
    handleSetFilterBy(newFilterBy);
  };

  const removeTimePeriodItem = (timePeriodKey: keyof ITimePeriod) => () => {
    let newTimePeriod = _omit(timePeriod, timePeriodKey) as ITimePeriod;

    if (_isEmpty(newTimePeriod)) {
      newTimePeriod = null;
    }

    setTimePeriod(newTimePeriod);
  };

  const getSelectedTagsToRender = (selectedTags: string[]): ITagWithGroup[] => {
    return selectedTags.map((tagId) => allTags.find((tag) => tag.id === tagId));
  };

  const { groupedTagOptions, ownerOptions } = useMemo(() => {
    const groupedTagOptions: Record<string, any> = {};
    const allReportsOwnerIds = new Set();
    const allReportsTags = new Set();

    // Collect tags from filtered reports when at least one filter is selected
    reports.forEach((report) => {
      report.tags.forEach(allReportsTags.add, allReportsTags);
    });

    // Collect owners from filtered reports when at least one filter is selected, except 'Owner' filter
    // Keep selected owners because other filters can filter out reports where this owners are exists
    selectedOwners.forEach(allReportsOwnerIds.add, allReportsOwnerIds);
    reports.forEach((report) => {
      report.owner.forEach(allReportsOwnerIds.add, allReportsOwnerIds);
    });

    const ownerOptions = allReportsOwners
      .filter((owner) => allReportsOwnerIds.has(owner.id)) // Filter out owners that doesn't exists in reports
      .map(mapUserForSelectOption);

    Object.keys(groupedTags).forEach((tagGroupName) => {
      const label = TagGroupUINames[tagGroupName] || tagGroupName;

      groupedTagOptions[label] = groupedTags[tagGroupName]
        .filter((tag) => allReportsTags.has(tag.id) || selectedTags.has(tag.id)) // Filter out tags that doesn't exists in reports
        .map((tag) => ({
          order: TAGS_FILTER_SECTION_ORDER,
          id: tag.id,
          name: tag.name,
        }));
    });

    return { groupedTagOptions, ownerOptions };
  }, [reports, groupedTags, allReportsOwners, selectedTags, selectedOwners, filterBy, timePeriod]);

  return {
    query,
    filterBy,
    timePeriod,
    searchText,
    groupedTagOptions,
    ownerOptions,
    selectedTags,
    selectedOwners,
    setSelectedTags,
    setSelectedOwners,
    setFiltersBy: handleSetFilterBy,
    setTimePeriod,
    setSearchText,
    setAllFilters: handleSetAllFilters,
    removeSelectedFilter,
    removeSelectedOwner,
    removeFilterByItem,
    removeTimePeriodItem,
    getSelectedTagsToRender,
    getUsersByIds,
  };
};
