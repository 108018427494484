import { useDispatch } from "react-redux";

import {
  favoriteReport,
  IFavoriteReportRequestAction,
  IUnfavoriteReportRequestAction,
  unfavoriteReport,
} from "Store/actions/favorites.actions";

interface IFavoritesHook {
  favoriteReport: (reportId: number) => IFavoriteReportRequestAction;
  unfavoriteReport: (reportId: number, favoriteId: number) => IUnfavoriteReportRequestAction;
}

const useFavorites = (): IFavoritesHook => {
  const dispatch = useDispatch();

  const handleFavoriteReport = (reportId: number) => dispatch(favoriteReport(reportId));
  const handleUnfavoriteReport = (reportId: number, favoriteId: number) => {
    return dispatch(unfavoriteReport(reportId, favoriteId));
  };

  return {
    favoriteReport: handleFavoriteReport,
    unfavoriteReport: handleUnfavoriteReport,
  };
};

export default useFavorites;
