import { FormikProps } from "formik";
import React, { FC, useRef, useState } from "react";

import { Button, FlexSpacer } from "@epam/loveship";
import { IModal } from "@epam/uui";

import Modal from "Components/modal";
import { handleShortLink } from "Helpers/utils";
import { useNews, useNotifications } from "Hooks/index";
import { INewsSavePayload } from "SP/news/news.types";
import { IUserPermissions } from "SP/users/users.types";
import { notificationTypes } from "Store/actions/notifications.actions";

import DashboardNewsDetails from "./dashboard-news-details";
import DashboardNewsForm from "./dashboard-news-form";

export enum ModalModes {
  VIEW_MODE = 0,
  FORM_MODE = 1,
}

interface IDashboardNewsModalProps {
  newsId: number;
  modalProps: IModal<string>;
  details: INewsSavePayload;
  defaultMode?: ModalModes;
  currentUserPermissions: IUserPermissions;
}

const newsInitialValues: INewsSavePayload = {
  newsTitle: "",
  description: "",
  reportLink: "",
};

const DashboardNewsModal: FC<IDashboardNewsModalProps> = ({
  defaultMode,
  newsId,
  modalProps,
  details,
  currentUserPermissions,
}) => {
  const [mode, setMode] = useState(defaultMode || ModalModes.VIEW_MODE);
  const [isValidForm, setValidForm] = useState(false);
  const formRef = useRef<FormikProps<INewsSavePayload> | null>(null);

  const { showNotification } = useNotifications();
  const { singleNewsLoading, deleteNews, addNews, editNews } = useNews();
  const isEdit = mode === ModalModes.FORM_MODE && newsId !== undefined;

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleSaveNews = (values: INewsSavePayload) => {
    if (isEdit) {
      return editNews(newsId, values, modalProps.abort);
    }

    return addNews(values, modalProps.abort);
  };

  const handleEditNews = () => {
    setMode(ModalModes.FORM_MODE);
  };

  const handleDeleteNews = () => {
    showNotification({
      type: notificationTypes.warning,
      text: "Are you sure you want to delete this news?",
      actions: (props) => [
        {
          name: "Delete",
          action: () => {
            props.onClose();
            deleteNews(newsId, modalProps.abort);
          },
        },
      ],
      duration: "forever",
      size: "large",
    });
  };

  const detailsModalProps = {
    title: details?.newsTitle,
    footer: (
      <>
        {details?.reportLink && (
          <Button
            target="_blank"
            lineHeight="24"
            fill="white"
            caption="Open Report"
            href={handleShortLink(details.reportLink)}
          />
        )}
        <FlexSpacer />
        <Button cx="btn" color="night600" fill="white" onClick={modalProps.abort} caption="Cancel" />
        <Button
          isDisabled={singleNewsLoading || !currentUserPermissions.canDeleteNews}
          color="fire"
          caption="Delete"
          fill="white"
          onClick={handleDeleteNews}
        />
        <Button
          isDisabled={singleNewsLoading || !currentUserPermissions.canEditNews}
          cx="btn btn--grass"
          color="grass"
          caption="Edit"
          onClick={handleEditNews}
        />
      </>
    ),
  };

  const formModalProps = {
    title: `${isEdit ? "Edit" : "Add"} News`,
    footer: (
      <>
        <FlexSpacer />
        <Button cx="btn" color="night600" fill="white" onClick={modalProps.abort} caption="Cancel" />
        <Button
          cx="btn btn--grass"
          isDisabled={!isValidForm || singleNewsLoading}
          color="grass"
          caption="Save"
          onClick={handleSaveButtonClick}
        />
      </>
    ),
  };

  return (
    <Modal
      scrollableContent
      disallowClickOutside
      width={672}
      modalProps={modalProps}
      {...(mode === ModalModes.VIEW_MODE ? detailsModalProps : formModalProps)}
    >
      {mode === ModalModes.VIEW_MODE ? (
        <DashboardNewsDetails {...details} />
      ) : (
        <DashboardNewsForm
          formRef={formRef}
          isValidForm={isValidForm}
          onSetValidForm={setValidForm}
          initialValues={details || newsInitialValues}
          handleSubmit={handleSaveNews}
        />
      )}
    </Modal>
  );
};

export default DashboardNewsModal;
