import FilterBuilder from "odata-filter-builder";

import { graphConfig, graphGet } from "@App/libs/graph";
import { ItemAddResult, ItemUpdateResult, List, PermissionKind, sp } from "@pnp/sp";

import { batchPromises } from "Helpers/batchPromises";
import { UserPhoto } from "Helpers/storage";
import { getBase64ImageFromBlob } from "Helpers/utils";
import {
  IGroupDTO,
  IRoleDTO,
  IRolesData,
  ISearchByNameDTO,
  ISearchEntityDTO,
  IUserConfigsDTO,
  IUserDTO,
  IUserEntityDTO,
  IUserPermissions,
} from "SP/users/users.types";

export const certifyGroup = process.env.REACT_APP_CERTIFY_GROUP;

export default class UsersRepository {
  private newsList: List = sp.web.lists.getByTitle("News");
  private userSpecificConfigs: List = sp.web.lists.getByTitle("UserSpecificConfigurations");
  private dl: List = sp.web.defaultDocumentLibrary;
  private cachedLocalUsers = {};

  async getCurrentUser(): Promise<IUserDTO> {
    const currentUserEntity = await sp.web.currentUser.select("Title", "Email", "Id").usingCaching().get();
    const [permissions, photo, configs] = await Promise.all([
      this.getCurrentUserPermissions(),
      this.getCurrentUserPhoto(),
      this.getCurrentUserConfigs(currentUserEntity.Id),
    ]);

    return {
      entity: currentUserEntity,
      permissions,
      photo,
      configs,
    };
  }

  async getCanCertify(url?: string): Promise<boolean> {
    try {
      const userMemberOfResponse = await graphGet<{ value: IGroupDTO[] }>({
        url: url || graphConfig.graphMemberOfEndpoint,
      });

      const canCertify = !!userMemberOfResponse.value.find((group) => group.displayName === certifyGroup);
      const nextLink = userMemberOfResponse[graphConfig.nextLinkField];

      if (!canCertify && nextLink) {
        return this.getCanCertify(nextLink);
      }

      return canCertify;
    } catch (e) {
      return false;
    }
  }

  async getCurrentUserPermissions(): Promise<IUserPermissions> {
    const [newsPerms, reportPerms, canCertifyReport] = await Promise.all([
      this.newsList.getCurrentUserEffectivePermissions(),
      this.dl.getCurrentUserEffectivePermissions(),
      this.getCanCertify(),
    ]);

    return {
      canAddNews: sp.web.hasPermissions(newsPerms, PermissionKind.AddListItems),
      canEditNews: sp.web.hasPermissions(newsPerms, PermissionKind.EditListItems),
      canDeleteNews: sp.web.hasPermissions(newsPerms, PermissionKind.DeleteListItems),
      canAddReport: sp.web.hasPermissions(reportPerms, PermissionKind.ManagePermissions),
      canCertifyReport,
    };
  }

  async getCurrentUserPhoto(): Promise<string> {
    const userPhoto = UserPhoto.get();
    if (userPhoto) {
      return userPhoto;
    }

    const photoBlob = await graphGet<Blob>({ url: graphConfig.graphMePhotoEndpoint, readAs: "blob" });
    if (!photoBlob) {
      return "";
    }

    const photoUrl = await getBase64ImageFromBlob(photoBlob);
    UserPhoto.set(photoUrl, 60 * 60);

    return photoUrl;
  }

  async getCurrentUserRoles(userEmail: string): Promise<IRoleDTO[]> {
    const roleList = sp.web.lists.getByTitle("datahub.epm_data.v_primary_role");
    const rolesData: IRolesData = await roleList.items.filter(`Email eq '${userEmail}'`).select("ReportsData").get();

    return JSON.parse(rolesData[0]?.ReportsData || null)?.roles;
  }

  async getCurrentUserConfigs(userId: number): Promise<IUserConfigsDTO> {
    const configs = await this.userSpecificConfigs.items
      .filter(`AuthorId eq ${userId}`)
      .select("FavoritesOrder", "Id", "FilterRole")
      .get();

    if (configs.length > 0) {
      return configs[0];
    }

    return null;
  }

  async addCurrentUserConfigs(updatedUserConfigs: IUserConfigsDTO): Promise<ItemAddResult> {
    return await this.userSpecificConfigs.items.add(updatedUserConfigs);
  }

  async updateCurrentUserConfigs(updatedUserConfigs: IUserConfigsDTO, configId: number): Promise<ItemUpdateResult> {
    return await this.userSpecificConfigs.items.getById(configId).update(updatedUserConfigs);
  }

  async getUsersByIds(ids: number[]): Promise<IUserEntityDTO[]> {
    const query = new FilterBuilder();
    ids.forEach((id) => {
      query.or((q) => q.eq("Id", id));
    });
    return await sp.web.siteUsers.filter(query.toString()).usingCaching().get();
  }

  async getUsersByNames(names: string[]): Promise<ISearchByNameDTO[]> {
    const limit = 2;
    const thenCb =
      (name: string) =>
      (users: ISearchEntityDTO[]): ISearchByNameDTO => ({
        text: name,
        data: users[0],
      });

    return await batchPromises<string, ISearchEntityDTO[], ISearchByNameDTO>(
      {
        items: names,
        fn: this.searchPeople.bind(this),
        thenCb,
      },
      limit,
    );
  }

  async search(name: string, limit: number): Promise<IUserEntityDTO[]> {
    const query = new FilterBuilder().or((q) => q.startsWith("Email", name)).or((q) => q.startsWith("Title", name));
    return await sp.web.siteUsers.filter(query.toString()).top(limit).usingCaching().get();
  }

  async searchPeople(name: string, limit: number): Promise<ISearchEntityDTO[]> {
    const queryParams = {
      AllowEmailAddresses: true,
      AllowMultipleEntities: false,
      AllUrlZones: false,
      MaximumEntitySuggestions: limit * 1.5,
      PrincipalSource: 15,
      PrincipalType: 13,
      QueryString: name,
    };

    const data = await sp.profiles.clientPeoplePickerSearchUser(queryParams);
    // Filter out duplicate values
    const values = (data as ISearchEntityDTO[]).filter((v) => (v.Key as string).indexOf("c:05") === -1);

    await batchPromises({
      items: values.filter(this.isSendAsBatchPromise),
      fn: this.ensureUser.bind(this),
      thenCb: this.userKeyHandler,
    });

    return values.filter((v) => v.Key !== null && typeof v.Key === "number");
  }

  private isSendAsBatchPromise(item) {
    return (
      !item.EntityData ||
      item.EntityData?.SPUserID === undefined ||
      (item.EntityType === "SPGroup" && item.EntityData?.SPGroupID === undefined)
    );
  }

  private userKeyHandler(user: ISearchEntityDTO) {
    return (userId: number) => {
      user.Key = userId;
    };
  }

  async ensureUser({ Key: userId }: ISearchEntityDTO): Promise<number> {
    if (this.cachedLocalUsers[userId]) return this.cachedLocalUsers[userId];

    const { data } = await sp.web.ensureUser(userId as string);

    if (data?.Id) {
      this.cachedLocalUsers[userId] = data.Id;
      return data.Id;
    }

    return null;
  }
}
