import type { ISelectOption } from "Components/select/select.types";
import type { IUser } from "SP/users/users.types";

interface IGetSortedUserIdsByEmails {
  emails: string[];
  existedUsers: Map<string, number>;
  foundUsers: IUser[];
}

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function isValidEmail(email: string): boolean {
  return emailRegex.test(email);
}

function getSeparator(emails: string) {
  const [comma, semiColon] = [",", ";"];

  if (emails.includes(comma)) {
    return comma;
  }

  return semiColon;
}

export function getValidEmails(emails: string): string[] {
  if (!emails) return [];

  const separator = getSeparator(emails);
  const emailArray = emails.split(separator);
  const outlookMatchRegex = /<(.+?)>/;
  const validEmails = [];

  for (const email of emailArray) {
    const outlookMatch = email.match(outlookMatchRegex);

    if (outlookMatch && isValidEmail(outlookMatch[1].trim())) {
      validEmails.push(outlookMatch[1].trim());
    } else if (isValidEmail(email.trim())) {
      validEmails.push(email.trim());
    }
  }

  return validEmails;
}

export function generateCopyEmailsString<T>(options: ISelectOption[], selectedEmailIds: T[]): string {
  let copyEmailsString = "";
  const optionsMap = new Map(options.map((option) => [option.id, option]));

  selectedEmailIds.forEach((id) => {
    const option = optionsMap.get(id as unknown as number);

    if (option) {
      copyEmailsString += `${copyEmailsString ? "; " : ""}${option.label} <${option.searchField}>`;
    }
  });

  return copyEmailsString;
}

export function getExistedUsersByEmails(allUsers: IUser[], emails: string[]): Map<string, number> {
  const existedUsers = new Map<string, number>();
  const lowerCaseEmails = emails.map((email) => email.toLowerCase());

  allUsers.forEach((user) => {
    const userEmail = user.email.toLowerCase();
    if (lowerCaseEmails.includes(userEmail)) {
      existedUsers.set(userEmail, user.id);
    }
  });

  return existedUsers;
}

export function getSortedUserIdsByEmails(params: IGetSortedUserIdsByEmails): number[] {
  const { emails, existedUsers, foundUsers } = params;
  const foundUsersMap = new Map<string, number>(foundUsers.map((user) => [user.email.toLowerCase(), user.id]));

  return emails
    .map((email) => {
      const lowerCaseEmail = email.toLowerCase();
      return existedUsers.get(lowerCaseEmail) || foundUsersMap.get(lowerCaseEmail);
    })
    .filter(Boolean);
}
