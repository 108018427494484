import { useDispatch, useSelector } from "react-redux";

import { getReportVersionHistory } from "Pages/report/report.actions";
import { IReportVersion } from "SP/reports/reports.types";
import { IRootReducerState } from "Store/reducers";

export const useVersionHistory = () => {
  const dispatch = useDispatch();

  const loading = useSelector<IRootReducerState, boolean>((state) => state.report.versionsLoading);
  const versions = useSelector<IRootReducerState, IReportVersion[]>((state) => state.report.versions);

  const handleGetReportVersionHistory = (id) => dispatch(getReportVersionHistory(id));

  return {
    loading,
    versions,
    getReportVersionHistory: handleGetReportVersionHistory,
  };
};
