import { List, sp } from "@pnp/sp";

import type { ISearchDictionaryItemDTO } from "./search.types";

export default class SearchRepository {
  private dl: List = sp.web.lists.getByTitle("SearchDictionary");

  public loadSearchDictionary(): Promise<ISearchDictionaryItemDTO[]> {
    return this.dl.items.select("Word", "Synonyms", "Group").get<ISearchDictionaryItemDTO[]>();
  }
}
