import { IFolder } from "SP/folders/folders.types";

export enum FoldersActionsTypes {
  GET_ROOT_FOLDERS_REQUEST = "[FOLDERS] GET ROOT FOLDERS REQUEST",
  GET_ROOT_FOLDERS_SUCCESS = "[FOLDERS] GET ROOT FOLDERS SUCCESS",
  GET_ROOT_FOLDERS_FAILURE = "[FOLDERS] GET ROOT FOLDERS FAILURE",

  GET_SUB_FOLDERS_REQUEST = "[FOLDERS] GET SUB FOLDERS REQUEST",
  GET_SUB_FOLDERS_SUCCESS = "[FOLDERS] GET SUB FOLDERS SUCCESS",
  GET_SUB_FOLDERS_FAILURE = "[FOLDERS] GET SUB FOLDERS FAILURE",

  CLEAR_FOLDERS = "[FOLDERS] CLEAR FOLDERS",
}

export interface IGetRootFoldersRequestAction {
  type: FoldersActionsTypes.GET_ROOT_FOLDERS_REQUEST;
  callback?: (rootFolders: IFolder[]) => void;
}

export function getRootFoldersRequest(callback?: () => void): IGetRootFoldersRequestAction {
  return {
    type: FoldersActionsTypes.GET_ROOT_FOLDERS_REQUEST,
    callback,
  };
}

export interface IGetRootFoldersSuccessAction {
  type: FoldersActionsTypes.GET_ROOT_FOLDERS_SUCCESS;
  payload: IFolder[];
}

export function getRootFoldersSuccess(rootFolders: IFolder[]): IGetRootFoldersSuccessAction {
  return {
    type: FoldersActionsTypes.GET_ROOT_FOLDERS_SUCCESS,
    payload: rootFolders,
  };
}

export interface IGetRootFoldersFailureAction {
  type: FoldersActionsTypes.GET_ROOT_FOLDERS_FAILURE;
  error: string;
}

export function getRootFoldersFailure(error: Error): IGetRootFoldersFailureAction {
  return {
    type: FoldersActionsTypes.GET_ROOT_FOLDERS_FAILURE,
    error: error.message,
  };
}

export interface IGetSubFoldersRequestAction {
  type: FoldersActionsTypes.GET_SUB_FOLDERS_REQUEST;
  parentFolderId: string;
  callback?: () => void;
}

export function getSubFoldersRequest(parentFolderId: string, callback?: () => void): IGetSubFoldersRequestAction {
  return {
    type: FoldersActionsTypes.GET_SUB_FOLDERS_REQUEST,
    parentFolderId,
    callback,
  };
}

export interface IGetSubFoldersSuccessAction {
  type: FoldersActionsTypes.GET_SUB_FOLDERS_SUCCESS;
  payload: { parentFolderId: string; subFolders: IFolder[] };
}

export function getSubFoldersSuccess(parentFolderId: string, subFolders: IFolder[]): IGetSubFoldersSuccessAction {
  return {
    type: FoldersActionsTypes.GET_SUB_FOLDERS_SUCCESS,
    payload: {
      parentFolderId,
      subFolders,
    },
  };
}

export interface IGetSubFoldersFailureAction {
  type: FoldersActionsTypes.GET_SUB_FOLDERS_FAILURE;
  error: string;
}

export function getSubFoldersFailure(error: Error): IGetSubFoldersFailureAction {
  return {
    type: FoldersActionsTypes.GET_SUB_FOLDERS_FAILURE,
    error: error.message,
  };
}

export interface IClearFoldersAction {
  type: FoldersActionsTypes.CLEAR_FOLDERS;
}

export function clearFolders(): IClearFoldersAction {
  return {
    type: FoldersActionsTypes.CLEAR_FOLDERS,
  };
}

export type IFoldersActionsTypes =
  | IGetRootFoldersRequestAction
  | IGetRootFoldersSuccessAction
  | IGetRootFoldersFailureAction
  | IGetSubFoldersRequestAction
  | IGetSubFoldersSuccessAction
  | IGetSubFoldersFailureAction
  | IClearFoldersAction;
