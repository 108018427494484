import _get from "lodash/get";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";

import Routes, { IRoute } from "@App/routes";
import { ApmRoute } from "@elastic/apm-rum-react";

interface IProtectedRoute {
  permissionKey: string;
  path: string;
  exact?: boolean;
  routes?: IRoute[];
  component: React.FC<RouteComponentProps>;
}

const notLoaded = "not-loaded-yet";
const ProtectedRoute: FC<IProtectedRoute> = ({ component: Component, permissionKey, ...rest }) => {
  const canView = useSelector((state) => _get(state, permissionKey, notLoaded));

  return (
    <ApmRoute
      {...rest}
      render={(props) => {
        if (canView === notLoaded || canView) {
          return <Component {...props} />;
        }

        return <Redirect to={Routes.HOME.path} />;
      }}
    />
  );
};

export default ProtectedRoute;
