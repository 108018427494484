import React, { FC } from "react";

import { Tooltip } from "Components/tooltip";

import { badgesCN } from "./badges";
import type { IBadgesTooltip } from "./badges.types";

export const BadgesTooltip: FC<IBadgesTooltip> = ({ badges, children }) => (
  <Tooltip
    trigger="click"
    placement="bottom-start"
    color="white"
    cx={badgesCN("badges-tooltip")}
    content={() => <div className={badgesCN("badges-panel")}>{badges}</div>}
  >
    {children}
  </Tooltip>
);
