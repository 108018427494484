import { msalInstance } from "@App/libs/msal";
import { PnPFetchClient } from "@App/libs/pnp";
import { sp } from "@pnp/sp";

export function configurePnP(): void {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchClientFactory = () => {
    return new PnPFetchClient(msalInstance);
  };

  sp.setup({
    sp: {
      fetchClientFactory,
      baseUrl,
      headers: {
        Accept: "application/json;odata=minimalmetadata",
      },
    },
    globalCacheDisable: false,
    enableCacheExpiration: true,
    cacheExpirationIntervalMilliseconds: 1000,
  });
}
