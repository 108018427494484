import _isEqual from "lodash/isEqual";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";

import { DatePicker } from "@epam/loveship";

import { DEFAULT_DATE_FORMAT } from "Components/fields/datepicker-field";
import withNaming from "Helpers/bemClassname";
import { omitEmptyDate } from "Helpers/utils";
import { ITimePeriod } from "Pages/reports-browser/reports-filters.reducer";

const defaultValueFormat = "YYYY-MM-DD";

interface IReportBrowsersFilterTimePeriodProps {
  timePeriod: ITimePeriod;
  onSetTimePeriod: (values: ITimePeriod) => void;
}

const handleFilterDates = (key: keyof ITimePeriod, day, { fromDate, toDate }: ITimePeriod) => {
  const date = day.toDate();
  date.setHours(0, 0, 0, 0);

  if (key === "fromDate" && toDate) {
    return date <= moment(toDate, defaultValueFormat).toDate();
  } else if (key === "toDate" && fromDate) {
    return date >= moment(fromDate, defaultValueFormat).toDate();
  }

  return true;
};

const handleDefaultData = (defaultData: ITimePeriod) => ({
  fromDate: defaultData?.fromDate,
  toDate: defaultData?.toDate,
});

export const ReportBrowsersFilterTimePeriod: FC<IReportBrowsersFilterTimePeriodProps> = ({
  timePeriod,
  onSetTimePeriod,
}) => {
  const cn = withNaming("reports-browser-filter-time-period");
  const [dates, setDates] = useState<ITimePeriod>(handleDefaultData(timePeriod));
  const handleDateChange = (key: keyof ITimePeriod) => (newDate: string) => {
    if (!newDate) return;

    setDates((oldDates) => {
      const newDates = { ...oldDates, [key]: newDate };

      onSetTimePeriod(omitEmptyDate(newDates));
      return newDates;
    });
  };

  useEffect(() => {
    const newDates = handleDefaultData(timePeriod);

    if (!_isEqual(dates, newDates)) {
      setDates(newDates);
    }
  }, [timePeriod]);

  return (
    <div className={cn("", ["flex"])}>
      <div className={cn("date-picker", { "from-date": true })}>
        <div className={cn("date-picker-label")}>From</div>
        <DatePicker
          cx={cn("date-picker-input")}
          format={DEFAULT_DATE_FORMAT}
          value={dates.fromDate}
          filter={(day) => handleFilterDates("fromDate", day, dates)}
          onValueChange={handleDateChange("fromDate")}
          mode="form"
          disableClear
        />
      </div>
      <div className={cn("date-picker", { "to-date": true })}>
        <div className={cn("date-picker-label")}>To</div>
        <DatePicker
          cx={cn("date-picker-input")}
          format={DEFAULT_DATE_FORMAT}
          value={dates.toDate}
          filter={(day) => handleFilterDates("toDate", day, dates)}
          onValueChange={handleDateChange("toDate")}
          mode="form"
          disableClear
        />
      </div>
    </div>
  );
};
