import { FormikProps } from "formik";
import _omit from "lodash/omit";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Prompt, RouteComponentProps } from "react-router-dom";

import { Layout } from "Components/layout";
import InlineLoader from "Components/loader";
import { closeNotificationsByType } from "Components/notifications/notifications.utils";
import Subheader, { IButton } from "Components/subheader";
import { handleOpenVersionHistoryModal } from "Components/version-history";
import { getEncodedReportName } from "Helpers/reportsHelper";
import { handleHistoryBack } from "Helpers/utils";
import useBeforeunload from "Hooks/useBeforeunload";
import { useSingleReportData, useSingleReportMethods } from "Hooks/useSingleReport";
import { useTagsData } from "Hooks/useTags";
import { IReportEditSavePayload } from "SP/reports/reports.types";
import { notificationTypes } from "Store/actions/notifications.actions";

import Routes from "../../routes";
import AddReportForm from "./add-report-form";
import { IFormReport } from "./add-report-form/add-report-form.types";

export const reportInitialValues: IReportEditSavePayload = {
  folderPath: null,
  reportName: "",
  certified: false,
  owner: [],
  orderNumber: 10000,
  viewOnlyUsers: [],
  readOnlyUsers: [],
  modifierUsers: [],
  contributorUsers: [],
  modificationDate: moment().toISOString(),
  kbLink: "",
  videoGuideLink: "",
  reportLink: "",
  reportUploadFile: null,
  description: "",
  applications: [],
  domains: [],
  metrics: [],
  roles: [],
  powerBI_ID: "",
  primaryRoles: [],
};

// In order to change below styles be aware that it's used also in Report page.
export const panelStyles = {
  marginTop: 60,
  maxWidth: 920,
  boxShadow: "0 1px 2px 0 rgba(29, 30, 38, 0.1), 0 2px 10px 0 rgba(29, 30, 38, 0.15)",
};

function isFormReadyToDisplay(isEdit: boolean, report: IFormReport): boolean {
  return (isEdit && !!report?.id) || !isEdit;
}

const getDisabledSaveButtonInfo = (isFormDirty, isValidForm): string | undefined => {
  if (!isValidForm && isFormDirty) {
    return "Not all required fields are filled in.";
  }
  if (!isValidForm) {
    return "No changes have been made.";
  }
};

const AddReport: React.FC<RouteComponentProps> = ({ history, match }) => {
  const [reportName, setReportName] = useState<string>("");
  const [isValidForm, setValidForm] = useState<boolean>(false);
  const [isFormDirty, setFormDirty] = useState<boolean>(false);
  const formRef = React.useRef<FormikProps<IReportEditSavePayload> | null>(null);
  const { report: reportData, loading: reportsLoading, permissions } = useSingleReportData();
  const { getReport, resetReport } = useSingleReportMethods();
  const { loading: tagsLoading, groupedTags, getReportTagIds } = useTagsData();
  const reportIdentifier = match.params["reportName"]; // Could be also old url with id
  const isEdit = match.path === Routes.REPORT_EDIT.path;

  const isLoading = reportsLoading || tagsLoading;

  const report = useMemo(() => {
    if (isEdit) {
      const newReport = _omit(reportData, ["tags"]);
      return {
        ...newReport,
        ...getReportTagIds(reportData?.tags || []),
        modificationDate: moment.utc(newReport.modificationDate).format("YYYY-MM-DD"),
      };
    }

    return null;
  }, [groupedTags, reportData, isEdit]);

  const subHeaderButtons: IButton[] = [
    isEdit && {
      caption: "Version History",
      fill: "solid",
      color: "sky",
      onClick: () => handleOpenVersionHistoryModal(report.id),
      visible: true,
    },
    {
      caption: "Cancel",
      fill: "white",
      color: "night500",
      onClick: () => handleHistoryBack(history),
      visible: true,
    },
    {
      caption: "Save",
      fill: "solid",
      isDisabled: isLoading || !isValidForm,
      color: "grass",
      onClick: handleSaveButtonClick,
      visible: true,
      tooltipText: getDisabledSaveButtonInfo(isFormDirty, isValidForm),
    },
  ];

  function handleSaveButtonClick() {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }

  function handleAfterSubmit(reportName: string) {
    history.push(`${Routes.REPORTS_BROWSER.path}/${getEncodedReportName(reportName)}`);
  }

  function isShowPrompt(): boolean {
    const isSubmitting = formRef.current?.isSubmitting;
    return isFormDirty && typeof isSubmitting === "boolean" && !isSubmitting;
  }

  useEffect(() => {
    isEdit && getReport(reportIdentifier);
    closeNotificationsByType(notificationTypes.warning);

    return () => {
      isEdit && resetReport();
    };
  }, []);

  useEffect(() => {
    if (isEdit && !!report?.reportName && report.reportName !== reportName) {
      setReportName(report.reportName);
    }
  }, [isEdit, report, reportName]);

  useBeforeunload((event) => {
    if (isShowPrompt()) {
      event.preventDefault();
    }
  });

  return (
    <Layout>
      {isLoading && <InlineLoader />}
      <Subheader reportId={report?.id} title={reportName || "Report Name"} buttons={subHeaderButtons} />
      <Prompt
        when={isShowPrompt()}
        message="If you leave the page before clicking ‘save’ button, data can be missed. Are you sure you want to leave?"
      />
      {isFormReadyToDisplay(isEdit, report) && (
        <AddReportForm
          initialValues={isEdit ? report : reportInitialValues}
          formRef={formRef}
          isValidForm={isValidForm}
          onReportNameSet={setReportName}
          onSetFormDirty={setFormDirty}
          onSetValidForm={setValidForm}
          onAfterSubmit={handleAfterSubmit}
          permissions={permissions}
          {...(isEdit ? { isEdit: true, id: report.id } : {})}
        />
      )}
    </Layout>
  );
};

export default AddReport;
