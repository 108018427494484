import highligh, { IHighlightWithCount } from "Helpers/highlight";
import type { IReport, IReportExtended, IReportVideoGuide } from "SP/reports/reports.types";
import type { ITagWithGroup } from "SP/tags/tags.types";

export function getReportTags(tagIds: string[], allTags: ITagWithGroup[]): ITagWithGroup[] {
  return allTags.filter((tag) => tagIds?.includes(tag.id));
}

export function checkIsSomeTagHighlighted<T, K extends keyof T>(searchText: string, tags: T[], tagKey: K): boolean {
  return tags.some((tag) => highligh.check(searchText, tag[tagKey] as unknown as string));
}

export function calculateSearchRank(
  titleHighlighted: IHighlightWithCount,
  descriptionHighlighted: IHighlightWithCount,
  isTagHighlighted: boolean,
): number {
  let searchRank = 0;

  if (titleHighlighted.isHighlighted) {
    searchRank += titleHighlighted.count * 2000;
  }

  if (titleHighlighted.isHighlightedBySynonym) {
    searchRank += 1000;
  }

  if (descriptionHighlighted.isHighlighted) {
    searchRank += 200;
  }

  if (descriptionHighlighted.isHighlightedBySynonym) {
    searchRank += 100;
  }

  if (isTagHighlighted) {
    searchRank += 200;
  }

  return searchRank;
}

export function updateReportsSearchRank(reports: IReportExtended[], favoriteReportIds: number[]): IReportExtended[] {
  return reports.map((report) => {
    if (favoriteReportIds.includes(report.id)) {
      report.searchRank += 1000;
    }

    if (report.certified) {
      report.searchRank += 500;
    }

    return report;
  });
}

export function getSortedFavoriteReports(favoritesOrder: number[], favoriteReports: IReport[]): IReport[] {
  const favoritesMap = new Map(favoriteReports.map((report) => [report.id, report]));
  return favoritesOrder.map((reportId) => favoritesMap.get(reportId)).filter(Boolean);
}

export function getReportVideoGuideFromStore(reportId: number, allReports: IReportExtended[]): IReportVideoGuide {
  const foundReport = allReports.find((report) => report.id === reportId);

  if (foundReport) {
    return {
      id: reportId,
      reportName: foundReport.reportName,
      videoGuideLink: foundReport.videoGuideLink,
    };
  }

  return null;
}

export function getEncodedReportName(reportName: string) {
  return encodeURIComponent(reportName).replaceAll("%20", "+");
}

export function getDecodedReportName(reportName: string) {
  return decodeURIComponent(reportName).replaceAll("+", " ");
}
