import _countBy from "lodash/countBy";
import _pickBy from "lodash/pickBy";

import { ITagSelectOption } from "Hooks/useTags";

export const sortPrimaryFirst = (value: string[], primaryValue: string[], idNameMap: Map<string, string>): string[] => [
  ...value.filter((i) => primaryValue.includes(i)).sort((id1, id2) => sortIdsByNames(id1, id2, idNameMap)),
  ...value.filter((i) => !primaryValue.includes(i)).sort((id1, id2) => sortIdsByNames(id1, id2, idNameMap)),
];

export const sortIdsByNames = (id1: string, id2: string, idNameMap: Map<string, string>): 0 | 1 | -1 => {
  if (idNameMap.get(id1) > idNameMap.get(id2)) return 1;
  if (idNameMap.get(id1) < idNameMap.get(id2)) return -1;
  return 0;
};

export const addDuplicatesToPrimary = (
  searchValue: string,
  roles: ITagSelectOption[],
  handleAddPrimaryRole: (newValue: string | number) => void,
): void => {
  if (!searchValue) return;

  const valueArray = searchValue.split("; ");
  const elementCounts = _countBy(valueArray);
  const duplicates = Object.keys(_pickBy(elementCounts, (count) => count > 1));

  duplicates.forEach((roleName) => addIfExists(roleName, roles, handleAddPrimaryRole));
};

export const addIfExists = (
  label: string,
  options: ITagSelectOption[],
  addCb: (newValue: string | number) => void,
): void => {
  const newRoleId = options.find((role) => role.label === label)?.id;
  newRoleId && addCb(newRoleId);
};
