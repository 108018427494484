import moment from "moment";

import { ItemUpdateResult } from "@pnp/sp";

import {
  addGuidLabel,
  generateFolderPathByFileUrl,
  getDestinationUrls,
  mapStreamReportFile,
} from "SP/reports/reports.utils";
import type { IUser } from "SP/users/users.types";

import ReportsRepository from "./reports.repository";
import {
  IFile,
  IReport,
  IReportDTO,
  IReportExtended,
  IReportIdentifierType,
  IReportPermissions,
  IReportVersion,
  IReportVersionDTO,
  IReportVideoGuide,
  IStreamReport,
  IStreamReportDTO,
  IStreamTagsBI,
} from "./reports.types";

class ReportsService {
  repository = new ReportsRepository();

  private convertStreamTags = (tags: IStreamTagsBI[]): string[] => {
    return tags.map((tag) => addGuidLabel(tag.TermID));
  };

  private mapStreamReport = (report: IStreamReportDTO): IStreamReport => {
    const owner = [];
    const ownersNames = [];
    const ownerUsers: IUser[] = [];
    const file = mapStreamReportFile(report);

    report.Owner.forEach((user) => {
      const userId = parseInt(user.id);

      owner.push(userId);
      ownersNames.push(user.title);
      ownerUsers.push({
        id: userId,
        email: user.email,
        name: user.title,
      });
    });

    return {
      id: parseInt(report.ID),
      owner,
      ownersNames,
      ownerUsers,
      viewOnlyUsers: (report.ViewOnlyUsers || []).map((user) => parseInt(user.id)),
      readOnlyUsers: (report.ReadOnlyUsers || []).map((user) => parseInt(user.id)),
      modifierUsers: (report.Modifiers || []).map((user) => parseInt(user.id)),
      contributorUsers: (report.Contributors || []).map((user) => parseInt(user.id)),
      tags: this.convertStreamTags(report.TagsBI),
      orderNumber: parseInt(report.OrderNo.replace(",", "")) || null,
      description: report.DescriptionBI,
      modificationDate: report.UserDefinedModificationDate,
      reportName: report.ReportName || "Unnamed report",
      kbLink: report.KBLink || "",
      videoGuideLink: report.ReportVideoGuide || "",
      reportLink: report.ReportLink || "",
      file,
      powerBI_ID: report.PowerBI_ID || "",
      // MOCKED VALUES
      folderPath: {
        key: "",
        destinationUrl: generateFolderPathByFileUrl(file.url),
      },
      reportUploadFile: null,
      certified: report.Certified === "Yes",
      primaryRoles: this.convertStreamTags(report.PrimaryRoles) || [],
    };
  };

  private reverseMapReport = (report: IReport): Partial<IReportDTO> => {
    return {
      DescriptionBI: report.description,
      // Because of broken SharePoint api when we get list of reports 'UserDefinedModificationDate' is received as normal
      // but when we get single report 'UserDefinedModificationDate' time is 21:00:00 of previous day.
      // By adding 'T00:00:00Z' fixes this issue!
      UserDefinedModificationDate: `${moment(report.modificationDate).format("YYYY-MM-DD")}T00:00:00Z`,
      ReportName: report.reportName,
      OrderNo: report.orderNumber,
      OwnerId: {
        results: report.owner || [],
      },
      ViewOnlyUsersId: {
        results: report.viewOnlyUsers || [],
      },
      ReadOnlyUsersId: {
        results: report.readOnlyUsers || [],
      },
      ModifiersId: {
        results: report.modifierUsers || [],
      },
      ContributorsId: {
        results: report.contributorUsers || [],
      },
      Id: report.id,
      Certified: report.certified,
      KBLink: report.kbLink,
      ReportVideoGuide: report.videoGuideLink,
      ReportLink: report.reportLink,
      TagsBI: report.tags,
      PowerBI_ID: report.powerBI_ID,
      PrimaryRoles: report.primaryRoles,
    };
  };

  private mapReportVersion = (reportVersion: IReportVersionDTO, fileSizes: Map<string, string>): IReportVersion => {
    return {
      description: reportVersion.DescriptionBI,
      userDefinedModificationDate: reportVersion.UserDefinedModificationDate,
      reportName: reportVersion.ReportName,
      orderNumber: reportVersion.OrderNo,
      owner: (reportVersion.Owner || []).map((user) => user.LookupValue),
      viewOnlyUsers: (reportVersion.ViewOnlyUsers || []).map((user) => user.LookupValue),
      readOnlyUsers: (reportVersion.ReadOnlyUsers || []).map((user) => user.LookupValue),
      modifierUsers: (reportVersion.Modifiers || []).map((user) => user.LookupValue),
      contributorUsers: (reportVersion.Contributors || []).map((user) => user.LookupValue),
      id: reportVersion.ID,
      certified: reportVersion.Certified,
      kbLink: reportVersion.KBLink,
      videoGuideLink: reportVersion.ReportVideoGuide,
      reportLink: reportVersion.ReportLink,
      tags: reportVersion.TagsBI.map((t) => t.Label),
      powerBI_ID: reportVersion.PowerBI_x005f_ID,
      fileSize: fileSizes.get(reportVersion.VersionLabel),
      editor: reportVersion.Editor.LookupValue,
      modified: new Date(reportVersion.Modified),
      versionLabel: reportVersion.VersionLabel,
      primaryRoles: reportVersion.PrimaryRoles.map((t) => t.Label),
    };
  };

  async getAll(): Promise<IReport[]> {
    const { Row } = await this.repository.getAll();
    return Row.map(this.mapStreamReport);
  }

  async getByIdentifier(identifier: string, identifierType: IReportIdentifierType): Promise<IReport> {
    const report = await this.repository.getByIdentifier(identifier, identifierType);
    return this.mapStreamReport(report);
  }

  async getReportVideoGuideById(id: number): Promise<IReportVideoGuide> {
    const report = await this.repository.getReportVideoGuideById(id);
    return {
      id,
      reportName: report.ReportName,
      videoGuideLink: report.ReportVideoGuide,
    };
  }

  async getFileInfo(id: number): Promise<IFile> {
    return await this.repository.getFileInfo(id);
  }

  async getReportVersionHistory(id: number): Promise<IReportVersion[]> {
    const { reportVersions, reportFileVersions, currentFileSize } = await this.repository.getReportVersionHistory(id);

    const fileSizes = new Map(reportFileVersions.map((v) => [v.VersionLabel, v.Length]));
    fileSizes.set(reportVersions[0].VersionLabel, currentFileSize);

    return reportVersions.map((reportVersion) => this.mapReportVersion(reportVersion, fileSizes));
  }

  async edit(
    id: number,
    updatedReport: Omit<IReport, "id">,
    oldReport: IReportExtended,
    canEditPermissions: boolean,
  ): Promise<void> {
    const editParams = {
      id,
      file: updatedReport.reportUploadFile,
      payload: this.reverseMapReport({ id, ...updatedReport }),
      oldPayload: this.reverseMapReport(oldReport),
      destinationUrls: getDestinationUrls(updatedReport.file.url, updatedReport.folderPath.destinationUrl),
      canEditPermissions,
    };

    await this.repository.edit(editParams);
  }

  async deleteById(id: number): Promise<string> {
    return this.repository.deleteById(id);
  }

  async create(payload: Omit<IReport, "id">): Promise<number> {
    const reportData = this.reverseMapReport({ id: undefined, ...payload });
    const folderPath = payload.folderPath.key;
    return await this.repository.create(payload.reportUploadFile, folderPath, reportData);
  }

  async createLink(payload: Omit<IReport, "id">): Promise<number> {
    const reportData = this.reverseMapReport({ id: undefined, ...payload });
    const folderPath = payload.folderPath.key;
    return await this.repository.createLink(reportData, folderPath);
  }

  async getPermissionsOnItem(reportId: number): Promise<IReportPermissions> {
    return await this.repository.getPermissionsOnItem(reportId);
  }
}

export default ReportsService;
