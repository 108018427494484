import React, { FC, HTMLAttributes } from "react";

interface TimingElementProps<T> extends HTMLAttributes<T> {
  elementtiming: string;
}

export const TimingDiv: FC<TimingElementProps<HTMLDivElement>> = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);

export const TimingSpan: React.FC<TimingElementProps<HTMLSpanElement>> = ({ children, ...props }) => (
  <span {...props}>{children}</span>
);
