import React from "react";

import { ReactComponent as tableIcon } from "@epam/assets/icons/common/editor-chart-table-24.svg";
import { ReactComponent as cardIcon } from "@epam/assets/icons/common/editor-outline-24.svg";
import { ControlGroup, IconButton } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { useReportView } from "Hooks/useReports";

import "./style.scss";

const ReportViewToggle: React.FC = () => {
  const cn = withNaming("report-view-toggle");
  const { viewMode, changeViewMode } = useReportView();

  return (
    <ControlGroup cx={cn()}>
      <IconButton
        icon={tableIcon}
        cx={cn("icon", { active: viewMode === "table" })}
        onClick={changeViewMode("table")}
      />
      <IconButton icon={cardIcon} cx={cn("icon", { active: viewMode === "cards" })} onClick={changeViewMode("cards")} />
    </ControlGroup>
  );
};

export default ReportViewToggle;
