import type { ISelectOption } from "Components/select/select.types";
import type { IUser } from "SP/users/users.types";

export function getUsersByIds(users: IUser[], userIds: number[]): IUser[] {
  const usersMap = new Map(users.map((user) => [user.id, user]));
  return userIds.map((userId) => usersMap.get(userId)).filter(Boolean);
}

export function mapUserForSelectOption(user: IUser): ISelectOption {
  return { searchField: user.email, label: user.name, id: user.id };
}
