import React from "react";

import SearchService from "SP/search/search.service";
import type { ISearchDictionaryGroup } from "SP/search/search.types";

export type IHighlightItem = string | JSX.Element | JSX.Element[];
export type IHighlightWithCount = {
  isHighlighted: boolean;
  isHighlightedBySynonym: boolean;
  count: number;
};

export default (function () {
  const cache = new Map<string, IHighlightItem>();
  const wrapClassName = "highlight";

  function getSegmentedValues(value: string, queries: string[]) {
    return value.split(new RegExp(`(${queries.join("|")})`, "gmi"));
  }

  function checkIsSegmentInQuery(segment: string, queries: string[]) {
    return queries.some((query) => query.replaceAll("\\", "") === segment.toLowerCase().trim());
  }

  function check(query: string, value: string) {
    if (!query || !value) return false;

    const queries = getQueries(query);
    const segmentedValues = getSegmentedValues(value, queries);
    let isValueHighlighted = false;

    for (const segment of segmentedValues) {
      isValueHighlighted = checkIsSegmentInQuery(segment, queries);
      if (isValueHighlighted) break;
    }

    return isValueHighlighted;
  }

  function checkForSearchRank(
    query: string,
    value: string,
    dictionaryKey?: keyof ISearchDictionaryGroup,
  ): IHighlightWithCount {
    if (!query || !value) return { isHighlighted: false, isHighlightedBySynonym: false, count: 0 };

    const queries = getQueries(query);
    const segmentedValues = getSegmentedValues(value, queries);
    const countedSegments = new Set();
    let isHighlighted = false;
    let isHighlightedBySynonym = false;
    let count = 0;

    // Calculate count and isHighlighted
    for (const segment of segmentedValues) {
      const lowerCaseSegment = segment.toLowerCase();

      if (checkIsSegmentInQuery(segment, queries) && !countedSegments.has(lowerCaseSegment)) {
        isHighlighted = true;
        countedSegments.add(lowerCaseSegment);
        count++;
      }
    }

    // Calculate isHighlightedBySynonym
    if (dictionaryKey) {
      const dictionaryGroup = SearchService.CachedDictionary;

      for (const query of queries) {
        const synonymWords = dictionaryGroup[dictionaryKey][query];

        if (synonymWords) {
          const segmentedValues = getSegmentedValues(value, synonymWords);

          isHighlightedBySynonym = segmentedValues.some((segment) => checkIsSegmentInQuery(segment, synonymWords));
          if (isHighlightedBySynonym) break;
        }
      }
    }

    return { isHighlighted, isHighlightedBySynonym, count };
  }

  function wrap(value: IHighlightItem) {
    return <span className={wrapClassName}>{value}</span>;
  }

  function peek(query: string, value: string, dictionaryKey?: keyof ISearchDictionaryGroup) {
    if (!value) return "";
    if (!query) return value;

    const key = `${query}_${value}`;
    if (cache.has(key)) {
      return cache.get(key);
    }
    const queries = getQueries(query, dictionaryKey);

    const segmentedValues = getSegmentedValues(value, queries);
    const parsedValue = (
      <>
        {segmentedValues.map((segment, i) => {
          if (checkIsSegmentInQuery(segment, queries)) {
            return (
              <span key={`${segment}_${i}`} className={wrapClassName}>
                {segment}
              </span>
            );
          }
          return segment;
        })}
      </>
    );
    cache.set(key, parsedValue);

    return parsedValue;
  }

  function getQueries(query: string, dictionaryKey?: keyof ISearchDictionaryGroup) {
    let queries = query
      .toLowerCase()
      .trim()
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
      .split(/\s+/)
      .filter((q) => q.length > 2);

    if (dictionaryKey) {
      const dictionaryGroup = SearchService.CachedDictionary;

      queries.forEach((word) => {
        if (dictionaryGroup[dictionaryKey].hasOwnProperty(word)) {
          queries = queries.concat(dictionaryGroup[dictionaryKey][word]);
        }
      });
    }

    return queries;
  }

  function clear() {
    cache.clear();
  }

  return {
    clear,
    peek,
    wrap,
    check,
    checkForSearchRank,
  };
})();
