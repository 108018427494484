import React from "react";

import PageHead from "Components/page-head/page-head";

const withPageHead =
  (WrappedComponent: React.FC<any>, pageName): React.FC =>
  (props) => {
    return (
      <>
        <PageHead page={pageName} />
        <WrappedComponent {...props} />
      </>
    );
  };

export default withPageHead;
