import { useEffect, useState } from "react";

const getCollapsedInitialState = (filterIds, initialValue) => {
  const state = {
    Owner: initialValue,
    TimePeriod: initialValue,
  };

  filterIds.forEach((filterId) => {
    state[filterId] = initialValue;
  });

  return state;
};

const useCollapse = (ids: string[], initialCollapsed: boolean) => {
  const [state, setState] = useState<{ [id: string]: boolean }>({});

  useEffect(() => {
    if (ids.length && !Object.keys(state).length) {
      setState(getCollapsedInitialState(ids, initialCollapsed));
    }
  }, [ids]);

  const toggle = (id: string) => {
    const newState = { ...state };
    newState[id] = !newState[id];
    setState(newState);
  };

  const isAllCollapsed = () => {
    const allValues = Object.values(state);
    if (allValues.length) {
      return allValues.every((a) => a);
    } else {
      return false;
    }
  };

  const toggleAll = () => {
    if (isAllCollapsed()) {
      setState(getCollapsedInitialState(ids, false));
    } else {
      setState(getCollapsedInitialState(ids, true));
    }
  };

  return {
    toggle,
    toggleAll,
    state,
    isAllCollapsed,
  };
};

export default useCollapse;
