import { useField } from "formik";
import React from "react";

import { DropSpot, Text } from "@epam/loveship";

import { IFile } from "SP/reports/reports.types";

import { IFieldCommonProps } from "../field.interface";
import UploadFileFieldAttachment from "./uploadfile-field-attachment";
import UploadFileFieldBadge from "./uploadfile-field-badge";

import "./style.scss";

interface IUploadFileFieldProps extends IFieldCommonProps {
  fileInfo: IFile;
}

const UploadFileField: React.FC<IUploadFileFieldProps> = ({ name, fileInfo }) => {
  const [field, meta, helpers] = useField(name);
  const error = meta.touched ? meta.error : "";

  const uploadFile = async (files: File[]): Promise<void> => {
    await helpers.setValue(files[0]);
    helpers.setTouched(true);
  };

  const handleRemove = (e): void => {
    e.stopPropagation();
    helpers.setValue(null);
  };

  return (
    <>
      <DropSpot
        render={(props) => <UploadFileFieldAttachment {...props} onUploadFile={uploadFile} />}
        onFilesDropped={uploadFile}
      />
      {field.value && <UploadFileFieldBadge onClose={handleRemove} attachedFile={field.value} />}
      {!field.value && fileInfo && <UploadFileFieldBadge canDownload fileInfo={fileInfo} />}
      {!!error && (
        <Text color="fire" size="24">
          {error}
        </Text>
      )}
    </>
  );
};

export default UploadFileField;
