import { useEffect, useRef } from "react";

const useBeforeunload = (fn: ((evt: BeforeUnloadEvent) => any) | string): void => {
  const handlerRef = useRef(fn);

  useEffect(() => {
    handlerRef.current = fn;
  }, [fn]);

  useEffect(() => {
    const handleBeforeunload = (event) => {
      let returnValue;

      if (typeof handlerRef.current === "function") {
        returnValue = handlerRef.current(event);
      }

      // Chrome requires `returnValue` to be set.
      if (event.defaultPrevented) {
        event.returnValue = "";
      }

      if (typeof returnValue === "string") {
        event.returnValue = returnValue;
        return returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, []);
};

export default useBeforeunload;
