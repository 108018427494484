import _map from "lodash/map";
import { call, fork, ForkEffect, put, select, takeLatest } from "redux-saga/effects";

import {
  getFavoritesOrder,
  getFilterRole,
  setFilterRole,
  updateCurrentUserFavoritesOrder,
  waitForAllReports,
  waitForFavorites,
  waitForGroupedTags,
} from "@App/sagas/helpers/utils.sagas";

import { getSortedFavoriteReports } from "Helpers/reportsHelper";
import { dashboardDefaultRole } from "Helpers/utils";
import {
  DashboardActionTypes,
  getDashboardReports as getDashboardReportsAction,
  getDashboardReportsFailure,
  getDashboardReportsSuccess,
  IReorderFavoriteReportsAction,
  ISetFilterRoleAction,
} from "Pages/dashboard/dashboard.actions";
import { filterByFavorites, filterByTags } from "SP/reports/reports.filters";
import { TagGroupNames } from "SP/tags/tags.types";
import { IRootReducerState } from "Store/reducers";

import { isNotDefaultFilterRole } from "./dashboard-domain-filters/dashboard-domain-filters";

export function* getDashboardReports() {
  try {
    const allReports = yield waitForAllReports();
    const favorites = yield waitForFavorites();
    const groupedTags = yield waitForGroupedTags();
    const filterRole = yield select((state: IRootReducerState) => state.dashboard.filterRole);

    const filterRoleId = groupedTags.Role.find((role) => role.name === filterRole)?.id || dashboardDefaultRole.id;
    const roleTagObject = { [TagGroupNames.Role]: [filterRoleId] };
    const isFilteredByRole = isNotDefaultFilterRole(filterRole);
    const favoriteIds = _map(favorites, "reportId");

    const favoriteReports = filterByFavorites(allReports, favoriteIds);

    const favoriteOrders = yield call(getFavoritesOrder);
    const sortedFavoriteReports = getSortedFavoriteReports(favoriteOrders, favoriteReports);

    const roleRelatedReports = isFilteredByRole ? filterByTags(allReports, roleTagObject) : allReports;

    yield put(getDashboardReportsSuccess(sortedFavoriteReports, roleRelatedReports));
  } catch (error) {
    yield put(getDashboardReportsFailure(error));
    throw error;
  }
}

export function* reorderFavoriteReports(action: IReorderFavoriteReportsAction) {
  try {
    const favoriteReportsIds = _map(action.favoriteReports, "id");
    yield call(updateCurrentUserFavoritesOrder, favoriteReportsIds);
  } catch (error) {
    throw error;
  }
}

export function* getRole() {
  yield call(getFilterRole);
}

export function* watchGetRole() {
  yield takeLatest(DashboardActionTypes.GET_FILTER_ROLE, getRole);
}

export function* watchGetReportsByDomain() {
  yield takeLatest(DashboardActionTypes.GET_DASHBOARD_REPORTS_REQUEST, getDashboardReports);
}

export function* requestDashboardUpdate(action: ISetFilterRoleAction) {
  yield call(setFilterRole, action.filterRole);
  yield put(getDashboardReportsAction());
}

export function* watchDashboardUpdates() {
  yield takeLatest([DashboardActionTypes.SET_FILTER_ROLE], requestDashboardUpdate);
}

export function* watchReorderFavoriteReports() {
  yield takeLatest(DashboardActionTypes.REORDER_FAVORITE_REPORTS, reorderFavoriteReports);
}

export default function* dashboardSagas(): Iterator<ForkEffect> {
  yield fork(watchGetReportsByDomain);
  yield fork(watchDashboardUpdates);
  yield fork(watchReorderFavoriteReports);
  yield fork(watchGetRole);
}
