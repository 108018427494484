import _cloneDeep from "lodash/cloneDeep";

import { IReportDTO, IReportEditSavePayload, IReportExtended } from "SP/reports/reports.types";

import { groupedTags, tagsWithGroup } from "./tags-mock-constants";
import { allUsers, currentUser } from "./users-mock-constants";

export const report: IReportExtended = {
  reportName: "Learning analytics for RMs and DMs",
  ownersNames: [currentUser.name, allUsers[1].name],
  modificationDate: "2020-10-08",
  kbLink: "https://fastest.fish/test-files",
  reportLink: "https://kb.epam.com/display/EPMRPRT",
  videoGuideLink:
    "https://epamdev.sharepoint.com/sites/reports-qa/_layouts/15/embed.aspx?UniqueId=cb391255-5e24-4be5-b47e-4cfa5173262c",
  reportUploadFile: null,
  powerBI_ID: "1851919b-225c-4fe7-9812-69881c072ace",
  folderPath: { destinationUrl: "/Documents/DEV", key: "" },
  description:
    "Test description for Learning analytics for RMs and DMs.Though, most of these notes are not fundamentally necessary, they are such that you can use them for a good laugh, at a drinks party or for picking up women or men.",
  owner: [currentUser.id, allUsers[1].id],
  readOnlyUsers: [],
  viewOnlyUsers: [],
  modifierUsers: [],
  contributorUsers: [],
  id: 1331,
  tags: tagsWithGroup.map((tag) => tag.id),
  orderNumber: 10000,
  searchRank: 0,
  certified: true,
  primaryRoles: [],
};

const { tags, id, ...reportForPayload } = _cloneDeep(report);

export const reportPayload: IReportEditSavePayload = {
  ...reportForPayload,
  roles: [groupedTags.Role[0].id, groupedTags.Role[1].id, groupedTags.Role[2].id],
  domains: [groupedTags.Domain[0].id, groupedTags.Domain[1].id, groupedTags.Domain[2].id],
  applications: [groupedTags.Application[0].id, groupedTags.Application[1].id, groupedTags.Application[2].id],
  metrics: [groupedTags.Metric[0].id, groupedTags.Metric[1].id, groupedTags.Metric[2].id],
};

export const reportDTO: Partial<IReportDTO> = {
  DescriptionBI: report.description,
  UserDefinedModificationDate: `${report.modificationDate}T00:00:00Z`,
  ReportName: report.reportName,
  OrderNo: report.orderNumber,
  OwnerId: { results: [currentUser.id, allUsers[1].id] },
  ViewOnlyUsersId: { results: [] },
  ReadOnlyUsersId: { results: [] },
  ModifiersId: { results: [] },
  ContributorsId: { results: [] },
  Id: report.id,
  Certified: report.certified,
  KBLink: report.kbLink,
  ReportLink: report.reportLink,
  TagsBI: report.tags,
  PowerBI_ID: report.powerBI_ID,
};

export const reportUploadFile = new File(["foo"], "foo.txt", {
  type: "text/plain",
});
