import type { IGroupedTags, ITagWithGroup } from "SP/tags/tags.types";

const tagDescriptionPlaceholder = "Tag description is in progress";

export const groupedTags: IGroupedTags = {
  Role: [
    {
      name: "Account Management",
      id: "/Guid(8c6b8856-6d83-4ff2-886e-20b4976b6bc4)/",
      group: "Role",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "BU Heads",
      id: "/Guid(5970a8b3-7300-4c43-9557-6d94a0ac31f3)/",
      group: "Role",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Competencies Heads",
      id: "/Guid(2c31dfec-3f6c-49c7-a7fd-d43687f94743)/",
      group: "Role",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Project Management",
      id: "/Guid(f9f4208c-bf78-4c99-bbe3-8b02bd4438c0)/",
      group: "Role",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Resource Management",
      id: "/Guid(fac8a978-422f-4af8-aacf-fde74138045f)/",
      group: "Role",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Senior Management",
      id: "/Guid(c7391cb9-df65-41a6-ab6c-64edc040aa4c)/",
      group: "Role",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Top Management",
      id: "/Guid(71ee32ae-6995-4f91-896e-ff078cbc85df)/",
      group: "Role",
      description: tagDescriptionPlaceholder,
    },
  ],
  Domain: [
    {
      name: "Business Continuity Planning (BCP)",
      id: "/Guid(6bd76439-50d9-4c29-830b-9c8a45d4ded8)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Communications",
      id: "/Guid(decae1c5-c6ac-4890-8435-43337ac8f0a4)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Delivery",
      id: "/Guid(1f38016c-f7dd-4ebf-a7f6-12fddb11dca2)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Education",
      id: "/Guid(b707b1ab-0f05-4b5c-bd92-17d7bcca56f9)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Finance",
      id: "/Guid(fc016ae3-88d3-4e30-a45c-f99aaea10ecf)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "HRM",
      id: "/Guid(7fca4923-9f3e-4a32-b28a-68c4cf4cb7f7)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Operations",
      id: "/Guid(24c04f25-29ba-402f-bc28-347db40bd7f5)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Sales",
      id: "/Guid(158b6d3d-51a0-41ae-a3dd-3b7fd620c669)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "WPM",
      id: "/Guid(37169818-791b-4747-a547-ceb4c54598b9)/",
      group: "Domain",
      description: tagDescriptionPlaceholder,
    },
  ],
  Application: [
    {
      name: "CRM",
      id: "/Guid(058a7d1c-257a-44d5-951a-3a349e33d69f)/",
      group: "Application",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "CTC",
      id: "/Guid(a932f59a-a82f-4c08-901f-e95a454237a2)/",
      group: "Application",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "PMC",
      id: "/Guid(6f889cbc-1f23-4c44-9516-528c00abefad)/",
      group: "Application",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "UPSA",
      id: "/Guid(ce2d9475-538c-46b4-b0f1-d285fc46d00a)/",
      group: "Application",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "VTS",
      id: "/Guid(9b4a8f75-4871-41a6-b176-bc8cfb4eebdd)/",
      group: "Application",
      description: tagDescriptionPlaceholder,
    },
  ],
  Metric: [
    {
      name: "Daily",
      id: "/Guid(5cd4ae04-c154-40e2-b529-a2b209a4d605)/",
      group: "Metric",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Monthly",
      id: "/Guid(818324df-d486-4f08-bd1e-858a47085190)/",
      group: "Metric",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Quarterly",
      id: "/Guid(3f600459-3cf4-48fe-b28c-ef41ec4ebec0)/",
      group: "Metric",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Weekly",
      id: "/Guid(0fd6c5b1-9a08-4c0f-99ec-0b8596f5c946)/",
      group: "Metric",
      description: tagDescriptionPlaceholder,
    },
    {
      name: "Yearly",
      id: "/Guid(69805520-1d69-4572-9d1c-541b5dd96caf)/",
      group: "Metric",
      description: tagDescriptionPlaceholder,
    },
  ],
};

export const allTags = (() => {
  let result: ITagWithGroup[] = [];

  for (const groupTag in groupedTags) {
    result = result.concat(groupedTags[groupTag]);
  }

  return result;
})();

export const tagsWithGroup = [
  groupedTags.Role[0],
  groupedTags.Role[1],
  groupedTags.Role[2],
  groupedTags.Domain[0],
  groupedTags.Domain[1],
  groupedTags.Domain[2],
  groupedTags.Application[0],
  groupedTags.Application[1],
  groupedTags.Application[2],
  groupedTags.Metric[0],
  groupedTags.Metric[1],
  groupedTags.Metric[2],
];
