import _noop from "lodash/noop";
import moment from "moment";
import React, { FC } from "react";

import { ReactComponent as certificateIcon } from "@App/assets/warning_fill.svg";
import { ReactComponent as favIcon } from "@epam/assets/icons/common/fav-rates-star-18.svg";
import { ReactComponent as closeIcon } from "@epam/assets/icons/common/navigation-close-18.svg";
import { IconContainer } from "@epam/loveship";

import Badge, { badgeCN, BadgeColors } from "Components/badge";
import { BadgeDescriptionTooltip } from "Components/badges/badges-description-tooltip";
import { isUsernamesFromQuery, omitEmptyDate } from "Helpers/utils";
import { useFilters } from "Hooks/useFilters";
import { DashboardFilterTabs } from "Pages/dashboard/dashboard-domain-filters/dashboard-domain-filters";
import { isAtLeastOneFilterPresent } from "Pages/reports-browser/reports-browser-filter/report-browsers-filter-sections";
import type { IUser } from "SP/users/users.types";

import type { ITimePeriod } from "../reports-filters.reducer";

const GroupColors = {
  Role: undefined,
  Domain: BadgeColors.blue,
  Application: BadgeColors.amber,
  Metric: BadgeColors.grass,
};

const TimePeriodLabels: ITimePeriod = {
  fromDate: "From",
  toDate: "To",
};

// We render owner names from query until their data is loaded from server, to avoid CLS!
function isShowOwnersPlaceholder(selectedOwnersToRender: IUser[], selectedOwners: number[]) {
  return selectedOwnersToRender.length === 0 && isUsernamesFromQuery(selectedOwners);
}

function getTimePeriodToRender(timePeriod: ITimePeriod) {
  return timePeriod ? Object.keys(omitEmptyDate(timePeriod)) : [];
}

const ReportBrowsersFilterBadges: FC = () => {
  const {
    selectedTags,
    selectedOwners,
    filterBy,
    timePeriod,
    getSelectedTagsToRender,
    getUsersByIds,
    removeSelectedFilter,
    removeSelectedOwner,
    removeFilterByItem,
    removeTimePeriodItem,
  } = useFilters();

  if (!isAtLeastOneFilterPresent(selectedTags, selectedOwners, filterBy, timePeriod)) {
    return null;
  }

  const selectedTagsToRender = getSelectedTagsToRender(Array.from(selectedTags));
  const selectedOwnersToRender = getUsersByIds(selectedOwners);
  const timePeriodToRender = getTimePeriodToRender(timePeriod);

  return (
    <div className="reports-browser__filter-badges flex flex-wrap">
      {Array.isArray(filterBy) &&
        filterBy.map((f) => (
          <Badge key={f} type={f} icon={closeIcon} onIconClick={removeFilterByItem(f)}>
            <IconContainer cx={badgeCN("icon")} icon={f === DashboardFilterTabs.favorite ? favIcon : certificateIcon} />
            <span className={badgeCN("label")}>{f}</span>
          </Badge>
        ))}
      {selectedOwnersToRender.map((owner) => (
        <Badge key={owner.id} color={BadgeColors.white} icon={closeIcon} onIconClick={removeSelectedOwner(owner.id)}>
          <span className={badgeCN("label")}>{owner.name}</span>
        </Badge>
      ))}
      {isShowOwnersPlaceholder(selectedOwnersToRender, selectedOwners) &&
        selectedOwners.map((ownerName) => (
          <Badge key={ownerName} color={BadgeColors.white} icon={closeIcon} onIconClick={_noop}>
            <span className={badgeCN("label")}>{ownerName}</span>
          </Badge>
        ))}
      {selectedTagsToRender.map((filter) => (
        <BadgeDescriptionTooltip key={filter.id} description={filter.description} size="large">
          <Badge color={GroupColors[filter.group]} icon={closeIcon} onIconClick={removeSelectedFilter(filter.id)}>
            <span className={badgeCN("label")}>{filter.name}</span>
          </Badge>
        </BadgeDescriptionTooltip>
      ))}
      {timePeriodToRender.map((dateKey) => (
        <Badge
          key={dateKey}
          color={BadgeColors.white}
          icon={closeIcon}
          onIconClick={removeTimePeriodItem(dateKey as keyof ITimePeriod)}
        >
          <span className={badgeCN("label")}>
            {`${TimePeriodLabels[dateKey]} - ${moment(timePeriod[dateKey]).format("MM/DD/YYYY")}`}
          </span>
        </Badge>
      ))}
    </div>
  );
};

export default ReportBrowsersFilterBadges;
