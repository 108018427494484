import type {
  IDeleteReportSuccessAction,
  IGetReportFileUrlFailureAction,
  IGetReportFileUrlSuccessAction,
  IGetReportSuccessAction,
} from "Pages/report/report.actions";
import type { IReportExtended } from "SP/reports/reports.types";
import type { IUser } from "SP/users/users.types";

export enum ReportsActionTypes {
  GET_ALL_REPORTS_REQUEST = "[REPORTS] GET ALL REPORTS REQUEST",
  GET_ALL_REPORTS_SUCCESS = "[REPORTS] GET ALL REPORTS SUCCESS",
  GET_ALL_REPORTS_FAILURE = "[REPORTS] GET ALL REPORTS FAILURE",

  GET_FILTERED_REPORTS_REQUEST = "[REPORTS] GET FILTERED REPORTS REQUEST",
  GET_FILTERED_REPORTS_SUCCESS = "[REPORTS] GET FILTERED REPORTS SUCCESS",
  GET_FILTERED_REPORTS_FAILURE = "[REPORTS] GET FILTERED REPORTS FAILURE",

  SET_ALL_REPORTS_OWNERS = "[REPORTS] SET ALL REPORTS OWNERS",

  CHANGE_VIEW_MODE = "[REPORTS] CHANGE VIEW MODE",
}

// GET ALL REPORTS REQUEST START
export interface IGetReportsRequestAction {
  type: ReportsActionTypes.GET_ALL_REPORTS_REQUEST;
}

export function getReports(): IGetReportsRequestAction {
  return {
    type: ReportsActionTypes.GET_ALL_REPORTS_REQUEST,
  };
}

export interface IGetReportsSuccessAction {
  type: ReportsActionTypes.GET_ALL_REPORTS_SUCCESS;
  reports: IReportExtended[];
}

export function getReportsSuccess(reports: IReportExtended[]): IGetReportsSuccessAction {
  return {
    type: ReportsActionTypes.GET_ALL_REPORTS_SUCCESS,
    reports,
  };
}

export interface IGetReportsFailureAction {
  type: ReportsActionTypes.GET_ALL_REPORTS_FAILURE;
  error: string;
}

export function getReportsFailure(error: Error): IGetReportsFailureAction {
  return {
    type: ReportsActionTypes.GET_ALL_REPORTS_FAILURE,
    error: error.message,
  };
}
// GET ALL REPORTS REQUEST END

// GET FILTERED REPORTS REQUEST START
export interface IGetFilteredReportsRequestAction {
  type: ReportsActionTypes.GET_FILTERED_REPORTS_REQUEST;
}

export function getFilteredReports(): IGetFilteredReportsRequestAction {
  return {
    type: ReportsActionTypes.GET_FILTERED_REPORTS_REQUEST,
  };
}

export interface IGetFilteredReportsSuccessAction {
  type: ReportsActionTypes.GET_FILTERED_REPORTS_SUCCESS;
  reports: IReportExtended[];
}

export function getFilteredReportsSuccess(reports: IReportExtended[]): IGetFilteredReportsSuccessAction {
  return {
    type: ReportsActionTypes.GET_FILTERED_REPORTS_SUCCESS,
    reports,
  };
}

export interface IGetFilteredReportsFailureAction {
  type: ReportsActionTypes.GET_FILTERED_REPORTS_FAILURE;
  error: string;
}

export function getFilteredReportsFailure(error: Error): IGetFilteredReportsFailureAction {
  return {
    type: ReportsActionTypes.GET_FILTERED_REPORTS_FAILURE,
    error: error.message,
  };
}
// GET FILTERED REPORTS REQUEST END

export type IReportViewMode = "table" | "cards";
export interface IChangeReportViewAction {
  type: ReportsActionTypes.CHANGE_VIEW_MODE;
  payload: IReportViewMode;
}

export function changeReportViewMode(payload: IReportViewMode): IChangeReportViewAction {
  return {
    type: ReportsActionTypes.CHANGE_VIEW_MODE,
    payload,
  };
}

export interface ISetAllReportsOwnersAction {
  type: ReportsActionTypes.SET_ALL_REPORTS_OWNERS;
  payload: IUser[];
}

export function setAllReportsOwners(users: IUser[]): ISetAllReportsOwnersAction {
  return {
    type: ReportsActionTypes.SET_ALL_REPORTS_OWNERS,
    payload: users,
  };
}

export type IReportsActionTypes =
  | IGetReportsRequestAction
  | IGetReportsSuccessAction
  | IGetReportsFailureAction
  | IGetFilteredReportsRequestAction
  | IGetFilteredReportsSuccessAction
  | IGetFilteredReportsFailureAction
  | IChangeReportViewAction
  | IGetReportFileUrlSuccessAction
  | IGetReportFileUrlFailureAction
  | IGetReportSuccessAction
  | IDeleteReportSuccessAction
  | ISetAllReportsOwnersAction;
