import React from "react";

import { ReactComponent as uploadIcon } from "@App/assets/upload-cloud.svg";
import { DropSpotRenderParams, FlexRow, IconButton, LinkButton, Text, UploadFileToggler } from "@epam/loveship";
import { cx } from "@epam/uui";

interface IUploadFileFieldAttachmentProps extends DropSpotRenderParams {
  onUploadFile: (f: File[]) => void;
}

const UploadFileFieldAttachment: React.FC<IUploadFileFieldAttachmentProps> = ({
  isDragStart,
  eventHandlers,
  onUploadFile,
}) => {
  return (
    <div className="upload-file-field-attachment">
      <div
        className={cx(
          "upload-file-field-attachment__block",
          isDragStart && "upload-file-field-attachment__block--drop-start",
        )}
      >
        {isDragStart ? (
          <div {...eventHandlers} className="upload-file-field-attachment__drop-area">
            <div className={"upload-file-field-attachment__drop-text"}>Drop files to attach</div>
          </div>
        ) : (
          <FlexRow spacing={undefined}>
            <IconButton cx="upload-file-field-attachment__upload-icon" icon={uploadIcon} />
            <Text font="sans-semibold">{"Drop files to attach or"}</Text>
            <UploadFileToggler
              onFilesAdded={onUploadFile}
              render={(props) => <LinkButton font="sans-semibold" {...props} caption=" browse" />}
            />
          </FlexRow>
        )}
      </div>
    </div>
  );
};

export default UploadFileFieldAttachment;
