import moment from "moment";
import React, { useMemo } from "react";

import { ReactComponent as certificateIcon } from "@App/assets/warning_fill.svg";
import { FlexCell, FlexRow, FlexSpacer, IconButton, Tooltip } from "@epam/loveship";

import { BadgeColors } from "Components/badge/badge";
import ExpandableText from "Components/expandable-text/expandableText";
import FavoriteButton from "Components/favorite-button";
import { Owners } from "Components/owners/owners";
import { ReportButtons } from "Components/report-buttons/report-buttons";
import ReportLink from "Components/report-link";
import { TimingSpan } from "Components/timing-elements";
import type { IMeasureElement } from "Components/virtual-list";
import withNaming from "Helpers/bemClassname";
import highlight from "Helpers/highlight";
import { htmlDecode, stripTags } from "Helpers/utils";
import { useGlobalSearchData } from "Hooks/useGlobalSearch";
import { useTagsData } from "Hooks/useTags";
import { handleRoles } from "Pages/report/report-details/report-details-helpers";
import { CERTIFIED_MARK_TOOLTIP } from "SP/reports/reports.constants";
import type { IReportExtended } from "SP/reports/reports.types";

import BadgesInline from "./badges-inline";
import LabeledField from "./labeled-field";

import "./style.scss";

const cn = withNaming("report-card");

const ReportCard: React.FC<IReportExtended & IMeasureElement> = ({
  id,
  certified,
  reportName,
  modificationDate,
  description,
  ownersNames,
  tags,
  kbLink,
  videoGuideLink,
  reportLink,
  measureElement,
  primaryRoles,
}) => {
  const { searchText } = useGlobalSearchData();
  const { getReportTags } = useTagsData();

  const { applications, domains, roles, metrics } = getReportTags(tags);
  const rolesBadges = useMemo(() => handleRoles(roles, primaryRoles), [roles, primaryRoles]);

  const decodedDescription = stripTags(htmlDecode(description));

  return (
    <div ref={measureElement} className={cn("")}>
      <FlexRow>
        <FlexCell width={20} cx={cn("icons")}>
          <FavoriteButton className="star-icon" reportId={id} />
          {certified ? (
            <Tooltip placement="bottom" content={CERTIFIED_MARK_TOOLTIP}>
              <IconButton cx="certified-icon" icon={certificateIcon} color="sky" />
            </Tooltip>
          ) : null}
        </FlexCell>
        <FlexCell cx={cn("content")} grow={1}>
          <FlexRow>
            <ReportLink
              id={id}
              className={cn("report-link")}
              lineHeight="18"
              link={reportLink}
              name={reportName}
              caption={highlight.peek(searchText, reportName, "reportNameDictionary")}
            />
            <ReportButtons
              reportId={id}
              reportName={reportName}
              kbLink={kbLink}
              videoGuideLink={videoGuideLink}
              showVideoGuide
            />
            <FlexSpacer />
          </FlexRow>
          <FlexRow>
            <ExpandableText
              maxHeight={75}
              content={
                <TimingSpan elementtiming="report-description">
                  {highlight.peek(searchText, decodedDescription, "descriptionDictionary")}
                </TimingSpan>
              }
            />
          </FlexRow>
          <FlexRow cx={cn("owner-date-row")}>
            {ownersNames?.length ? (
              <LabeledField label="Owner" isBadges={true}>
                <Owners ownersNames={ownersNames} reportName={reportName} />
              </LabeledField>
            ) : null}
            {modificationDate && (
              <LabeledField label="Mod. date">
                {modificationDate && moment.utc(modificationDate).format("MM/DD/YYYY")}
              </LabeledField>
            )}
          </FlexRow>
          <FlexRow cx={cn("badges-row")}>
            <BadgesInline groupName="Roles" data={rolesBadges} searchText={searchText} />
            <BadgesInline
              groupName="Business Domains"
              color={BadgeColors.blue}
              data={domains}
              searchText={searchText}
            />
            <BadgesInline groupName="Apps" color={BadgeColors.amber} data={applications} searchText={searchText} />
            <BadgesInline groupName="Metrics" color={BadgeColors.grass} data={metrics} searchText={searchText} />
          </FlexRow>
        </FlexCell>
      </FlexRow>
    </div>
  );
};

export default ReportCard;
