import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";

import { ReactComponent as loaderIcon } from "@epam/assets/icons/loaders/circle_loader_30 .svg";
import { IconContainer } from "@epam/loveship";

import { Layout } from "Components/layout";
import withNaming from "Helpers/bemClassname";
import highlight from "Helpers/highlight";
import { useMobileData, useReportsData } from "Hooks/index";
import { usePrimaryRoleModal } from "Hooks/usePrimaryRoleModal";
import { useReportView } from "Hooks/useReports";
import type { IRootReducerState } from "Store/reducers";

import ReportsBrowserSearch from "./report-browser-search/report-browser-search";
import ReportView from "./report-view/report-view";
import ReportViewToggle from "./report-view/report-view-toggle";
import ReportBrowsersFilterBadges from "./reports-browser-filter/report-browsers-filter-badges";
import ReportsBrowserFilter from "./reports-browser-filter/reports-browser-filter";

import "./reports-browser.scss";

const ReportsBrowser: React.FC<RouteComponentProps> = () => {
  const cn = withNaming("reports-browser");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const { viewMode } = useReportView();
  const { isMobile } = useMobileData();
  const { loading: reportsLoading } = useReportsData();
  const tagsLoading = useSelector<IRootReducerState, boolean>((state) => state.tags.loading);
  usePrimaryRoleModal();

  const isLoading = reportsLoading || tagsLoading;
  const newViewMode = isMobile ? "cards" : viewMode;

  useEffect(() => {
    return () => {
      highlight.clear();
    };
  }, []);

  return (
    <Layout>
      <div className={cn("wrapper", ["flex items-start justify-between"])}>
        <ReportsBrowserFilter onToggle={setIsFiltersOpen} viewMode={newViewMode} />
        <ReportsBrowserSearch />
        {!isMobile && <ReportViewToggle />}
      </div>
      <div
        className={cn("content-wrapper", { [newViewMode]: !!newViewMode })}
        style={{ maxWidth: !isMobile && isFiltersOpen && newViewMode === "cards" ? "calc(100% - 357px)" : "100%" }}
      >
        <ReportBrowsersFilterBadges />
        {isLoading ? (
          <div className={cn("loader")}>
            <IconContainer
              cx={cn("loader-icon", { isCardMode: !isMobile && newViewMode === "cards" })}
              icon={loaderIcon}
            />
          </div>
        ) : (
          <ReportView viewMode={newViewMode} />
        )}
      </div>
    </Layout>
  );
};

export default ReportsBrowser;
