import { FormikProps } from "formik";
import { Dispatch, SetStateAction } from "react";

import { IReportEditSavePayload, IReportPermissions } from "SP/reports/reports.types";

export interface IFormReport extends IReportEditSavePayload {
  id: number;
}

export type IFormRef = {
  current: FormikProps<IReportEditSavePayload> | null;
};
export interface IAddReportFormProps {
  formRef: IFormRef;
  isValidForm: boolean;
  id?: number;
  isEdit?: boolean;
  initialValues: IReportEditSavePayload;
  onSetValidForm: Dispatch<SetStateAction<boolean>>;
  onAfterSubmit: (reportName: string) => void;
  onReportNameSet: Dispatch<SetStateAction<string>>;
  onSetFormDirty: (isDirty: boolean) => void;
  permissions: IReportPermissions;
}

export enum ReportFormFieldTypes {
  REPORT_LINK = "reportLink",
  REPORT_UPLOAD_FILE = "reportUploadFile",
  REPORT_FOLDER_PATH = "folderPath",
  REPORT_OWNER = "owner",
  REPORT_VIEW_ONLY_USERS = "viewOnlyUsers",
  REPORT_READ_ONLY_USERS = "readOnlyUsers",
  REPORT_MODIFIER_USERS = "modifierUsers",
  REPORT_CONTRIBUTOR_USERS = "contributorUsers",
  REPORT_PRIMARY_ROLES = "primaryRoles",
  REPORT_ROLES = "roles",
}

export interface IUserSelectFieldProps {
  fieldName: ReportFormFieldTypes;
  placeholder?: string;
}

export enum Info {
  ModificationDate = "Report structure Modification date",
  Owner = "The team or person that owns the report and which can be contacted for consultation",
  LinkToKb = "Link to a Confluence page with a detailed report description",
  ReportVideoGuide = "Link to the video guide of the Report with detailed instructions on how to use it",
  ReportLocation = "Folder in SharePoint where Report will be stored",
  LinkToTheReport = "Link to the report that is located on the external resources (Power BI or Excel)",
  UploadReport = "Upload a report to SharePoint if it is not located on the external resources",
  PowerBiId = "ID of the Power BI report",
  Description = "A short description of the report in 2-3 sentences",
  Applications = "Select those EPAM applications which data is exposed in this report",
  Metrics = "Select metrics that are displayed in the Report",
  BusinessDomains = "Select one or several Business Domains covered by the report",
  Roles = "Select employee roles which are applicable for this report",
  Permissions = "Permissions for report configuration in reports.epam.com",
  Certified = "Enable the toggle switch if the report has been certified based on the BI Center of Excellence requirements",
  DisabledCertified = "If you require to mark report as Certified, please contact OrgBICenterOfExcellence@epam.com",
}
