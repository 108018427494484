import moment from "moment";
import React, { FC } from "react";

import { LinkButton, Text, Tooltip } from "@epam/loveship";

import withNaming from "Helpers/bemClassname";
import { handleShortLink, truncateString } from "Helpers/utils";
import { INewsSavePayload } from "SP/news/news.types";

interface IDashboardNewsFeedItemProps extends INewsSavePayload {
  date: string;
  onOpenView: () => void;
}

const DashboardNewsFeedItem: FC<IDashboardNewsFeedItemProps> = ({
  newsTitle,
  description,
  reportLink,
  date,
  onOpenView,
}) => {
  const cn = withNaming("dashboard-news-feed");

  return (
    <div className={cn("item", ["flex", "flex-col", "items-start"])}>
      <Text cx={cn("item-date")} fontSize="12" font="sans" lineHeight="18">
        {moment.utc(date).format("MM/DD/YYYY")}
      </Text>
      <Tooltip content={newsTitle} placement="auto">
        <LinkButton
          cx={cn("item-title", ["unstyled-link"])}
          fontSize="16"
          font="sans-semibold"
          lineHeight="24"
          caption={truncateString(newsTitle, 90)}
          onClick={onOpenView}
        />
      </Tooltip>
      <Tooltip cx="tooltip-whitespace-pre-line" content={description} placement="auto">
        <Text cx={cn("item-description")}>{truncateString(description, 360)}</Text>
      </Tooltip>
      {reportLink && (
        <LinkButton
          cx={cn("item-link", ["unstyled-link"])}
          target="_blank"
          font="sans"
          lineHeight="24"
          caption="View report"
          href={handleShortLink(reportLink)}
        />
      )}
    </div>
  );
};

export default DashboardNewsFeedItem;
