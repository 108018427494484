import { MutableRefObject } from "react";
import * as Yup from "yup";

import { MAX_FILE_SIZE } from "SP/reports/reports.constants";
import { IReportEditSavePayload } from "SP/reports/reports.types";

const REQUIRED_MESSAGE = "This field is required to fill";
const INCORRECT_URL_MESSAGE = "The link must be correct";
const INCORRECT_UUID_MESSAGE = "The ID must be correct";
const INCORRECT_VIDEO_GUIDE_MESSAGE = "The embedded url should be either from Video Portal or SharePoint Stream";
const INCORRECT_SYMBOLS_MESSAGE = "It's not allowed to use next symbols: % # ? \\ / < > : | ' \" * +";
const FILE_IS_TOO_LARGE_MESSAGE = "File is too large";
export const REPORT_WITH_THIS_NAME_EXISTS = "Report with the same name already exists. Please try another one";

const videoGuideRegex =
  /^https:\/\/epam(.+)?\.sharepoint\.com\/(.+)\/(.+)\/_layouts\/15\/embed\.aspx\?UniqueId=[a-zA-Z0-9_-].+|^\/\/videoportal\.epam\.com\/video\/iframe\.html\?video=[a-zA-Z0-9_-].+/i;

const getFileNameErrorMessage = (oldFileName: string) =>
  `File name should correspond to the old name (${oldFileName}). Please, create the new report if you want to upload different file.`;

const generateAddReportSchema = (
  mode: number,
  attachedFileName: string,
  isUniqueNameRef: MutableRefObject<boolean>,
) => {
  return Yup.object<IReportEditSavePayload>().shape(
    {
      reportName: Yup.string()
        .trim()
        .matches(/^[^%#?\/\\<>:|'"*+]+$/g, INCORRECT_SYMBOLS_MESSAGE)
        .required(REQUIRED_MESSAGE)
        .test("fileName", REPORT_WITH_THIS_NAME_EXISTS, () => isUniqueNameRef.current),
      owner: Yup.array(Yup.string()).min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
      modificationDate: Yup.string().nullable().required(REQUIRED_MESSAGE),
      kbLink: Yup.string().url(INCORRECT_URL_MESSAGE).required(REQUIRED_MESSAGE),
      reportLink: Yup.lazy(() => {
        if (mode !== 1) {
          return Yup.mixed().notRequired();
        }
        return Yup.string().required(REQUIRED_MESSAGE);
      }),

      reportUploadFile: Yup.lazy(() => {
        if (mode !== 2) {
          return Yup.mixed().notRequired();
        }

        const fileDefaultValidation = Yup.mixed()
          .required("File is required to attach")
          .test(
            "fileSize",
            FILE_IS_TOO_LARGE_MESSAGE,
            (value) => (attachedFileName && value === null) || value?.size <= MAX_FILE_SIZE,
          );

        if (attachedFileName) {
          return fileDefaultValidation
            .test(
              "fileName",
              getFileNameErrorMessage(attachedFileName),
              (value) => value === null || value?.name === attachedFileName,
            )
            .notRequired();
        }

        return fileDefaultValidation;
      }),
      folderPath: Yup.lazy(() => {
        return Yup.mixed()
          .test("destinationUrlChecking", "Report location is required", (value) => !!value?.destinationUrl)
          .required();
      }),
      description: Yup.string().trim().required(REQUIRED_MESSAGE),
      applications: Yup.array(Yup.string()).min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
      domains: Yup.array(Yup.string()).min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
      metrics: Yup.array(Yup.string()).min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
      roles: Yup.array(Yup.string()).min(1, REQUIRED_MESSAGE).required(REQUIRED_MESSAGE),
      powerBI_ID: Yup.string()
        .nullable()
        .notRequired()
        .when("powerBI_ID", {
          is: (value: string) => value?.length,
          then: (rule: Yup.StringSchema) => rule.uuid(INCORRECT_UUID_MESSAGE),
        }),
      videoGuideLink: Yup.string()
        .nullable()
        .notRequired()
        .when("videoGuideLink", {
          is: (value: string) => value?.length,
          then: (rule: Yup.StringSchema) => rule.matches(videoGuideRegex, INCORRECT_VIDEO_GUIDE_MESSAGE),
        }),
    },
    [
      ["powerBI_ID", "powerBI_ID"],
      ["videoGuideLink", "videoGuideLink"],
    ],
  );
};

export default generateAddReportSchema;
