import produce from "immer";
import { Reducer } from "redux";

import { IReportsFiltersActionTypes, ReportsFiltersActionTypes } from "Pages/reports-browser/reports-filters.actions";

export type ITimePeriod = {
  fromDate?: string;
  toDate?: string;
};

export interface IReportsFilterState {
  selectedTags: Set<string>;
  selectedOwners: number[];
  timePeriod: ITimePeriod;
  filterBy: string[];
  search: string;
  preselectedRole: string;
}

const initialState: IReportsFilterState = {
  selectedTags: new Set(),
  selectedOwners: [],
  timePeriod: null,
  filterBy: null,
  search: null,
  preselectedRole: "",
};

const reportsFilterReducer: Reducer<IReportsFilterState, IReportsFiltersActionTypes> = produce((draft, action) => {
  switch (action.type) {
    case ReportsFiltersActionTypes.SET_FILTER_TAGS:
      draft.selectedTags = new Set(action.tagIds);
      break;
    case ReportsFiltersActionTypes.RESET_FILTER_TAGS:
      draft.selectedTags = new Set();
      break;
    case ReportsFiltersActionTypes.SET_SEARCH_TEXT:
      draft.search = action.search || null;
      break;

    case ReportsFiltersActionTypes.SET_FILTER_BY:
      draft.filterBy = action.filterBy;
      break;

    case ReportsFiltersActionTypes.SET_ALL_FILTERS:
      draft.selectedOwners = action.filters.selectedOwners;
      draft.selectedTags = new Set(action.filters.selectedTags);
      draft.filterBy = action.filters.filterBy;
      draft.timePeriod = action.filters.timePeriod;
      draft.search = action.filters.searchText;
      break;

    case ReportsFiltersActionTypes.SET_FILTER_OWNERS:
      draft.selectedOwners = action.ownerIds;
      break;

    case ReportsFiltersActionTypes.SET_FILTER_TIME_PERIOD:
      draft.timePeriod = action.timePeriod;
      break;

    case ReportsFiltersActionTypes.SET_PRESELECTED_ROLE:
      draft.preselectedRole = action.value;
      break;
  }
}, initialState);

export default reportsFilterReducer;
