import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import createSagaMiddleware, { SagaMiddleware } from "redux-saga";

import handleError from "Helpers/errorHandler";

import rootSaga from "../sagas";
import { rootReducer } from "./reducers";

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => handleError(err, store),
}) as SagaMiddleware<RootState>;
const composeEnhancer = composeWithDevTools({});

const store = createStore(rootReducer, {}, composeEnhancer(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
