import React, { ReactElement, useRef } from "react";

import { Tooltip as TooltipType } from "@epam/uui-components";

import { generateCopyEmailsString, getValidEmails } from "Helpers/emailsCopy";
import { EmptyRC } from "Helpers/utils";
import { useCopyText } from "Hooks/useCopyText";
import { report } from "Mock/report-mock-constants";

import { Select } from "./select";
import { CopyIcon } from "./select-copy-icon";
import { NotFoundBlock } from "./select-not-found";
import type { ISelectEmailsCopyProps } from "./select.types";

export function SelectWithEmailsCopy<IType>({
  error,
  value,
  options,
  placeholder,
  loading,
  searchLoading,
  onValueChange,
  onSearchChange,
  onCheckEmails,
}: ISelectEmailsCopyProps<IType>): ReactElement {
  const tooltipRef = useRef<TooltipType>(null);
  const { isCopied, handleCopyUrl } = useCopyText(tooltipRef);

  const handleSearchChange = (searchValue) => {
    if (getValidEmails(searchValue).length > 1) return;
    onSearchChange?.(searchValue);
  };

  const handleCheckEmails = (emails: string[], onClose) => {
    if (process.env.UNIT_TEST) {
      onValueChange(value.concat(report.owner as unknown as IType));
      onClose();
    } else {
      onCheckEmails(emails, (foundUserIds) => {
        onValueChange([...new Set(value.concat(foundUserIds as unknown as IType))]);
        onClose();
      });
    }
  };

  const handleCopy = (e) => {
    const copyEmailsString = generateCopyEmailsString<IType>(options, value);
    handleCopyUrl(e, copyEmailsString);
  };

  return (
    <Select<IType>
      value={value}
      error={error}
      loading={loading}
      options={options}
      placeholder={placeholder}
      onValueChange={onValueChange}
      onSearchChange={handleSearchChange}
      renderCopyIcon={({ cx }) => (
        <CopyIcon
          cx={cx}
          tooltipRef={tooltipRef}
          tooltipContent={isCopied ? "Emails are copied to your clipboard" : "Copy selected options"}
          onCopy={handleCopy}
        />
      )}
      renderNotFound={(props) => (
        <NotFoundBlock
          checkCaption="Check e-mails"
          searchLoading={searchLoading}
          onCheck={handleCheckEmails}
          getData={getValidEmails}
          {...props}
        />
      )}
      renderFooter={EmptyRC}
    />
  );
}
