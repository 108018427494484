import React, { FC } from "react";

import { Checkbox } from "@epam/loveship";

import { ALL, IFilterSectionItem, IReportBrowsersFilterProps } from "./reports-browser-filter.types";

const allItemsSelected = (sectionItems: IFilterSectionItem[], selectedItems: string[]) => {
  return sectionItems.length === selectedItems.length;
};

const ReportBrowsersFilterCheckboxGroup: FC<IReportBrowsersFilterProps> = ({
  sectionItems,
  selectedItems,
  onSelectFilters: handleSelectFilters,
  showToggleAll,
}) => {
  const toggleAll = () => () => {
    const value = allItemsSelected(sectionItems, selectedItems) ? [] : sectionItems.map((item) => item.id);
    handleSelectFilters(value);
  };

  const getCheckboxHandler = (tagId: string) => (value) => {
    const newSelectedItems = [...selectedItems];

    if (value) {
      newSelectedItems.push(tagId);
    } else {
      const selectedTagIndex = newSelectedItems.indexOf(tagId);
      newSelectedItems.splice(selectedTagIndex, 1);
    }

    handleSelectFilters(newSelectedItems);
  };

  return (
    <>
      {showToggleAll && (
        <Checkbox
          key={ALL.id}
          cx="checkbox-item"
          size="12"
          label={ALL.label}
          value={!!selectedItems.length}
          onValueChange={toggleAll()}
          indeterminate={!selectedItems.length ? undefined : !allItemsSelected(sectionItems, selectedItems)}
        />
      )}
      {sectionItems
        .sort((a, b) => a.order - b.order)
        .map(({ id, name }) => (
          <Checkbox
            key={id}
            cx="checkbox-item"
            size="12"
            label={name}
            onValueChange={getCheckboxHandler(id)}
            value={selectedItems.indexOf(id) !== -1}
          />
        ))}
    </>
  );
};

export default ReportBrowsersFilterCheckboxGroup;
