import React, { FC, useCallback, useMemo, useState } from "react";

import { svc } from "@App/services";
import { Button, FlexSpacer, LabeledInput, PickerInput, Text } from "@epam/loveship";
import { IModal, useArrayDataSource } from "@epam/uui";

import Modal from "Components/modal";
import withNaming from "Helpers/bemClassname";
import { handleRolesData } from "Helpers/utils";
import { useAppSelector } from "Hooks/useAppSelector";
import { useTagsData } from "Hooks/useTags";
import { useUsersData } from "Hooks/useUsers";
import { useDashboardReportsData, useDashboardReportsMethods } from "Pages/dashboard/dashboard.hooks";

import "./primary-role-modal.scss";

interface IPromaryRoleModalProps {
  modalProps: IModal<string>;
}
const cn = withNaming("primary-role-modal");

export const PrimaryRoleModal: FC<IPromaryRoleModalProps> = ({ modalProps }) => {
  const filterRole = useAppSelector((state) => state.dashboard.filterRole);
  const { primaryRoleModalValue } = useDashboardReportsData();
  const [roleValue, setRoleValue] = useState(primaryRoleModalValue);
  const { setFilterRole, setPrimaryModalValue } = useDashboardReportsMethods();
  const { roles } = useTagsData();

  const {
    currentUser: { name: fullName },
  } = useUsersData();
  const userName = useMemo(() => fullName?.split(" ")[0], [fullName]);

  const dataSource = useArrayDataSource({
    items: handleRolesData(roles),
  });

  const setRoleAndClose = (value: string) => {
    setFilterRole(value);
    setPrimaryModalValue("");
    modalProps.abort();
  };

  const handleClose = useCallback(() => {
    setRoleAndClose(filterRole);
  }, [filterRole]);

  const handleContinue = useCallback(() => {
    setRoleAndClose(roleValue);
  }, [roleValue]);

  return (
    <Modal
      disallowClickOutside
      blockerShadow="dark"
      className={cn("")}
      title="Role Selection"
      onClose={handleClose}
      footer={
        <>
          <FlexSpacer />
          <Button cx="btn btn--grass" isDisabled={false} color="grass" caption="Continue" onClick={handleContinue} />
        </>
      }
      modalProps={modalProps}
      width={580}
      footerBorderTop={false}
    >
      <Text fontSize="14" lineHeight="24" color="night700" cx={cn("info")}>
        Hello{userName ? ` ${userName}` : ""}! Reports Platform will show the recommendations based on your current
        role. You can select another role at any time.
      </Text>
      <LabeledInput label="Your Role">
        <PickerInput
          disableClear
          searchPosition="input"
          entityName="Role"
          minBodyWidth={278}
          dropdownPlacement="bottom-end"
          dataSource={dataSource}
          value={roleValue}
          onValueChange={setRoleValue}
          getName={(item) => item.label}
          selectionMode="single"
          valueType={"id"}
        />
      </LabeledInput>
    </Modal>
  );
};

export const handleShowPrimaryRoleModal = () => {
  svc.uuiModals.show((modalProps) => <PrimaryRoleModal modalProps={modalProps} />);
};
