import React, { FC } from "react";

import { FlexCell, FlexRow, LabeledInput } from "@epam/loveship";
import { cx } from "@epam/uui";

interface IReportDetailsFileProps {
  label: string;
  labelAlignCx?: string;
  info?: string;
}

const ReportDetailsRow: FC<IReportDetailsFileProps> = ({ label, labelAlignCx, info, children }) => {
  return (
    <FlexRow cx="report__row">
      <FlexCell grow={1}>
        <LabeledInput
          cx={cx("report__label", {
            [`report__label${labelAlignCx}`]: !!labelAlignCx,
          })}
          label={label}
          labelPosition="left"
          info={info}
        >
          {children}
        </LabeledInput>
      </FlexCell>
    </FlexRow>
  );
};

export default ReportDetailsRow;
