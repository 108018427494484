import React, { forwardRef } from "react";

import { ReactComponent as DragIcon } from "@epam/assets/icons/common/action-drag_indicator-18.svg";

import withNames from "Helpers/bemClassname";

import "./drag-handle.scss";

const DragHandle = forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement>>(
  ({ className, ...props }, ref) => {
    const cn = withNames("drag-handle");

    return (
      <button ref={ref} {...props} className={cn("", [className, "flex items-center justify-center"])} tabIndex={0}>
        <DragIcon />
      </button>
    );
  },
);

export default DragHandle;
