import { useDispatch, useSelector } from "react-redux";

import {
  changeReportViewMode,
  getFilteredReports as getFilteredReportsAction,
  getReports as getReportsAction,
  IReportViewMode,
} from "Pages/reports-browser/reports-browser.actions";
import { IReportExtended } from "SP/reports/reports.types";
import { IRootReducerState } from "Store/reducers";

export const useReportView = () => {
  const viewMode = useSelector<IRootReducerState, IReportViewMode>((state) => state.reports.viewMode);

  const dispatch = useDispatch();
  const changeViewMode = (newMode: IReportViewMode) => () => {
    dispatch(changeReportViewMode(newMode));
  };

  return { viewMode, changeViewMode };
};

export const useReportsData = () => {
  const filteredReports = useSelector<IRootReducerState, IReportExtended[]>((state) => state.reports.filteredReports);
  const error = useSelector<IRootReducerState, string | null>((state) => state.reports.error);

  const loading = useSelector<IRootReducerState, boolean>((state) => state.reports.loading);
  const isFilteredReportsLoaded = useSelector<IRootReducerState, boolean>(
    (state) => state.reports.isFilteredReportsLoaded,
  );

  return {
    filteredReports,
    error,
    loading,
    isFilteredReportsLoaded,
  };
};

export const useReportsMethods = () => {
  const dispatch = useDispatch();

  const getReports = () => {
    dispatch(getReportsAction());
  };

  const getFilteredReports = () => {
    dispatch(getFilteredReportsAction());
  };

  return {
    getReports,
    getFilteredReports,
  };
};
